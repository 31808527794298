import React from 'react'
import { Col } from 'react-flexbox-grid'
import Feedback from '../../../../components/feedback/'

import HeaderWebview from '../../../../components/layouts/header-webview'
import HelpDetailStyle from './index.style'

import Helmet from 'react-helmet'
import dompurify from 'dompurify'

const WebviewHelpDetail = props => {
  const helpDetailData = props.location.state
  const sanitizer = dompurify.sanitize

  return (
    <HelpDetailStyle>
      <Helmet>
        <title>Pusat Bantuan</title>
        <meta name="robots" content="noindex" />
        <meta charSet="utf-8" />
      </Helmet>
      <Col sm={12} xs={12} className="wrap-help">
        <HeaderWebview></HeaderWebview>
        <div className="dtl-ttl-wrp">
          {helpDetailData.title}
          <div className="brdr-btm"></div>
        </div>
        <div>
          <ol
            className={
              helpDetailData.value.length > 1 ? 'list-number' : 'list-none'
            }
          >
            {helpDetailData.value.map((val, index) => {
              return (
                <li
                  key={index}
                  dangerouslySetInnerHTML={{ __html: sanitizer(val.text) }}
                ></li>
              )
            })}
          </ol>
        </div>
      </Col>
      <Feedback></Feedback>
    </HelpDetailStyle>
  )
}

export default WebviewHelpDetail
