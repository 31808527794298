/* eslint-disable */
import React from 'react'
// import { KarirStyle } from './index.style'
import Helmet from 'react-helmet'

const RegisterVendor = () => {
    return (
        <div>
            <Helmet>
                <title>Register Vendor | halojasa.com</title>
                <meta charSet="utf-8" />
                <meta name="description" content="Daftarkan diri anda. Jadikan diri anda sebagai mitra terampil dan bermanfaat" />
                <meta property="og:title" content="Register Vendor | halojasa.com" />
                <meta property="og:site_name" content="Halo Jasa" />
                <meta property="og:url" content="https://halojasa.com/register-vendor/" />
                <meta property="og:description" content="Daftarkan diri anda. Jadikan diri anda sebagai mitra terampil dan bermanfaat" />
                <meta property="og:image" content="https://halojasa.com/blog/wp-content/uploads/2020/06/register-ls.png" />
                <meta property="og:image:secure_url" content="https://halojasa.com/blog/wp-content/uploads/2020/06/register-ls.png" />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:image" itemProp="image" content="https://halojasa.com/blog/wp-content/uploads/2020/06/register-sq.png" />
                <meta property="og:image:secure_url" itemProp="image" content="https://halojasa.com/blog/wp-content/uploads/2020/06/register-sq.png" />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:image:width" content="300" />
                <meta property="og:image:height" content="300" />
                <meta property="og:type" content="website" />
                <meta property="fb:app_id" content="951866408583742" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@pthalojasa" />
                <meta name="twitter:title" content="Register Vendor | halojasa.com" />
                <meta name="twitter:description" content="Daftarkan diri anda. Jadikan diri anda sebagai mitra terampil dan bermanfaat" />
                <meta name="twitter:image:src" content="https://halojasa.com/blog/wp-content/uploads/2020/06/register-ls.png" />
                <meta name="p:domain_verify" content="4e6fcb4deba1a02d84225fad69babc0b" />
                <meta name="keyword" content="register vendor" />
            </Helmet>
        </div>
    )
}

export default RegisterVendor
