import React, { useEffect } from 'react'

import { Col } from 'react-flexbox-grid'
import HelpWebview from './index.style'
import AccordionWebview from '../../../../components/accordion-webview'
import HeaderWebview from '../../../../components/layouts/header-webview'
import { WebviewUserDataHelp } from '../../../../utils/lib/webview-user-data'

import Helmet from 'react-helmet'

const WebviewHelp = () => {

    const data = WebviewUserDataHelp

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <HelpWebview>
            <Helmet>
                <title>Pusat Bantuan</title>
                <meta name="robots" content="noindex" />
                <meta charSet="utf-8" />
            </Helmet>
            <HeaderWebview></HeaderWebview>
            <div className="contain-help">
                <Col sm={12} xs={12} className="wrap-help">
                    {data.map((value, index) => {
                        return (
                            <div key={index}>
                                <AccordionWebview key={index} {...value}></AccordionWebview>
                            </div>
                        )
                    })}
                </Col>
            </div>
        </HelpWebview>
    )
}

export default WebviewHelp