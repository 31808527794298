// import React from 'react'
// import ReactDOM from 'react-dom'
// import './index.css'
// import App from './App'
// import * as serviceWorker from './serviceWorker'

// ReactDOM.render(<App />, document.getElementById('root'))

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()

import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
// import { I18nextProvider } from 'react-i18next'

import configureStore from './reducers/store'
import * as serviceWorker from './serviceWorker'
// import i18n from './translate/translate-config'
import App from './App'
import './index.css'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enTranslate from './translate/en-translate'
import idnTranslate from './translate/idn-translate'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: enTranslate },
      idn: { translation: idnTranslate }
    },
    lng: 'idn',
    fallbackLng: 'idn',

    interpolation: {
      escapeValue: false
    }
  })

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={configureStore()}>
    <App />
  </Provider>
)

serviceWorker.unregister()
