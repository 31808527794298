import React from 'react'
import Skeleton from '../../skeleton'
import { Grid, Row, Col } from 'react-flexbox-grid'
const HeaderSkeleton = () => {
  return (
    <div
      style={{
        paddingBottom: 20,
        paddingTop: 20,
        zIndex: 8,
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0
      }}
    >
      <Grid>
        <Row between={'lg'}>
          <Col lg={3} sm={12} xs={12}>
            <Skeleton width={'80%'} height={'25px'}></Skeleton>
          </Col>
          <Col lg={3} sm={12} xs={12}>
            <Row between="lg">
              <Col lg={3}>
                <Skeleton width={'100%'} height={'25px'}></Skeleton>
              </Col>
              <Col lg={3}>
                <Skeleton width={'100%'} height={'25px'}></Skeleton>
              </Col>
              <Col lg={3}>
                <Skeleton width={'100%'} height={'25px'}></Skeleton>
              </Col>
              <Col lg={3}>
                <Skeleton width={'100%'} height={'25px'}></Skeleton>
              </Col>
            </Row>
          </Col>
          <Col lg={3} sm={12} xs={12}>
            <Row between="lg">
              <Col lg={6}>
                <Skeleton width={'100%'} height={'25px'}></Skeleton>
              </Col>
              <Col lg={6}>
                <Skeleton width={'100%'} height={'25px'}></Skeleton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default HeaderSkeleton
