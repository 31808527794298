import React, { useState, useEffect } from 'react'
import { Col } from 'react-flexbox-grid'

import HeaderWebview from '../../../../components/layouts/header-webview'
import HelpDetailStyle from './index.style'

import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import dompurify from 'dompurify'

const WebviewHelpDetail = props => {
    const helpDetailData = props.location.state
    const sanitizer = dompurify.sanitize

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [isShow, setShow] = useState(false)

    const user = helpDetailData.user

    return (
        <HelpDetailStyle>
            <Helmet>
                <title>Pusat Bantuan</title>
                <meta name="robots" content="noindex" />
                <meta charSet="utf-8" />
            </Helmet>
            <Col sm={12} xs={12} className="wrap-help">
                <HeaderWebview user={helpDetailData.user}></HeaderWebview>
                <div className="dtl-ttl-wrp">
                    {helpDetailData.title}
                    <div className="brdr-btm"></div>
                </div>
                <div>
                    <ol className={helpDetailData.value.length > 1 ? 'list-number' : 'list-none'}>
                        {helpDetailData.value.map((val, index) => {
                            return (
                                <li key={index} dangerouslySetInnerHTML={{ __html: sanitizer(val.text) }}>{}</li>
                            )
                        })}
                    </ol>
                </div>
            </Col>
            <Col sm={12} xs={12} className="wrap-help">
                <div className="fv"></div>
                {
                    !isShow &&
                    <div className="fvb-wrap">
                        <p>Apakah penjelasan ini sudah membantu anda?</p>
                        <div className="fvb-flx">
                            <button
                                className="fvb-st"
                                onClick={() => setShow(!isShow)}
                            >
                                Sudah, terima kasih
                            </button>
                            <Link
                                to={{
                                    pathname: `/webview-${user ? 'vendor' : 'user'}-feedback/`,
                                    state: {
                                        user: user
                                    }
                                }}
                                className="fvb-st"
                            >
                                Belum, saya masih butuh bantuan
                            </Link>
                        </div>
                    </div>
                }
                {
                    isShow &&
                    <div className="thanks">
                        <span>Terima kasih atas feedback anda</span>
                    </div>
                }
            </Col>
        </HelpDetailStyle>
    )
}

export default WebviewHelpDetail