/* eslint-disable */
import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"

import { HeaderHelpStyle } from './help-header.styles'

const HelpHeader = () => {
  const { t } = useTranslation()
  return (
    <HeaderHelpStyle>
      <div className="help-header-wrapper">
        <ul>
          <li key={1}>
            <Link to='/bantuan/pusat-bantuan/'>{t('pageHelpHeader.part1')}</Link>
          </li>
          <li key={2}>
            <Link to='/bantuan/privacy/'>{t('pageHelpHeader.part2')}</Link>
          </li>
          <li key={3}>
            <Link to='/bantuan/syarat-umum/'>{t('pageHelpHeader.part3')}</Link>
          </li>
          <li key={4}>
            <Link to='/bantuan/cara-pembayaran/'>{t('pageHelpHeader.part4')}</Link>
          </li>
          <li key={5}>
            <Link to='/bantuan/faq/'>{t('pageHelpHeader.part5')}</Link>
          </li>
          <li key={6}>
            <Link to='/bantuan/informasi-layanan/'>{t('pageHelpHeader.part6')}</Link>
          </li>
          <li key={7}>
            <Link to='/bantuan/pengaduan-layanan/'>{t('pageHelpHeader.part7')}</Link>
          </li>
        </ul>
      </div>
    </HeaderHelpStyle>
  )
}

export default HelpHeader
