import styled from 'styled-components'

import ArrowDown from '../../static/icons/arrow-down.png'

export default styled.div`
    font-family: open sans;
    overflow-x: hidden;
    width: 100%;
    .list-webview {
        overflow: hidden;
    }
    .arrow {
        padding: 20px 24px;
        display: flex;
        width: 100%;
        -webkit-box-pack: justify;
        justify-content: space-between;
        box-sizing: border-box;
        border-bottom: 2px solid #EEEEEE;
        > span {
            font-weight: bold;
            color: #333333;
        }

        > i {
            width: 20px;
            height: 20px; 
            transform: rotate(-180deg);
            transition: transform 0.3s ease-in-out;
                &.down {
                    background-image: url(${ArrowDown});
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 1rem;
                    transform: rotate(0deg);
                }
                &.up {
                    background-image: url(${ArrowDown});
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 1rem;
                    transform: rotate(-180deg);
                }
        }
    }

    .item-link {
        visibility: hidden;
        height: 0;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear, height 0s linear;
        box-sizing: border-box;
        margin-left: 24px;
        &.collapsed {
            visibility: visible;
            height: 100%;
            opacity: 1;
            transition: visibility 0s, opacity 0.5s linear, height 0.7s linear;
            border-bottom: 1px solid #DDDDDD;
            &:last-child {
                border-bottom: none;
            }
        }
        > .link-wrap {
            padding: 20px 0px;
            display: flex;
            width: 100%;
            > .link {
                width: 100%;
                text-decoration: none;
                color: #555555;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }

    .wrap-acc {
        background: #F7F7F7;
    }
  
`