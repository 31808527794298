import styled from 'styled-components'

export default styled.div`
    font-family: open sans;
    overflow-x: hidden;
    padding-bottom: 40px;
    .wrap-help {
        padding: 0px; 
    }
    .dtl-ttl-wrp {
        width: 100%;
        box-sizing: border-box;
        padding: 20px 24px;
        font-size: 16px;
        font-weight: bold;
        color: #555555;
        position: relative;
        > .brdr-btm {
            border-bottom: 1px solid #eeeeee;
            margin-top: 16px;
            width: 150%;
        }
    }
    .list-number {
        padding: 0px 24px 0px 24px;
        box-sizing: border-box;
        > li {
            margin-bottom: 16px;
            box-sizing: border-box;
            margin-left: 24px;
            font-family: Roboto;
            font-size: 14px;
        }
    }
    .list-none {
        list-style: none;
        padding: 0px 24px 0px 24px;
        box-sizing: border-box;
        > li {
            margin-bottom: 16px;
            font-family: Roboto;
            font-size: 14px;
        }
    }
`