import React from 'react'
import Skeleton from '../../skeleton'
import { Grid, Row, Col } from 'react-flexbox-grid'
import styled from 'styled-components'

const FooterSkeletonWrap = styled.div`
  padding-bottom: 20px;
  padding-top: 20px;
`

const HeaderSkeleton = () => {
  return (
    <FooterSkeletonWrap>
      <Grid>
        <Row between={'lg'}>
          <Col lg={2} sm={12} xs={12}>
            <Skeleton width={'100%'} height={'20px'}></Skeleton>
            <Skeleton width={'80%'} height={'10px'}></Skeleton>
            <Skeleton width={'50%'} height={'10px'}></Skeleton>
          </Col>
          <Col lg={2} sm={12} xs={12}>
            <Skeleton width={'100%'} height={'10px'}></Skeleton>
            <Skeleton width={'80%'} height={'10px'}></Skeleton>
            <Skeleton width={'60%'} height={'10px'}></Skeleton>
            <Skeleton width={'40%'} height={'10px'}></Skeleton>
            <Skeleton width={'30%'} height={'10px'}></Skeleton>
          </Col>
          <Col lg={2} sm={12} xs={12}>
            <Skeleton width={'100%'} height={'10px'}></Skeleton>
            <Skeleton width={'80%'} height={'10px'}></Skeleton>
            <Skeleton width={'60%'} height={'10px'}></Skeleton>
            <Skeleton width={'40%'} height={'10px'}></Skeleton>
            <Skeleton width={'30%'} height={'10px'}></Skeleton>
          </Col>
          <Col lg={2} sm={12} xs={12}>
            <Skeleton width={'100%'} height={'10px'}></Skeleton>
            <Skeleton width={'80%'} height={'10px'}></Skeleton>
            <Skeleton width={'60%'} height={'10px'}></Skeleton>
            <Skeleton width={'40%'} height={'10px'}></Skeleton>
            <Skeleton width={'30%'} height={'10px'}></Skeleton>
          </Col>
          <Col lg={2} sm={12} xs={12}>
            <Skeleton width={'100%'} height={'10px'}></Skeleton>
            <Skeleton width={'80%'} height={'10px'}></Skeleton>
            <Skeleton width={'60%'} height={'10px'}></Skeleton>
            <Skeleton width={'40%'} height={'10px'}></Skeleton>
            <Skeleton width={'40%'} height={'10px'}></Skeleton>
          </Col>
        </Row>
      </Grid>
    </FooterSkeletonWrap>
  )
}

export default HeaderSkeleton
