export const VendorData = [
    {
        id: 1,
        title: 'Pusat Bantuan Vendor',
        child: [
            {
                title: 'Menjadi vendor di Halo-jasa',
                subdata: [
                    {
                        title: 'Cara Mendaftar Menjadi Vendor di Halo Jasa',
                        content: [
                            {
                                text: `
                                        Lowongan Kerja Menjadi Vendor Halo Jasa & Syarat Pendaftaran
                                        <br /><br />
                                        Semakin hari, kesibukan kaum urban di perkotaan membuat mereka tidak sempat melakukan beragam kegiatan kecil seperti membersihkan rumah, kendaraan pribadi mereka hingga merawat AC. Maka dari itu, permintaan terhadap jasa pembersih panggilan atau Service On- Demand seperti Halo Jasa kian meningkat. Untuk itu, Halo Jasa mengajak Anda untuk turut bergabung menjadi bagian dari vendor Halo Jasa. Caranya mudah, cukup buka halaman utama website Halo Jasa di bagian vendor. Setelah itu, Anda akan menemukan form dan langsung ikuti langkah-langkahnya secara mudah.
                                        <br /><br />
                                        Layanan Halo Jasa terdiri dari empat yang utama yaitu Halo Massage, Halo Clean, Halo Fix, dan Halo Auto di mana Anda harus memilih salah satu layanan untuk mendaftar. Pada Halo Massage, Anda akan menjadi vendor di mana bertugas menjadi terapis dan menghilangkan pegal-pegal yang melanda kaum urban tersebut. Di Halo Clean, Anda akan menjadi petugas kebersihan yang menjaga tempat tinggal atau ruang kantor bersih dari beragam debu dan kotoran.
                                        <br /><br />
                                        Pada Halo Fix, Anda bertugas menjadi teknisi sekaligus perawat yang menjaga AC agar tetap optimal sehingga berkinerja dingin maksimal. Terakhir, di Halo Auto Anda akan bertugas untuk merawat kendaraan pribadi seperti mobil atau motor dari beragam hal yang membuat kotor kendaraan tersebut. Keseluruhan empat layanan tersebut berada di dalam satu aplikasi Halo Jasa.
                                        <br /><br />
                                        <h2>Cara Mendaftar Vendor Halo Jasa Online & Syarat Pendaftaran.</h2>
                                        <br />
                                        <div style="text-align: center;">
                                        <img style="max-width: 65%;;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/01/daftar-vendor-halojasa.jpg">
                                        <br />
                                        <p>Pada halaman registrasi vendor, Klik gabung</p>
                                        <img style="max-width: 65%;;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/01/registrasi-vendor-halo-jasa.jpg">
                                        <br />
                                        <p>Pilih daftar individual</p>
                                        <img style="max-width: 65%;;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/01/form-register-vendor-halo-jasa.jpg">
                                        <br />
                                        <p>Masukkan informasi diri Anda seperti Nama lengkap, domisili, no. Handphone, dan email</p>
                                        <br />
                                        <img style="max-width: 65%;;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/01/vendor-halo-jasa-pendaftaran-individu.jpg">
                                        <br />
                                        <p>Setelah itu, ke tahap selanjutnya dengan mengisi informasi tambahan, jenis layanan jasa yang ingin Anda pilih</p>
                                        <br />
                                        <img style="max-width: 65%;;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/01/vendor-halo-jasa-upload-ktp.jpg">
                                        <br />
                                        <p>Terakhir, upload data diri Anda berupa KTP lalu submit.</p>
                                        </div>
                                        <br /><br />
                                        <h3><b>Keuntungan yang Anda dapatkan dengan bergabung menjadi vendor di Halo Jasa</b></h3>
                                        <br />
                                        <div style="text-align: center;">
                                        <img style="max-width: 65%;;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/01/bergabung-lebih-mudah.jpg">
                                        <p>Cara bergabung yang lebih mudah</p>
                                        <br /><br />
                                        <img style="max-width: 65%;;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/01/order-lebih-banyak.jpg">
                                        <p>Order yang lebih banyak</p>
                                        <br />
                                        <img style="max-width: 65%;;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/01/insentif-langsung-cair.jpg">
                                        <p>Bonus insentif langsung cair</p>
                                        <br />
                                        <img style="max-width: 65%;;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/01/sistem-pembayaran-mudah.jpg">
                                        <p>Sistem pembayaran mudah</p>
                                        <br /><br />
                                        <h3><b>Syarat untuk bergabung menjadi vendor Halo Jasa :</b></h3>
                                        <br />
                                        <img style="max-width: 100%;;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/01/keuntungan-mitra-halo-jasa.png">
                                        <br />
                                        <p>Siapkan SKCK asli (Surat keterangan catatan kepolisian), fotokopi KTP, Fotokopi Kartu Keluarga (KK), Surat keterangan domisili (jika calon vendor ber-KTP di luar Jakarta tetapi tinggal di Jakarta)</p>
                                        <br />
                                        <img style="max-width: 100%;;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/01/syarat-vendor-halo-jasa.png">
                                        <br />
                                        <p>Jika calon vendor telah terdaftar di aplikasi lain, Anda cukup menyerahkan SKCK asli, fotokopi KTP, dan fotokopi KK. Namun tetap wajib menyerahkan surat keterangan tempat tinggal jika Anda tinggal di Jakarta tetapi ber-KTP di luar Jakarta.</p>
                                        <br />
                                        </div>
                                        <iframe width="100%" src="https://www.youtube.com/embed/Ukgv9zQwQrM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        `
                            }
                        ]
                    }
                ]
            },
            {
                title: 'Akun saya',
                subdata: [
                    {
                        title: 'Pengaturan Radius Order',
                        content: [
                            {
                                text: `
                                        Anda bisa mengatur radius penerimaan order anda sendiri, semakin besar radius penerimaan order semakin besar kemungkinan mendapatkan order. Berikut ini cara mengaturnya<br />
                                        <ol>
                                            <li>Di halaman depan aplikasi anda, pilih menu <em>setting</em> ( gambar<img src="https://halojasa.com/blog-vendor/wp-content/uploads/2019/09/ggr.png" alt="" width="21" height="20" />)</li>
                                            <li>Pilih Jarak Maksimal Order anda, terdapat pilihan 3 KM , 6 KM, 10 KM 15 KM</li>
                                            <li>selesai</li>
                                        </ol>
                                      `
                            }
                        ]
                    },
                    {
                        title: 'Pengaturan Penerimaan Booking',
                        content: [
                            {
                                text: `
                                            <strong><u>Penerimaan booking di hari yang sama dan hari yang berbeda</u></strong>
                                            Selain layanan yang cepat untuk 1 jam kedepan, Customer bisa memesan layanan anda untuk beberapa jam kedepan di hari yang sama atau bahkan di hari berikutnya. Anda bisa mengaturnya melalu fitur pengaturan booking, berikut ini langkah-langkahnya:
                                            <ol>
                                                <li>Di halaman depan aplikasi anda, pilih menu <em>setting</em> ( gambar <img class="alignnone wp-image-3232" src="https://halojasa.com/blog-vendor/wp-content/uploads/2019/09/ggr.png" alt="" width="23" height="22" />)</li>
                                                <li>Aktifkan atau geser tombol <em>terima order di hari yang sama</em> menjadi ON</li>
                                                <li>Aktifkan atau geser tombol <em>terima order di hari yang berbeda</em> menjadi ON</li>
                                                <li>Selesai<u></u></li>
                                            </ol>
                                            <br />
                                            <strong><u>Penerimaan booking di hari yang sama saja</u></strong>
                                            Jika anda hanya ingin menerima booking pada hari yang sama saja, dan tidak mau menerima order booking untuk hari berikutnya, maka anda bisa mengaturnya melalu fitur pengaturan booking, berikut ini langkah-langkahnya:
                                            <ol>
                                                <li>Di halaman depan aplikasi anda, pilih menu <em>setting</em> ( gambar <img class="alignnone wp-image-3232" src="https://halojasa.com/blog-vendor/wp-content/uploads/2019/09/ggr.png" alt="" width="23" height="22" />)</li>
                                                <li>Aktifkan atau geser tombol <em>terima order di hari yang sama</em> menjadi ON</li>
                                                <li>Aktifkan atau geser tombol <em>terima order di hari yang berbeda</em> menjadi OFF</li>
                                                <li>Selesai</li>
                                            </ol>
                                            <br />
                                            <strong><u>Penerimaan booking di hari yang berbeda saja</u></strong>
                                            Jika anda hanya ingin menerima booking pada hari yang berbeda saja, dan tidak mau menerima order booking untuk hari ini, maka anda bisa mengaturnya melalu fitur pengaturan booking, berikut ini langkah-langkahnya:
                                            <ol>
                                                <li>Di halaman depan aplikasi anda, pilih menu <em>setting</em> ( gambar <img class="alignnone wp-image-3232" src="https://halojasa.com/blog-vendor/wp-content/uploads/2019/09/ggr.png" alt="" width="25" height="23" />)</li>
                                                <li>Aktifkan atau geser tombol <em>terima order di hari yang sama</em> menjadi OFF</li>
                                                <li>Aktifkan atau geser tombol <em>terima order di hari yang berbeda</em> menjadi ON</li>
                                                <li>Selesai</li>
                                            </ol>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Cara Membatalkan Order',
                        content: [
                            {
                                text: `
                                        Jika anda ingin melakukan <strong>pembatalan</strong>, karena <strong>customer tidak bisa dihubungi</strong>, ataupun karena <strong>permintaan tidak sesuai dengan aplikasi</strong>, berikut ini langkah-langkahnya:<br />
                                        <ol>
                                            <li>Pada tampilan order di aplikasi anda pilih icon ini <img class="alignnone wp-image-3213" src="https://halojasa.com/blog-vendor/wp-content/uploads/2019/09/ttk1.png" alt="" width="19" height="26" /></li>
                                            <li>Anda akan masuk ke menu lainnya</li>
                                            <li>Pilih <em>batalkan order ini</em></li>
                                            <li>Pilih salah satu alasan, dan jelaskan alasan anda di text box yang ada.</li>
                                            <li>Pilih <em>kirim</em></li>
                                            <li>Order telah berhasil di batalkan</li>
                                        </ol>
                                        <br />
                                        Catatan: <strong>Pastikan anda selalu menyelesaikan order yang masuk agar penghasilan anda lebih optimal.</strong>
                                      `
                            }
                        ]
                    },
                    {
                        title: 'Terlambat Bidding Order',
                        content: [
                            {
                                text: `
                                        Jika anda sering kalah dalam <strong>Bid Order</strong>. Ikuti langkah-langkah berikut
                                        <ol>
                                            <li>Pastikan jaringan internet anda stabil</li>
                                            <li>Silahkan logout terlebih dahulu, kemudian login kembali</li>
                                            <li>Ayo semangat… Anda belum beruntung… selalu tingkatkan kecepatan anda dalam mengambil order..</li>
                                        </ol>
                                      `
                            }
                        ]
                    },
                    {
                        title: 'Tata cara top up Dompet Kredit anda',
                        content: [
                            {
                                text: `
                                        Anda Dapat melakukan <b>TOP Dompet Kredit</b> anda melalui dua metode dibawah ini
                                        <br /><br />
                                        <ol>
                                        <li><b>Dompet Tunai</b></li>
                                        <li><b>ATM BCA, m-BCA</b></li>
                                        </ol>
                                        <br /><br />
                                        Klik salah satu…
                                        <br /><br />
                                        <b><u>Dompet Tunai</u></b>
                                        <br />
                                        Anda bisa melakukan TOP UP dengan cara memindahkan saldo anda dari Dompet Tunai ke Dompet Kredit anda, berikut ini langkah-langkahnya:
                                        <br />
                                        <ol>
                                        <li>Masuk ke menu <b>Dompet</b></li>
                                        <li>Pilih Dompet Kredit</li>
                                        <li>Pilih TOP UP via Dompet Tunai</li>
                                        <li>Masukan nominal yang akan di TOP UP</li>
                                        <li>Konfirmasi</li>
                                        <li>Berhasil</li>
                                        </ol>
                                        <br /><br />
                                        <b><u>ATM BCA</u></b>
                                        <br />
                                        <ol>
                                        <li>
                                        Sebelum TOP UP pastikan anda telah mendapatkan nominal <b>dana yang sudah memiliki 3 kode unik</b>. Misalnya anda ingin TOPUP sebesar 100.000, anda sudah mendapatkan jumlahnya sebesar 100.189. Jika anda belum mendaptkannya klik <b>(drop down)</b>
                                        <ol>
                                        <li><b>Masuk ke menu Dompet</b></li>
                                        <li><b>Pilih Dompet Kredit</b></li>
                                        <li><b>Pilih TOP UP via ATM BCA</b></li>
                                        <li><b>Masukan Jumlah Pembayaran, No rekening, Nama Pemilik Rekening</b></li>
                                        <li><b>Akan Muncul No rekening a/n PT Halo Komunikasi Sejahtera No : 093.308.9981 dan jumlah yang harus di transfer dengan 3 kode unik dibelakangnya.</b></li>
                                        </ol>
                                        </li>
                                        <li>Masukan ATM anda, masukan PIN anda</li>
                                        <li>Pilih transfer ke Rek BCA</li>
                                        <li>Masukan nominal yang akan di transfer, <b>(jumlah nominal yang sudah memiliki kode unik)</b></li>
                                        <li>Masukan no rekening BCA yang dituju <b>(093.308.9981)</b> atas nama <b>PT HALO KOMUNIKASI SEJAHTERA</b></li>
                                        <li>Jika data sudah benar pilih “YA” (OK)</li>
                                        <li>Selesai (struk akan keluar dari mesin ATM)</li>
                                        <li>Ambil Kartu ATM anda</li>
                                        </ol>
                                        <br /><br />
                                        <b><u>m-BCA</u></b>
                                        <br />
                                        <ol>
                                        <li>Pilih menu “m-BCA” di ponsel Anda dan tekan OK/YES</li>.
                                        <li>Pilih menu “m-Transfer” dan tekan OK/YES</li>
                                        <li>Pilih “Antar Rek” dan tekan OK/YES</li>
                                        <li>Masukkan Jumlah Uang yang akan ditransfer dan tekan OK/YES, pastikan nominal yang anda masukan sesuai dengan ketentuan yaitu nominal yang sudah memiliki 3 kode unik dibelakangnya, jika anda belum mendapatkannya <b>klik disini (drop down)</b></li>
                                        </ol>
                                        <br />
                                        <ol>
                                        <li><b>Masuk ke menu Dompet</b></li>
                                        <li><b>Pilih Dompet Kredit</b></li>
                                        <li><b>Pilih TOP UP via ATM BCA</b></li>
                                        <li><b>Masukan Jumlah Pembayaran, No rekening, Nama Pemilik Rekening</b></li>
                                        <li><b>Akan Muncul No rekening a/n PT Halo Komunikasi Sejahtera No : 093.308.9981 dan jumlah yang harus di transfer dengan 3 kode unik dibelakangnya.</b></li>
                                        </ol>
                                        <br />
                                        <ol>
                                        <li>Masukkan No. RekeningTujuan <b>(093.308.9981)</b> yang akan ditransfer dan tekan OK/YES</li>
                                        <li>Masukkan PIN m-BCA Anda dan tekan OK/YES</li>
                                        <li>Muncul keterangan “berita” yang dapat Anda isi/kosongkan</li>
                                        <li>Jika Anda memiliki lebih dari 1 (satu) rekening yang terhubung dengan m-BCA, pilih nomor rekening yang akan digunakan, kemudian tekan OK/YES</li>
                                        <li><b>Pastikan data transaksi yang muncul di layar konfirmasi telah benar</b>. Jika benar, tekan OK/YES dan lanjutkan ke no. 11. Jika tidak, diamkan selama beberapa saat sampai message tersebut hilang dan otomatis transaksi tersebut akan dibatalkan</li>
                                        <li>Masukkan PIN m-BCA Anda dan tekan OK/YES</li>
                                        <li>Setelah beberapa saat, Anda akan menerima message yang berisi informasi transaksi transfer Anda Berhasil atau Gagal.</li>
                                        </ol>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Tata cara withdraw Dompet Tunai anda',
                        content: [
                            {
                                text: `
                                        Anda dapat melakukan withdraw dari <b><i>Dompet Tunai</i></b> anda, berikut ini langkah-langkahnya
                                        <br /><br />
                                        <ol>
                                        <li>Masuk ke menu <b><i>Dompet Tunai</i></b></li>
                                        <li>Pilih <b>Transfer ke Rekening bank</b></li>
                                        <li>Isi dengan lengkap setiap kolom pertanyaan yang ada</li>
                                        <li><b>Isi nominal uang</b> yang akan di transfer ke rekening anda</li>
                                        <li>Konfirmasi</li>
                                        </ol>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Pembayaran yang tidak masuk',
                        content: [
                            {
                                text: `
                                        <b><u>Pembayaran Customer tidak masuk</b></u>
                                        Mohon maaf atas apa yang anda alami, Tim kami akan segera menindaklanjuti, mohon tuliskan detail permasalahan anda. Terima kasih.
                                        <br /><br />
                                        <b><u>Insentif Tepat Waktu tidak Masuk</b></u>
                                        Mohon maaf atas apa yang anda alami, Tim kami akan segera menindaklanjuti, mohon tuliskan detail permasalahan anda. Terima kasih.
                                        <br /><br />
                                        <b><u>Insentif Rating Tidak Masuk</b></u>
                                        Mohon maaf atas apa yang anda alami, Tim kami akan segera menindaklanjuti, mohon tuliskan detail permasalahan anda. Terima kasih.
                                        <br /><br />
                                        <b><u>TOPUP tidak berhasil</b></u>
                                        Mohon maaf atas apa yang anda alami, Tim kami akan segera menindaklanjuti, mohon tuliskan detail permasalahan anda. Terima kasih.
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Pengaturan booking berdasarkan hari dan tanggal',
                        content: [
                            {
                                text: `
                                        <p><strong><u>Penerimaan booking di hari yang sama dan hari yang berbeda</u></strong></p>
                                        <p>Selain layanan yang cepat untuk 1 jam kedepan, Customer bisa memesan layanan anda untuk beberapa jam kedepan di hari yang sama atau bahkan di hari berikutnya. Anda bisa mengaturnya melalu fitur pengaturan booking, berikut ini langkah-langkahnya:</p>
                                        <ol>
                                        <li>Di halaman depan aplikasi anda, pilih menu&nbsp;<em>setting</em>&nbsp;( gambar&nbsp;<img class="alignnone wp-image-3232 td-animation-stack-type0-2" src="https://halojasa.com/blog-vendor/wp-content/uploads/2019/09/ggr.png" alt="" width="23" height="22" />)</li>
                                        <li>Aktifkan atau geser tombol&nbsp;<em>terima order di hari yang sama</em>&nbsp;menjadi ON</li>
                                        <li>Aktifkan atau geser tombol&nbsp;<em>terima order di hari yang berbeda</em>&nbsp;menjadi ON</li>
                                        <li>Selesai<u></u></li>
                                        </ol>
                                        <p>&nbsp;</p>
                                        <p><strong><u>Penerimaan booking di hari yang sama saja</u></strong></p>
                                        <p>Jika anda hanya ingin menerima booking pada hari yang sama saja, dan tidak mau menerima order booking untuk hari berikutnya, maka anda bisa mengaturnya melalu fitur pengaturan booking, berikut ini langkah-langkahnya:</p>
                                        <ol>
                                        <li>Di halaman depan aplikasi anda, pilih menu&nbsp;<em>setting</em>&nbsp;( gambar&nbsp;<img class="alignnone wp-image-3232 td-animation-stack-type0-2" src="https://halojasa.com/blog-vendor/wp-content/uploads/2019/09/ggr.png" alt="" width="23" height="22" />)</li>
                                        <li>Aktifkan atau geser tombol&nbsp;<em>terima order di hari yang sama</em>&nbsp;menjadi ON</li>
                                        <li>Aktifkan atau geser tombol&nbsp;<em>terima order di hari yang berbeda</em>&nbsp;menjadi OFF</li>
                                        <li>Selesai</li>
                                        </ol>
                                        <p>&nbsp;</p>
                                        <p><strong><u>Penerimaan booking di hari yang berbeda saja</u></strong></p>
                                        <p>Jika anda hanya ingin menerima booking pada hari yang berbeda saja, dan tidak mau menerima order booking untuk hari ini, maka anda bisa mengaturnya melalu fitur pengaturan booking, berikut ini langkah-langkahnya:</p>
                                        <ol>
                                        <li>Di halaman depan aplikasi anda, pilih menu&nbsp;<em>setting</em>&nbsp;( gambar&nbsp;<img class="alignnone wp-image-3232 td-animation-stack-type0-2" src="https://halojasa.com/blog-vendor/wp-content/uploads/2019/09/ggr.png" alt="" width="25" height="23" />)</li>
                                        <li>Aktifkan atau geser tombol&nbsp;<em>terima order di hari yang sama</em>&nbsp;menjadi OFF</li>
                                        <li>Aktifkan atau geser tombol&nbsp;<em>terima order di hari yang berbeda</em>&nbsp;menjadi ON</li>
                                        <li>Selesai</li>
                                        </ol>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Pengaturan booking berdasarkan radius jarak',
                        content: [
                            {
                                text: `
                                        Anda bisa mengatur radius penerimaan order anda sendiri, semakin besar radius penerimaan order semakin besar kemungkinan mendapatkan order. Berikut ini cara mengaturnya
                                        <br /><br />
                                        <ol>
                                        <li>Di halaman depan aplikasi anda, pilih menu setting (<img class="alignnone wp-image-3232 td-animation-stack-type0-2" src="https://halojasa.com/blog-vendor/wp-content/uploads/2019/09/ggr.png" alt="" width="21" height="20"> gambar)</li>
                                        <li>Pilih Jarak Maksimal Order anda, terdapat pilihan 3 KM , 6 KM, 10 KM 15 KM</li>
                                        <li>Selesai</li>
                                        </ol>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Cara melihat riwayat transaksi',
                        content: [
                            {
                                text: `
                                        Untuk melihat semua riwayat transaksi keuangan anda, ikuti langkah berikut
                                        <br /><br />
                                        <ol>
                                        <li>Masuk ke menu <b>Dompet</b></li>
                                        <li>Pilih salah satu <b>Dompet Tunai</b> atau <b>Dompet Kredit</b></li>
                                        <li>Pilih cek <b>riwayat transaksi</b></li>
                                        <li>Akan muncul <b>semua riwayat transaksi keuangan</b> anda</li>
                                        </ol>
                                        `
                            }
                        ]
                    },
                ]
            },
            {
                title: 'Pengaturan Akun Saya',
                subdata: [
                    {
                        title: 'Pengaturan Profil Vendor',
                        content: [
                            {
                                text: `<strong><u>Cara mengubah no HP vendor</u></strong><br />
                                Anda dapat mengubah profil anda, seperti no HP, alamat email, berikut ini langkah-langkahnya
                                <ol>
                                     <li>Pilih menu <em>Akun</em></li>
                                     <li>Klik <em>icon</em> setting di sebelah kanan atas</li>
                                     <li>Klik <em>Ubah</em> di baris kanan <em>no HP</em></li>
                                     <li>Masukan no HP yang baru</li>
                                     <li>Tekan berikutnya, anda akan menerima SMS kode verifikasi</li>
                                     <li>Masukan kode verifikasi</li>
                                     <li>Tekan <em>Submit</em></li>
                                     <li>Berhasil</li>
                                </ol>
                                
                                <strong><u>Cara mengubah alamat e-mail vendor</u></strong><br />
                                <ol>
                                     <li>Pilih menu <em>Akun</em></li>
                                     <li>Klik <em>icon</em> setting di sebelah kanan atas</li>
                                     <li>Klik <em>Ubah</em> di baris kanan <em>e-mail</em></li>
                                     <li>Masukan alamat e-mail yang baru</li>
                                     <li>Tekan berikutnya, anda akan menerima SMS kode verifikasi</li>
                                     <li>Masukan kode verifikasi</li>
                                     <li>Tekan <em>Submit</em></li>
                                     <li>Berhasil</li>
                                </ol>
                                
                                <strong><u>Cara mengubah PIN </u></strong><br />
                                <ol>
                                     <li>Pilih menu <em>Akun</em></li>
                                     <li>Klik icon setting di sebelah kanan atas</li>
                                     <li>Klik <em>Ubah</em> di baris kanan <em>ubah PIN</em></li>
                                     <li>Masukan no PIN lama anda</li>
                                     <li>Masukan no PIN baru anda</li>
                                     <li>Konfirmasi no PIN baru anda</li>
                                     <li>Tekan <em>Konfirmasi</em></li>
                                     <li>Berhasil</li>
                                </ol>
                                
                                <strong><u>Cara menambahkan atau mengubah kontak darurat </u></strong><br />
                                <ol>
                                     <li>Pilih menu <em>Akun</em></li>
                                     <li>Klik <em>icon</em> setting di sebelah kanan atas</li>
                                     <li>Klik <em>+ tambahkan</em> di baris kanan kontak darurat</li>
                                     <li>Masukan nama kontak darurat anda</li>
                                     <li>Masukan no HP kontak darurat anda</li>
                                     <li>Pilih <em>Hubungan</em> anda dengan kontak tersebut</li>
                                     <li>Tekan Tambahkan</li>
                                     <li>Berhasil</li>
                                </ol>`
                            }
                        ]
                    },
                    {
                        title: 'Nomor telepon yang didaftarkan sudah tidak aktif / hilang',
                        content: [
                            {
                                text: `Jika nomor lama anda sudah tidak aktif atau hilang, anda bisa menajukan perubahan dengan menghubungi Admin Halojasa segera. Akan ada klarifikasi oleh admin halojasa dengan melakukan wawancara melalui Video Call.`
                            }
                        ]
                    }
                ]
            },
            {
                title: 'Informasi pesanan',
                subdata: [
                    {
                        title: 'Bagaimana cara membatalkan pesanan yang sudah diterima?',
                        content: [
                            {
                                text: `
                                        Jika anda ingin melakukan <b>pembatalan</b>, karena <b>customer tidak bisa dihubungi</b>, ataupun karena <b>permintaan tidak sesuai dengan aplikasi</b>, berikut ini langkah-langkahnya:
                                        <br /><br />
                                        <ol>
                                        <li>Pada tampilan order di aplikasi anda pilih icon ini</li>
                                        <li>Anda akan masuk ke menu lainnya</li>
                                        <li>Pilih batalkan order ini</li>
                                        <li>Pilih salah satu alasan, dan jelaskan alasan anda di text box yang ada.</li>
                                        <li>Pilih kirim</li>
                                        <li>Order telah berhasil di batalkan</li>
                                        </ol>
                                        <br /><br />
                                        Catatan: <b>Pastikan anda selalu menyelesaikan order yang masuk agar penghasilan anda lebih optimal</b>.
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Bagaimana cara melihat histori transaksi pesanan?',
                        content: [
                            {
                                text: `
                                        <p>Untuk melihat semua riwayat transaksi keuangan anda, ikuti langkah berikut</p>
                                        <ol>
                                        <li>Masuk ke menu&nbsp;<strong><em>Dompet</em></strong></li>
                                        <li>Pilih salah satu&nbsp;<strong><em>Dompet Tunai</em>&nbsp;</strong>atau&nbsp;<strong><em>Dompet Kredit</em></strong></li>
                                        <li>Pilih cek&nbsp;<strong><em>riwayat transaksi</em></strong></li>
                                        <li>Akan muncul&nbsp;<strong>semua riwayat transaksi keuangan</strong>&nbsp;anda</li>
                                        </ol>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Bagaimana cara menghubungi Konsumen?',
                        content: [
                            {
                                text: `<ul>
                                <li>Pilih menu order</li>
                                <li>Klik di order aktif</li>
                                <li>Jika tidak ada tampilan order tetapi kamu merasa memiliki order, segera refresh dengan swipe layar ke bawah.</li>
                                <li>Klik order yang konsumenya ingin kamu hubungi untuk memasuki detail order</li>
                                <li>Klik Call atau Text untuk menghubungi konsumen.</li></ul>
                                `
                            }
                        ]
                    },
                    {
                        title: 'Terlambat Bidding Order',
                        content: [
                            {
                                text: `
                                        Jika anda sering kalah dalam <b>Bid Order</b>. Ikuti langkah-langkah berikut
                                        <br /><br />
                                        <ol>
                                        <li>Pastikan jaringan internet anda stabil</li>
                                        <li>Silahkan logout terlebih dahulu, kemudian login kembali</li>
                                        <li>Ayo semangat… Anda belum beruntung… selalu tingkatkan kecepatan anda dalam mengambil order..</li>
                                        </ol>                  
                                        `
                            }
                        ]
                    }
                ]
            },
            {
                title: 'Kendala dengan costumer',
                subdata: [
                    {
                        title: 'Konsumen sulit atau tidak dapat dihubungi',
                        content: [
                            {
                                text: 'Mohon Maaf atas kejadian ini, silahkan lakukan pembatalan order. Terima kasih.'
                            }
                        ]
                    },
                    {
                        title: 'Konsumen tidak berada di tempat',
                        content: [
                            {
                                text: 'Mohon maaf atas kejadian ini, silahkan lakukan pembatalan order dan tim kami akan segera menindaklanjutinya. Mohon tuliskan detail kejadian dibawah ini. Terima kasih.'
                            }
                        ]
                    },
                    {
                        title: 'Jenis kelamin konsumen tidak sesuai dengan pesanan',
                        content: [
                            {
                                text: 'Mohon maaf atas ketidaknyamanan yang terjadi. Jika anda keberatan menerima order ini, silahkan melakukan pembatalan order. Terima kasih.'
                            }
                        ]
                    },
                    {
                        title: 'Permintaan konsumen yang tidak sesuai dengan ketentuan Halojasa',
                        content: [
                            {
                                text: 'Demi keamanan dan kenyamanan seluruh pihak, arahkan customer untuk membatalkan pesanan dan mengubah tempat layanan, atau anda dapat membatalkan order ini. Terima kasih.'
                            }
                        ]
                    }
                ]
            },
            {
                title: 'Kebijakan Halojasa',
                subdata: [
                    {
                        title: 'Kebijakan umum',
                        content: [
                            {
                                text: `
                                        Demi <b>menjamin kualitas Layanan Jasa</b> yang Anda berikan serta memberikan <b>keamanan pada Pencari Jasa</b>, Kami telah menetapkan Peraturan dan sanksi atas pelanggaran-pelanggaran yang mungkin Anda lakukan. 
                                        <br />
                                        Di bawah ini adalah informasi mengenai pelanggaran apa saja yang harus dihindari agar Anda tidak dikenakan sanksi yang telah ditetapkan:
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Kode etik : teknikal dan non-teknikal',
                        content: [
                            {
                                text: `
                                        Berikut ini Kode Etik vendor Halo Jasa yang berlaku
                                        <br />
                                        <table width="100%">
                                        <tbody>
                                        <tr>
                                        <td colspan="3" width="100%"><b>TECHNICAL</b></td>
                                        </tr>
                                        <tr>
                                        <td width="5%"><b>NO</b></td>
                                        <td width="42%"><b>PELANGGARAN</b></td>
                                        <td width="42%"><b>SANKSI</b></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">1</td>
                                        <td width="42%">Menerima pesanan namun tidak melaksanakannya.</td>
                                        <td width="42%">Pelanggaran Pertama: Surat Peringatan di aplikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend 3 hari</p>
                                        <p>Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi</p>
                                        <p>Pelanggraan Keempat : suspend Permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">2</td>
                                        <td width="42%">Vendor melakukan proses pengerjaan layanan jasa yang tidak sesuai dengan SOP, asal-asalan, tidak maksimal.</td>
                                        <td width="42%">Pelanggaran Pertama: Surat Peringatan di aplikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend 3 hari</p>
                                        <p>Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi</p>
                                        <p>Pelanggraan Keempat : suspend Permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">3</td>
                                        <td width="42%">Memanipulasi waktu sebelum atau sesudah proses layanan jasa.</td>
                                        <td width="42%">Pelanggaran Pertama: Surat Peringatan di aplikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend 3 hari</p>
                                        <p>Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi</p>
                                        <p>Pelanggraan Keempat : suspend Permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">4</td>
                                        <td width="42%">Menekan tombol “SELESAI” tanpa mengerjakan order.</td>
                                        <td width="42%">Pelanggaran Pertama: Surat Peringatan di aplikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend 3 hari</p>
                                        <p>Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi</p>
                                        <p>Pelanggraan Keempat : suspend Permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">5</td>
                                        <td width="42%">Melakukan konfirmasi Penyelesaian Order saat Pekerjaan Layanan belum selesai dilakukan.</td>
                                        <td width="42%">Pelanggaran Pertama: Surat Peringatan di aplikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend 3 hari</p>
                                        <p>Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi</p>
                                        <p>Pelanggraan Keempat : suspend Permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">6</td>
                                        <td width="42%">Menggunakan akun orang lain.</td>
                                        <td width="42%">Pelanggaran Pertama: Surat Peringatan di aplikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend 3 hari</p>
                                        <p>Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi</p>
                                        <p>Pelanggraan Keempat : suspend Permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">7</td>
                                        <td width="42%">Menolak melakukan Order Pekerjaan hingga 3 kali berturut-turut dalam 1 hari <em>(belum berlaku sampai ada pemberitahuan berikutnya)</em></td>
                                        <td width="42%">Pelanggaran Pertama: Surat Peringatan di aplikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend 3 hari</p>
                                        <p>Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi</p>
                                        <p>Pelanggraan Keempat : suspend Permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">8</td>
                                        <td width="42%">Sengaja memanipulasi untuk mencurangi sistem Halo Jasa&nbsp; seperti membuat orderan fiktif.</td>
                                        <td width="42%">Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend permanent</p>
                                        <p>&nbsp;</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">9</td>
                                        <td width="42%">Mencurangi sistem Halo Jasa untuk mendapatkan bonus atau insentif Vendor dengan cara apapun.</td>
                                        <td width="42%">Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend permanent</p>
                                        <p>&nbsp;</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">10</td>
                                        <td width="42%">Menyebarkan detail kontak (Nama, Alamat, nomor telephon, foto) dari Pencari Jasa ke pihak lain melalui berbagai media.</td>
                                        <td width="42%">Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend permanent</p>
                                        <p>&nbsp;</p></td>
                                        </tr>
                                        <tr>
                                        <td colspan="3" width="652"><b>NON TECHNICAL</b></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">11</td>
                                        <td width="42%">Tidak melakukan 3”S” (Senyum, Salam, Sapa) Kepada Customer / Pencari Jasa saat tiba dilokasi.</td>
                                        <td width="42%">Pelanggaran Pertama: Surat Peringatan di aplikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend 3 hari</p>
                                        <p>Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi</p>
                                        <p>Pelanggraan Keempat : suspend Permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">12</td>
                                        <td width="42%">Tidak berpenampilan profesional (Pakaian kusut, bau badan, tidak rapih, kotor).</td>
                                        <td width="42%">Pelanggaran Pertama: Surat Peringatan di aplikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend 3 hari</p>
                                        <p>Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi</p>
                                        <p>Pelanggraan Keempat : suspend Permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">13</td>
                                        <td width="42%">Atribut tidak lengkap (seragam &amp; peralatan kerja layanan).</td>
                                        <td width="42%">Pelanggaran Pertama: Surat Peringatan di aplikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend 3 hari</p>
                                        <p>Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi</p>
                                        <p>Pelanggraan Keempat : suspend Permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">14</td>
                                        <td width="42%">Menggunakan peralatan kerja yang kotor atau bau tidak sedap.</td>
                                        <td width="42%">Pelanggaran Pertama: Surat Peringatan di aplikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend 3 hari</p>
                                        <p>Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi</p>
                                        <p>Pelanggraan Keempat : suspend Permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">15</td>
                                        <td width="42%">Customer / Pencari Jasa tidak mendapat layanan dengan peralatan sesuai SOP Halo Jasa (Vendor tidak menggunakan tools yang ada di SOP).</td>
                                        <td width="42%">Pelanggaran Pertama: Surat Peringatan di aplikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend 3 hari</p>
                                        <p>Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi</p>
                                        <p>Pelanggraan Keempat : suspend Permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">16</td>
                                        <td width="42%">Penyedia Jasa yang datang tidak sesuai dengan yang tertera&nbsp; diaplikasi.</td>
                                        <td width="42%">Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend permanent</p>
                                        <p>&nbsp;</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">17</td>
                                        <td width="42%">Dalam proses pengerjaan layanan, Vendor tidak melakukan tahapan proses pengerjaan layanan jasa sesuai dengan ketentuan SOP Halo Jasa.</td>
                                        <td width="42%">Pelanggaran Pertama: Surat Peringatan di aplikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend 3 hari</p>
                                        <p>Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi</p>
                                        <p>Pelanggraan Keempat : suspend Permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">18</td>
                                        <td width="42%">Vendor dilarang&nbsp; merokok saat bersama Customer&nbsp; dalam proses pengerjaan layanan jasa.</td>
                                        <td width="42%">Pelanggaran Pertama: Surat Peringatan di aplikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend 3 hari</p>
                                        <p>Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi</p>
                                        <p>Pelanggraan Keempat : suspend Permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">19</td>
                                        <td width="42%">Menyebabkan kerusakan pada properti apapun yang adalah milik Customer.</td>
                                        <td width="42%">Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend permanent</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">20</td>
                                        <td width="42%">Menghilangkan properti pribadi apapun yang adalah milik Customer.</td>
                                        <td width="42%">Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend permanent</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">21</td>
                                        <td width="42%">Bersikap dan atau berkata tidak sopan, menghina, mengintimidasi,&nbsp; dan kasar terhadap Customer.</td>
                                        <td width="42%">Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend permanent</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">22</td>
                                        <td width="42%">Melakukan tindakan pelecehan seksual dalam proses pengerjaan layanan jasa.</td>
                                        <td width="42%">Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend permanent</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">23</td>
                                        <td width="42%">Datang ke lokasi Pengerjaan Layanan dalam kondisi terpengaruhi Alkohol, Zat Adiktif atau Psikotropika.</td>
                                        <td width="42%">Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend permanent</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">24</td>
                                        <td width="42%">Membatalkan pesanan tanpa alasan jelas atau tidak memberitahukan kepada Customer.</td>
                                        <td width="42%">Pelanggaran Pertama: Surat Peringatan di aplikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend 3 hari</p>
                                        <p>Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi</p>
                                        <p>Pelanggraan Keempat : suspend Permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">25</td>
                                        <td width="42%">Mendapat tuduhan dan atau terbukti melakukan tindakan kejahatan terhadap Customer.</td>
                                        <td width="42%">Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend permanent</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">26</td>
                                        <td width="42%">Membawa anak atau kerabat lainnya saat proses pengerjaan layanan.</td>
                                        <td width="42%">Pelanggaran Pertama: Surat Peringatan di aplikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend 3 hari</p>
                                        <p>Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi</p>
                                        <p>Pelanggraan Keempat : suspend Permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">27</td>
                                        <td width="42%">Memberikan kembalian kurang jika transaksi COD.</td>
                                        <td width="42%">Pelanggaran Pertama: Surat Peringatan di aplikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend 3 hari</p>
                                        <p>Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi</p>
                                        <p>Pelanggraan Keempat : suspend Permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">28</td>
                                        <td width="42%">Menghubungi Customer diluar kebutuhan order.</td>
                                        <td width="42%">Pelanggaran Pertama: Surat Peringatan di aplikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend 3 hari</p>
                                        <p>Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi</p>
                                        <p>Pelanggraan Keempat : suspend Permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">29</td>
                                        <td width="42%">Meminta Biaya Tambahan di luar biaya yang telah disepakati untuk layanan jasa yang telah dipesan melalui sistem Halo Jasa.</td>
                                        <td width="42%">Pelanggaran Pertama: Surat Peringatan di aplikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend 3 hari</p>
                                        <p>Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi</p>
                                        <p>Pelanggraan Keempat : suspend Permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">30</td>
                                        <td width="42%">Tidak bersedia memberikan garansi 3 hari atas jasa layanan yang yang telah dipesan melalui sistem Halo Jasa.. (Hanya berlaku pada Kategori Jasa cuci&nbsp; AC )</td>
                                        <td width="42%">Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend permanent</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">31</td>
                                        <td width="42%">Bebicara maupun bertindak meresahkan (secara lisan maupun tulisan) yang mengarah pada pertengkaran yang berujung kontak fisik kepada sesama Vendor, Customer, maupun Staff Halo Jasa.</td>
                                        <td width="42%">Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend permanent</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">32</td>
                                        <td width="42%">Dokumen resmi (KTP/SIM/SKCK) tidak lengkap dan tidak diperbaharui.</td>
                                        <td width="42%">Pelanggaran Pertama: Surat Peringatan di aplikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend 3 hari</p>
                                        <p>Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi</p>
                                        <p>Pelanggraan Keempat : suspend Permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">33</td>
                                        <td width="42%">Berucap/berbuat hal yang bersifat penghinaan SARA, pelecehan seksual kepada Pihak Customer.</td>
                                        <td width="42%">Pelanggaran Pertama: Pemutusan Kontrak secara permanen dan penghapusan akun dari sistem Halo-jasa</td>
                                        </tr>
                                        <tr>
                                        <td width="5%">34</td>
                                        <td width="42%">Melakukan tindak kejahatan yang berpotensi menimbulkan gugatan Pidana.</td>
                                        <td width="42%">Pelanggaran Pertama: Suspend permanen</td>
                                        </tr>
                                        <tr>
                                        <td width="5%">35</td>
                                        <td width="42%">Melakukan tindakan yang meresahkan seperti mengancam Customer maupun staff Halo Jasa.</td>
                                        <td width="42%">Pelanggaran Pertama: Suspend permanen</td>
                                        </tr>
                                        <tr>
                                        <td width="5%">36</td>
                                        <td width="42%">Menawarkan dan memberikan barang atau uang kepada Staff Halo Jasa yang bertujuan untuk menyalahi peraturan (gratifikasi).</td>
                                        <td width="42%">Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">37</td>
                                        <td width="42%">Memprovokasi Vendor lain untuk melakukan kegiatan yang dapat merugikan perusahaan (demonstrasi, razia, sweeping, dsb).</td>
                                        <td width="42%">Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend permanen</p></td>
                                        </tr>
                                        <tr>
                                        <td width="5%">38</td>
                                        <td width="42%">Menyebar Hoax, berita fitnah atau kesaksian palsu mengenai Halo Jasa, pihak Customer atau Vendor Halo Jasa lainnya untuk alasan apapun.</td>
                                        <td width="42%">Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi<p></p>
                                        <p>Pelanggaran Kedua: suspend permanen</p></td>
                                        </tr>
                                        </tbody>
                                        </table>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Amandemen dan adendum',
                        content: [
                            {
                                text: 'Belum ada amandemen ataupun addendum di folder ini'
                            }
                        ]
                    },
                    {
                        title: 'Program khusus',
                        content: [
                            {
                                text: 'Belum ada program khusus sampai saat ini'
                            }
                        ]
                    },
                    {
                        title: 'Sistem bagi hasil dan pemberian insentif',
                        content: [
                            {
                                text: `
                                        Kerjasama Halo Jasa dan Vendor merupakan kerjasa bagi hasil dari harga layanan.
                                        <br /><br />
                                        Yaitu <b>80 % untuk Vendor, 20% untuk Aplikator</b>
                                        <br /><br />
                                        Vendor juga berhak mendapatkan Insentif dengan ketentuan berikut:
                                        <br /><br />
                                        <ul>
                                        <li><b>Insentif 5%</b> dari harga layanan untuk vendor yang datang tepat waktu</li>
                                        <li><b>Insentif 5%</b> dari harga layanan untuk <b>vendor yang mendapatkan rating bintang empat</b></li>
                                        <li><b>Insentif 10%</b> dari harga layanan untuk <b>vendor yang mendapatkan rating bintang Lima</b></li>
                                        </ul>
                                        `
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        id: 2,
        title: 'Pengetahuan',
        child: [
            {
                title: 'Berita Terkini Halo-jasa',
                subdata: [
                    {
                        title: 'Cara Mengupdate Suhu Tubuh Di Dalam Aplikasi',
                        content: [
                            {
                                text: `
                                        Melakukan update suhu tubuh adalah kewajiban bagi seluruh vendor.<br />
                                        <ol>
                                            <li>Klik tombol Upload pada beranda aplikasi vendor anda.
                                                <div style="width: 100%; margin: auto; display: block; ">
                                                    <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/Update-Suhu-Tubuh-1-klik.jpg" alt="" />
                                                </div>
                                            </li>
                                            <li>Foto diri Anda sedang memegang thermometer. Pastikan angka terlihat jelas seperti contoh.
                                                <div style="width: 100%; margin: auto; display: block; ">
                                                    <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/Update-Suhu-Tubuh-2-klik.jpg" alt="" />
                                                </div>
                                            </li>
                                            <li>Jangan lupa untuk input manual angka suhu tubuh berdasarkan hasil pengukuran thermometer pada kolom yang disediakan.
                                                <div style="width: 100%; margin: auto; display: block; ">
                                                    <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" class="aligncenter wp-image-4095 size-full" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/Update-Suhu-Tubuh-3-klik.jpg" alt="" />
                                                </div>
                                            </li>
                                            <li>Update suhu tubuh Anda setiap hari untuk mulai dapat mengambil order.
                                                <div style="width: 100%; margin: auto; display: block; ">
                                                    <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/Update-Suhu-Tubuh-4.jpg" alt="" />
                                                </div>
                                            </li>
                                        </ol>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Penambahan Standar Layanan Halo Jasa Selama Pandemi COVID-19',
                        content: [
                            {
                                text: `
                                        <div style="width: 100%; margin: auto; display: block; ">
                                        <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/sop-baru-pandemi-halo-jasa_n.jpg" alt="" />
                                        </div>
                                        Mengikuti anjuran pemerintah untuk menekan angka penyebaran COVID-19. Halo Jasa menerapkan sejumlah aturan tambahan yang wajib dipenuhi semua vendor halojasa.
                                        <ol>
                                            <li>Vendor diwajibkan untuk mengenakan masker dan face shield selama proses layanan di tempat pelanggan.</li>
                                            <li>Mengganti serta mencuci masker yang sudah terpakai.</li>
                                            <li>Memastikan pakaian kerja steril dengan mencuci bersih serta melakukan penyemprotan disinfektan ketika bertemu dengan customer.</li>
                                            <li>Meminta izin untuk mencuci tangan dengan sabun sebelum dan sesudah melakukan layanan.</li>
                                            <li>Melakukan ukur suhu tubuh dengan menggunakan thermometer. Ambil foto diri (selfie) bersama dengan hasil cek thermometer dan upload melalui aplikasi vendor Anda.</li>
                                        </ol>
                                        <br />
                                        Silahkan ambil <em>Masker </em>dan <em>Face Shield</em> Anda di kantor Halo Jasa.
                                        <br />
                                        Aturan ini bersifat mengikat dan wajib dipatuhi seluruh vendor. Vendor yang tidak mengikuti aturan akan dikenakan sanksi hingga suspend akun dan tidak akan mendapatkan pop out order.
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Cara Penukaran Voucher',
                        content: [
                            {
                                text: `
                                        <div style="width: 100%; margin: auto; display: block; ">
                                        <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/cara-tukar-voucher-halo-jasa_n.jpg" alt="" />
                                        </div>
                                        <ol>
                                            <li>Cek jenis voucher yang anda terima. Terdapat dua jenis voucher yang diterbitkan halojasa, voucher khusus layanan Halo Clean dan Auto, serta voucher untuk segala jenis layanan.</li>
                                            <li>Isi kolom Order ID pada lembar voucher, Anda bisa mendapatkan Order ID pada aplikasi Anda. Klik tab pemesanan dan cari order yang sedang anda kerjakan.</li>
                                            <li>Tulis Order ID pada lembar voucher, foto dan kirim pada admin Maudy (0838-7123-1574). Voucher harus di klaim dalam waktu 2x24 jam. Voucher yang tidak di-klaim dalam rentang waktu tersebut akan hangus.</li>
                                            <li>Voucher akan dicek dan diverifikasi dalam waktu 3x24 jam. Pencairan dana akan dikirim ke saldo tunai vendor.</li>
                                        </ol>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Halo Jasa Turut Berpartisipasi Dalam Bursa Tenaga Kerja Nandiri Depnaker',
                        content: [
                            {
                                text: `
                                        Pada tanggal 16 Desember 2019, Halo Jasa bersama beberapa startup ternama di Indonesia lainnya mengikuti acara Bursa Kerja Mandiri yang diadakan di Innovation Room, Talent Hub kantor kementerian tenaga kerja. Acara ini diadakan bertujuan memberikan pengalaman langsung kepada para pekerja mandiri atau bisa disebut freelancer di beragam sektor jasa.
                                        <br /><br />
                                        “Sebagai bentuk partisipasi kami terhadap program pemerintah dalam meningkatkan lapangan pekerjaan, maka Halo Jasa turut mengikuti bursa tenaga kerja hari ini. Hal ini juga sesuai dengan misi kami yaitu memberdayakan masyarakat informal melalui program dan sertifikasi pelatihan hingga meningkatkan lapangan pekerjaan baru,” ujar Chief operating officer (COO) Halo Jasa, Thomas Edyson. Selain itu, diharapkan peningkatan kesejahteraan kepada para vendor dapat meningkat.
                                        <br /><br />
                                        “Inisiatif menyelenggarakan Bursa Kerja Mandiri sejalan dengan upaya pemerintah dalam mengajak pelaku industri untuk sama-sama berkontribusi mewujudkan SDM unggul,” ujar sekretaris jenderal kemenaker, Khairul anwar saat pembukaan Bursa Kerja Mandiri di Kementerian Ketenagakerjaan. Semangat peningkatan produktivitas dalam bekerja juga terus digalakkan agar masyarakat yang belum mendapatkan pekerjaan untuk terus bersemangat dalam mencari pekerjaan.
                                        <br /><br />
                                        Selama dua hari penyelenggaraan acara pada 16-17 Desember 2019, acara ini dihadiri oleh ratusan pencari kerja yang memadati area acara seharian penuh. Mereka sangat antusias untuk bisa bekerja mandiri daripada mengandalkan lowongan-lowongan dari berbagai perusahaan.
                                        <br /><br />
                                        Halo Jasa mencatat, sebanyak 276 peserta mendatangi booth Halo Jasa dan mendaftarkan diri untuk menjadi calon vendor perusahaan. Di hari kedua, meski relatif lebih lengang dari hari pertama, 38 peserta telah terdaftar untuk menjadi calon vendor. Setelah mengisi formulir pendaftaran yang disediakan oleh tim akuisisi Halo Jasa, para vendor tersebut harus mengikuti langkah selanjutnya yaitu hadir dalam info session yang diadakan sesudahnya.
                                        <br /><br />
                                        “Halo Jasa selalu aktif terlibat dalam peningkatan impact social masyarakat di Indonesia. Setelah melalui acara ini, kami akan hadir di banyak bursa tenaga kerja lainnya.” Tutup Edyson.                               
                                        `
                            }
                        ]
                    },
                ]
            },
            {
                title: 'Layanan Halo Massage',
                subdata: [
                    {
                        title: 'Fitur Favorit Halo Clean Dan Halo Massage',
                        content: [
                            {
                                text: `
                                        Tahukah Anda, bahwa di <b>aplikasi vendor Halo Jasa terdapat fitur bernama “Fitur Favorit”</b> khusus untuk vendor Halo Massage dan Halo Clean. Fitur ini memudahkan pelanggan memilih penyedia jasa (Talent) yang diinginkan.
                                        <br /><br />
                                        Pelanggan dapat menandai talent yang mereka sukai sebagai Fitur Favorit agar pada order selanjutnya, pelanggan dapat langsung memilih talent yang diinginkan.
                                        <br /><br />
                                        Fitur ini memudahkan vendor dalam menyelesaikan order, karena telah berpengalaman dalam menangani pelanggan yang serupa sebelumnya. Pelanggan pun tidak perlu repot menjelaskan kembali proses pengerjaan yang diinginkan kepada vendor.<br />
                                        Berikut tampilan fitur favorit dalam aplikasi Halo Jasa.
                                        *tampilkan screenshot.
                                        <br /><br />
                                        Diharapkan dengan adanya fitur favorit, kesempatan vendor mendapatkan order secara langsung akan semakin meningkat.
                                        <br />
                                        Jangan lupa, terus berikan pelayanan terbaik Anda di setiap order, agar Anda bisa ditandai sebagai penyedia jasa favorit oleh pelanggan.<br />
                                        Semoga artikel ini bermanfaat untuk Anda.    
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Jagalah Kebersihan Selalu Bagi Vendor Halo Massage',
                        content: [
                            {
                                text: `
                                        Salah satu kewajiban dari vendor ialah menjaga kebersihan peralatan pekerjaan. Hal ini penting agar ancaman kuman penyebab penyakit dapat terhindarkan. Maka dari itu, berikut kami berikan <b>tips dan panduan khusus bagi Vendor Halo Massage dalam menjaga kebersihan peralatan kerjanya.</b>
                                        <br /><br />
                                        <b>Kebersihan dimulai dari diri sendiri</b>
                                        -Mandi yang rutin minimal dua kali dalam sehari
                                        -Cuci rambut dengan keramas minimal dua hari sekali dalam seminggu.
                                        -Menggunting kuku dengan frekuensi minimal seminggu sekali.
                                        -Membersihkan telinga setelah mandi.
                                        -Menghilangkan bau badan. Jika Anda memiliki masalah ini, Tidak perlu bagi Anda memakai parfum mahal. Cukup dengan sedikit bedak MBK di area ketiak. Bedak ini terbukti efektif dengan harga terjangkau.
                                        <br /><br />
                                        
                                        <b>Perhatikan pula kebersihan baju dan alas kaki.</b>
                                        -Kami menyarankan vendor memiliki dua baju seragam, sehingga Anda dapat mencuci serta memakainya bergantian.
                                        -Tidak perlu menggunakan kaos dalaman saat mengenakan seragam. Karena seragam kami terbukti ampuh dalam menyerap keringat.
                                        -Gunakan seragam dengan pakaian bagian bawah atau bawahan yang sesuai. Kami menyarankan menggunakan celana bahan kain.
                                        -Rutinlah mengganti kaos kaki sesuai kebutuhan, agar kaki tidak berbau atau mengeluarkan aroma tidak sedap.
                                        -Gunakan alas kaki yang bersih.
                                        
                                        <br /><br />
                                        Selain diri sendiri, penting menjaga dan merawat peralatan yang vendor gunakan saat bekerja. Maka dari itu, cucilah seluruh peralatan yang digunakan oleh satu pelanggan dimulai dari sprei, kain panjang, handuk kecil, hingga waslap dsb. Hal ini penting agar :
                                        <br />
                                        -<b>Mencegah penyakit menular.</b> Semisal kain panjang dan sprei yang telah digunakan satu pelanggan tidak digunakan kembali di pelanggan berikutnya.
                                        <br />
                                        -<b>Mencegah timbul bau tidak sedap.</b> Hal ini muncul jika peralatan tidak dibersihkan dan menyebabkan endapan kotoran pada peralatan.
                                        <br /><br />
                                        Dengan menjadi vendor terampil dalam menjaga kebersihan diri dan peralatan kerja tentu membuat Anda akan lebih dipercaya serta disukai pelanggan. Jika pelanggan sudah percaya, maka kesempatan Anda menjadi mitra favorit pelanggan lebih besar serta mendapatkan order yang lebih banyak.
                                        <br /><br />
                                        Semoga artikel ini bermanfaat bagi Anda.     
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Memaksimalkan Kinerja Waktu Halo Massage',
                        content: [
                            {
                                text: `
                                        Sebagai vendor Halo Massage, waktu merupakan hal berharga di mana perlu ketepatan waktu. Dan telah menjadi kewajiban Anda untuk selalu hadir tepat waktu sesuai jam yang ditentukan oleh pelanggan pada aplikasi vendor Halo Jasa.
                                        <br /><br />
                                        Demi kemudahan Anda dalam mengatur waktu saat menjalankan order, simak tips berikut.
                                        <br /><br />
                                        1.Saat sampai di lokasi pengerjaan, beri tahu pelanggan saat Anda akan mulai mengerjakkan layanan yang dipesan dengan menunjukkan atau mengkonfirmasikan jenis layanan termasuk durasi yang dipilih pelanggan. Hal ini telah tertera di dalam aplikasi vendor Halo Jasa dan segera tekan tombol “mulai” setelah pelanggan memastikan.<br />
                                        2.Harap diingat, waktu layanan akan dimulai saat peralatan pijat sudah Anda telah siap. Bukan saat Anda telah tiba di lokasi pemesanan.<br />
                                        3.Dapat memperkirakan durasi waktu untuk setiap bagian tubuh dengan benar.<br /><br />
                                        
                                        <b>Contoh.<br />
                                        Full Body Massage – 60 (enam puluh) menit<br /><br />
                                        
                                        <ul>
                                        <li>5 menit untuk Warming up (Pemanasan) dan Stretching (Peregangan)</li>
                                        <li>40 menit untuk Telungkup : memijat pada kaki dan punggung</li>
                                        <li>15 menit untuk Telentang : memijat pada kaki, tangan, dan kepala</li>
                                        </ul></b>
                                        <br /><br />
                                        -Dengan perkiraan waktu tersebut, tentu Anda dengan mudah dapat melakukan pengulangan gerakan. Missal dalam 60 menit tersebut, Anda dapat melakuan pengurangan pergerakan sebanyak 3 kali (Jika memungkinkan).
                                        -Anda pun dapat melakukan gerakan pijat sambil mengatur irama yang disesuaikan dengan memperhatikan alur nafas pelanggan, sehingga gerakan pijat tak terasa terburu-buru atau cepat.
                                        <br /><br />
                                        Semoga artikel ini bermanfaat. 
                                        `
                            }
                        ]
                    },
                ]
            },
            {
                title: 'Layanan Halo Clean',
                subdata: [
                    {
                        title: 'Fitur Favorit Halo Clean Dan Halo Massage',
                        content: [
                            {
                                text: `
                                        Tahukah Anda, bahwa di <b>aplikasi vendor Halo Jasa terdapat fitur bernama “Fitur Favorit”</b> khusus untuk vendor Halo Massage dan Halo Clean. Fitur ini memudahkan pelanggan memilih penyedia jasa (Talent) yang diinginkan.
                                        <br /><br />
                                        Pelanggan dapat menandai talent yang mereka sukai sebagai Fitur Favorit agar pada order selanjutnya, pelanggan dapat langsung memilih talent yang diinginkan.
                                        <br /><br />
                                        Fitur ini memudahkan vendor dalam menyelesaikan order, karena telah berpengalaman dalam menangani pelanggan yang serupa sebelumnya. Pelanggan pun tidak perlu repot menjelaskan kembali proses pengerjaan yang diinginkan kepada vendor.<br />
                                        Berikut tampilan fitur favorit dalam aplikasi Halo Jasa.
                                        *tampilkan screenshot.
                                        <br /><br />
                                        Diharapkan dengan adanya fitur favorit, kesempatan vendor mendapatkan order secara langsung akan semakin meningkat.
                                        <br />
                                        Jangan lupa, terus berikan pelayanan terbaik Anda di setiap order, agar Anda bisa ditandai sebagai penyedia jasa favorit oleh pelanggan.<br />
                                        Semoga artikel ini bermanfaat untuk Anda.    
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Cara Menghilangkan Jamur di Kamar Mandi',
                        content: [
                            {
                                text: `
                                        Jamur tumbuh akibat kelembapan dan sisa air yang berkembang pesat. Anda dapat dengan mudah membasminya dengan menggunakan pembersih dasar melalui langkah dan bahan berikut.
                                        <br /><br />
                                        <b>Karbol</b>
                                        Buat larutan campuran karbol dan air dengan perbandingan <b>takaran 1:3 (Karbol) : (Air)</b>
                                        <ol>
                                        <li>Masukkan campuran ke dalam botol semprot</li>
                                        <li>Semprotkan laruan campuran tersebut ke bagian dinding kamar mandi yang berjamur</li>
                                        <li>Sikat dengan sikat kaku</li>
                                        <li>Bilas dengan air hingga bersih</li>
                                        </ol>
                                        <br /><br />
                                        <b>Soda kue dan pemutih</b>
                                        Untuk menghilangkan sebagian besar jenis jamur, bisa menggunakan campuran air panas dengan <b>sabun dan soda kue</b>. Campur bahan-bahan tersebut hingga <b>berbentuk seperti pasta</b>.
                                        <br />
                                        <ol>
                                        <li>Campuran tersebut dimasukkan ke dalam botol semprot</li>
                                        <li>Semprotkan area yan berjamur dan biarkan mongering</li>
                                        <li>Semprot untuk kedua kalinya lalu gosok dengan sikat</li>
                                        <li>Bilas dan ulangi langkah yang sama hingga bersih jika diperlukan.</li>
                                        </ol>
                                        <br /><br />
                                        <b>Kaporit</b>
                                        <ol>
                                        <li>Basahi area yang berjamur dengan air</li>
                                        <li>Taburkan kaporit</li>
                                        <li>Diamkan 30 menit</li>
                                        <li>Sikat dengan sikat kaku</li>
                                        <li>Siram dengan air hingga bersih</li>
                                        </ol>
                                        <br /><br />
                                        Semoga artikel ini bermanfaat.
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Panduan Membersihkan Kloset Kamar Mandi',
                        content: [
                            {
                                text: `
                                        <p>Dalam membersihkan tempat tinggal, salah satu aspek penting ialah kebersihan kamar mandi, terutama kloset. Berikut kami berikan&nbsp;<strong>cara membersihkan kloset kamar mandi.</strong></p>
                                        <p><strong>Persiapkan perlengkapan berikut yaitu :</strong><br /><strong>Karbol</strong><br />Sebagai pembersih toilet yang standar. Karbol yang mengandung desinfektan juga bisa dipakai sekaligus pengharum toilet.</p>
                                        <p><strong>Penghilang kerak kapur dan serbuk mengandung bahan pembersih ampuh</strong><br />Bahan ini mengandung pembersih yang ampuh seperti boraks. Cocok untuk membersihkan secara mendalam atau mengatasi noda membandel.</p>
                                        <p><strong>Sikat toilet</strong><br />Dengan bahan-bahan plastik lebih baik dari pada bahan kawat agar tidak menggores permukaan keramik WC toilet.</p>
                                        <p><strong>Sebagai alternatif, berikut bahan yang bisa dipakai sebagai bahan alternatif pembersih toilet</strong></p>
                                        <p><strong>Cuka</strong><br />Efektif dalam menghilangkan noda dan bau. Tuangkan 3 cangkir cuka ke dalam mangkok toilet untuk menutup seluruh permukaan. Lalu gosok menggunakan sikat WC. Sebagai langkah perawatan, tuang 2 cangkir cuka yang disiramkan ke dalam toilet sekali dalam sebulan yang terbukti mampu mencegah kerak kapur.</p>
                                        <p><strong>Minuman berkarbonasi</strong><br />Terbukti membersihkan toilet karena zat asamnya mampu membunuh bakteri dan gelembung-gelembung udaranya berguna membantu meluruhkan kerak kapur. Siram minuman bersoda di sepanjang pinggiran bagian dalam dan mangkuk toilet serta diamkan selama satu jam atau hingga semalam jika memungkinkan.</p>
                                        <p>Semoga artikel ini bermanfaat, jangan ragu menghubungi admin vendor Halo Jasa jika terdapat kesulitan apapun.</p>
                                        `
                            }
                        ]
                    },
                ]
            },
            {
                title: 'Layanan Halo Auto',
                subdata: [
                    {
                        title: 'Mencegah Baret Menggunakan Lap Microfiber',
                        content: [
                            {
                                text: `
                                        Perlengkapan apa yang mutlak dibutuhkan vendor Halo Jasa ? sudah pasti banyak, dan salah satunya adalah kain lap yang digunakan vendor Halo Auto dan Halo Clean. Banyak ragamnya kain lap yang dapat digunakan. Salah satu terpopuler adalah berjenis microfiber yang memiliki keunggulan menyerap air hingga enam kali lebih banyak dibanding bobot lap itu sendiri. Selain itu, ia juga bisa menahan bahan-bahan polish dan wax, dua komponen pelicin yang sering digunakan untuk keperluan detailing otomotif.
                                        <br /><br />
                                        Pemakaian jenis lap ini sangat mudah. Anda cukup membasahi dengan air bersih.<br />
                                        Berikut <b>tips anti baret</b> dengan <b>menggunakan lap microfiber</b>.
                                        <br /><br />
                                        <ol>
                                        <li>Basahkan terlebih dahulu lap microfiber dengan air bersih sebelum menggunakannya. Lalu peras lap sampai pada tingkat kebasahan yang diinginkan.</li>
                                        <li>Selalu pastikan tidak ada butiran pasir, kotoran, maupun benda lain yang menempel pada permukaan lap. Biasanya, inilah penyebab utama baret pada kendaraan Anda saat menggunakan lap microfiber.</li>
                                        <li>Setelah menggunakan lap microfiber, jangan lupa mencucinya atau semprot menggunakan air bersih bertekanan tinggi agar kotoran tidak menjadi noda di permukaan lap.</li>
                                        <li>Dan pastikan pula, untuk menyimpan lap microfiber pada wadahnya yang sesuai di mana bertemperature kering. Di Halo Jasa, kami mewajibkan lap microfiber berwarna hijau, orange, biru, dan pink untuk digunakan vendor Halo Clean. Sedangkan lap berwarna hijau, ungu, dan orange digunakan untuk layanan Halo Auto.</li>
                                        </ol>
                                        <br /><br />
                                        Dengan dirawat dan dibersihkannya peralatan kerja, maka dapat menunjang dengan baik segala bentuk pekerjaan yang diberikan. Hal ini juga menjadi tanda profesionalisme vendor Halo Jasa.
                                        <br /><br />
                                        Semoga artikel ini bermanfaat.   
                                        `
                            }
                        ]
                    }
                ]
            },
            {
                title: 'Layanan Halo Fix',
                subdata: [
                    {
                        title: 'Bersihkan AC Dengan Panduan Singkat Berikut',
                        content: [
                            {
                                text: `
                                        Saat menjalankan layanan cuci AC, terdapat beberapa kemungkinan yang dapat terjadi usai dilakukan pencucian AC. Diantaranya remote AC tidak berfungsi, air menetes dalam indoor unit, AC yang kurang dingin, dsb. Maka dari itu, terdapat beberapa hal yang sebaiknya diperhatikan sebelum melakukan pencucian AC sebagai berikut.
                                        <br /><br />
                                        <ol>
                                        <li>Hidupkan unit AC dan periksa, apakah berfungsi normal atau adakah yang rusak dan memerlukan perbaikan lebih lanjut.</li>
                                        <li>Lepaskan kabel power dari sumber listrik.</li>
                                        <li>Modul/PCB indoor ditutup dengan plastik.</li>
                                        <li>Lubang drain indoor harus dipastikan tidak tersumbat dengan cara menyemprotkan langsung nozzle steam ke lubang drain (Di mana dapat disambung dengan selang)</li>
                                        <li>Tahan fan/blower indoor dengan tangan maupun obeng saat melakukan penyemprotan ke sirip-sirip blower indoor.</li>
                                        <li>Dalam memasang cover indoor, pastikan seluruh pengait terpasang dengan pas dan kuat sehingga menghilangkan suara berisik.</li>
                                        </ol>
                                        <br /><br />
                                        Demikianlah beberapa tips yang bisa Anda terapkan agar pelayanan cuci AC maksimal dan tak mengakibatkan kesulitan sesudahnya. Semoga artikel ini bermanfaat.
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Berikut Cara Menghitung Ukuran Split AC',
                        content: [
                            {
                                text: `
                                        <p>Sebagai alat yang memiliki fungsi mengkondisikan udara agar terasa nyaman, AC memiliki beberapa faktor agar udara terasa selalu nyaman. Seperti berikut.<br /><strong>-Temperatur/suhu</strong><br /><strong>-Kelembapan relatif</strong><br /><strong>-Kebersihan udara</strong><br /><strong>-Bau yang muncul</strong><br /><strong>-Kecepatan udara</strong></p>
                                        <p>Dari 5 faktor tersebut, prinsip kerjanya ialah menyerap panas dalam ruangan dengan indoor unit karena panas tersebut terus menerus diserap, maka temperature ruangan menjadi sejuk (lebih rendah disbanding suhu lingkungan sekitar). AC yang digunakan pun harus disesuaikan dengan ukuran ruangan agar bekerja optimal. Berikut cara menentukan AC yang tepat sesuai ukurang ruangan.<br />Estimasi beban pendinginan (berdasarkan referensi dari Panasonic)<br />( L x W x H x Faktor 1) + (Jumlah orang x Faktor 2)</p>
                                        <p><strong>Keterangan:</strong><br /><strong>L: Panjang</strong><br /><strong>W: Lebar</strong><br /><strong>H: Tinggi</strong></p>
                                        <p><strong>Faktor 1:</strong><br />&bull;Kamar tidur = 5<br />&bull;Kantor atau ruang keluarga = 6<br />&bull;Restoran = 7</p>
                                        <p><strong>Faktor 2:</strong><br />&bull;Dewasa = 600 Btu<br />&bull;Anak-anak = 300 Btu<br />Contoh:<br />Sebuah kamar tidur memiliki ukuran panjang = 5m, lebar = 4m dan tinggi = 3m. Kamar tersebut dihuni oleh 1 (satu) orang anak dan 1 (satu) orang dewasa. Berapa kapasitas AC yang diperlukan kamar tidur tersebut?</p>
                                        <p><strong>Estimasi:</strong><br />( L x W x H x Faktor 1) + (Jumlah orang x Faktor 2)<br />= (5 x 4 x 3 x 5 x 37) + (1 x 600 + 1 x 300)<br />= 11.100 + 900<br />= 12.000 Btu</p>
                                        <p>Selanjutnya, cocokkan angka dari hasil perhitungan di atas dengan pembagian berikut ini:<br />&bull;5000 Btu/h gunakan AC berukuran &frac12; PK<br />&bull;7000 Btu/h gunakan AC berukuran &frac34; PK<br />&bull;9000 Btu/h gunakan AC berukuran 1 PK<br />&bull;12000 Btu/h gunakan AC berukuran 1&frac12; PK<br />&bull;18000 Btu/h gunakan AC berukuran 2P&frac12;K</p>
                                        <p>Maka, AC yang diperlukan untuk kamar tersebut berukuran 1&frac12; PK.<br />Adapun lokasi pemasangan indoor unit tidak boleh berdekatan dengan sumber panas dan sirkulasi udara tidak boleh terhalang oleh benda lain.</p>
                                        <p>Demikian cara menghitung ukuran AC yang kita butuhkan berdasarkan ukuran ruangan beserta penghuninya.<br />Sebagai tambahan, peralatan yang dirawat dan dibersihkan dengan baik tentu akan menunjang dalam pekerjaan sehari-hari.<br />Semoga artikel ini bermanfaat</p>
                                        `
                            }
                        ]
                    }
                ]
            },
            {
                title: 'Tata Cara Berprilaku Vendor',
                subdata: [
                    {
                        title: 'Ikuti Langkah Agar Selalu Tepat Waktu ke Lokasi Pelanggan',
                        content: [
                            {
                                text: `
                                        <p>Rating dan insentif bagi vendor ditentukan oleh banyak hal, salah satunya ketepatan waktu kedatangan di lokasi pemesanan. Yuk ikuti tips berikut agar Anda senantiasa tepat waktu.</p>
                                        <p><strong>1. Persiapkan segala sesuatu sehari sebelumnya</strong><br />Banyak penyebab keterlambatan vendor tiba di tempat tujuan akibat terburu-buru dalam mempersiapkan segala keperluan untuk pekerjaan. Oleh karena itu, persiapkan segala sesuatu keperluan jauh waktu sebelumnya. Bisa sejam atau semalam sebelumnya.</p>
                                        <p><strong>2. Atur dan pasang alarm</strong><br />Mempersiapkan segala sesuatu dari jauh hari terkadang tidak cukup. Maka, atur dan pasang alarm agar tidak terburu-buru sebelum berangkat ke tempat order layanan.</p>
                                        <p><strong>3. Istirahat atau tidur lebih awal</strong><br />Penting mengetahui jam istirahat yang cukup bagimu setelah beraktivitas. Dengan istirahat yang cukup, maka pekerjaan berikutnya dapat dikerjakan dengan baik karena energi telah terisi.</p>
                                        <p><strong>4. Berangkat lebih awal</strong><br />Problem utama di jalanan, khususnya di kota-kota besar adalah macet. Hal ini tentu bisa membuyarkan waktu perjalanan yang telah ditentukan. Maka dari itu, penting mengetahui prediksi perjalanan serta berangkat lebih awal agar tidak telat sampai di lokasi layanan.</p>
                                        <p><strong>5. Selalu hargai orang lain</strong><br />Dengan menjadi vendor Halo Jasa, maka Anda telah berkomitmen untuk menghargai waktu dan komitmen pelanggan Halo Jasa. Ketahuilah, bahwa pelanggan mempunyai waktu dan kesibukan tersendiri, sehingga jika Anda terlambat, berarti Anda dianggap melanggar komitmen tersebut.<br />Sekian artikel yang dapat kami berikan. Semoga bermanfaat<br />Salam sukses</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Mencegah Pelecehan Seksual Saat Mengerjaan Layanan',
                        content: [
                            {
                                text: `
                                        <p>Seringkali, perilaku yang mengarah kepada pelecehan sexual kerap dialami oleh para vendor saat mengerjakan order layanan. Bentuk pelecehan tersebut bisa berupa&nbsp;<strong>verbal maupun non verbal.</strong></p>
                                        <p>Contoh pelecehan seksual dapat dilakukan melalui:</p>
                                        <p><strong>Lisan</strong><br />Pengucapan lisan yang tidak pantas diucapkan, kata-kata yang tidak bisa diterima, lelucon, komentar mengenai penampilan dan ucapan lisan yang tidak sopan dan disengaja</p>
                                        <p><strong>Pernyataan</strong><br />Keterangan tulisan yang bersifat seksual/tidak pantas, menggunakan/menunjukkan gambar-gambar yang bersifat pornografi</p>
                                        <p><strong>Isyarat</strong><br />Melalui siulan atau kedipan mata.</p>
                                        <p><strong>Kontak fisik</strong><br />Melakukan kontak fisik yang tidak perlu seperti menyentuh, menepuk, atau meraba bagian sensitif pada tubuh.</p>
                                        <p>Nah, agar Anda tidak terkena pelecehan seksual, kami berikan tips berikut ini.</p>
                                        <p><strong>1.Sebelum berangkat, konfirmasikan terlebih dahulu kepada pelanggan</strong><br />Tak ada salahnya saat menyetujui order yang masuk, Anda melakukan konfirmasi terlebih dahulu kepada pelanggan. Indikasi mencurigakan maupun ketidakberesan terhadap order tersebut dapat diminimalisir.</p>
                                        <p><strong>2.Kenakan pakaian yang tidak menarik perhatian</strong>.<br />Terkadang, pelecehan sexual bisa datang hanya karena pakaian yang mencolok mata. Maka dari itu, gunakan pakaian sewajarnya, tidak menarik perhatian, dan yang terpenting seragam vendor Halo Jasa harus terus dikenakan terutama saat mengerjakan order.</p>
                                        <p><strong>3.Tata rambut dengan sederhana</strong><br />Khusus bagi vendor wanita yang tidak mengenakan jilbab. Atur rambut sewajarnya. Tidak digerai secara berlebihan. Serta ikatlah rambut saat mengerjakan layanan.</p>
                                        <p><strong>4.Lengkapi diri dengan peralatan keamanan</strong><br />Pencegahan dari pakaian pun terkadang tidak cukup. Anda bisa berjaga-jaga menggunakan alat pengaman seperti semprotan merica yang diletakkan di dalam tas Anda.</p>
                                        <p><strong>5.Pastikan alat komunikasi dalam kondisi prima</strong><br />Tak jarang, Anda memerlukan bantuan dari luar tempat layanan order Anda. Maka pastikan pulsa Anda terisi, paket data tersedia, dan daya tambahan berupa powerbank bisa digunakan sewaktu-waktu kondisi mendesak datang.</p>
                                        <p><strong>6.Wajib perhatikan lokasi layanan dan lingkungan sekitarnya</strong><br />Apakah lokasi tempat layanan begitu mencurigakan ? apakah ada bantuan di sekitar lokasi yang bisa didapatkan ? Penting memperhatikan hal tersebut agar Anda dapat langsung mengambil langkah penting jika situasi darurat tiba.</p>
                                        <p><strong>7.Perhatikan orang-orang saat sampai di lokasi tujuan</strong><br />Cek satu-persatu siapa saja orang-orang yang berada di sekitar tempat Anda mengerjakan layanan. Apakah orang tersebut mencurigakan atau bisa saja dapat menjadi bantuan pertama Anda saat meminta pertolongan.</p>
                                        <p><strong>8.Beritahu lokasi layanan kepada kerabat maupun teman Anda</strong><br />Tak ada salahnya Anda memberitahukan posisi order Anda kepada kerabat maupun teman Anda. Antisipasi jika keadaan mendesak datang dan Anda tidak sempat meminta tolong, maka orang terdekat Anda bisa langsung melacak ke lokasi terakhir Anda.</p>
                                        <p>Meski telah mengikuti langkah-langkah di atas, tidaklah menjamin Anda akan bebas 100% dari pelecehan seksual. Untuk itu, tersedia&nbsp;<strong>tombol darurat di Apps Vendor Halo Jasa.</strong>&nbsp;Gunakan fitur tersebut jika Anda benar-benar dalam bahaya. Semoga bermanfaat.</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Hadapi Pelecehan Seksual Melalui Cara ini',
                        content: [
                            {
                                text: `
                                        <p>Tak dipungkiri, pelecehan seksual kerap terjadi saat vendor sedang mengerjakan pesanan pelanggan. Berikut tips menghadapinya.</p>
                                        <p><strong>1.Saat menghadapi pelecehan secara verbal (melalui perkataan)</strong></p>
                                        <ul>
                                        <li>Tetap tenang, jangan terbawa emosi.</li>
                                        <li>Bicara langsung, hadapi dengan teguran untuk membuat pelaku tidak berani. Jika tetap nekat, segera minta pertolongan orang lain.</li>
                                        <li>Gunakan tehnik acupressure (Pijat menggunakan jari) di titik tertentu (khusus vendor Halo Massage) yang mempunyai fungsi menghambat hasrat seksual.</li>
                                        </ul>
                                        <p><strong>2.Saat menghadapi pelecehan non verbal (melalui perbuatan)</strong></p>
                                        <ul>
                                        <li>Jika secara paksa pelaku melakukan pelecehan, jangan ragu untuk tinggalkan lokasi pekerjaan dan tinggalkan seluruh barang bawaan Anda. Barang tertinggal akan diurus oleh pihak Halo Jasa kemudian.</li>
                                        <li>Jika berada terlalu jauh dari pintu keluar, segera lari ke dalam kamar mandi atau ruangan tertutup yang dapat dikunci dari dalam. Lalu minta bantuan segera melalui telepon atau berteriak.</li>
                                        <li>Bila langkah di atas tidak berhasil. Lakukan pertahanan diri terakhir dengan menyemprotkan&nbsp;<strong>pepper spray (semprotan merica), stun gun (alat kejut)</strong>**, serta lakukan&nbsp;<strong>bela diri dasar*.</strong></li>
                                        <li>Dalam keadaan darurat seperti ini pun, Anda dapat segera menekan Panic Button yang tersedia di aplikasi vendor Halo Jasa. Manfaatkan fitur tersebut.</li>
                                        <li>Jangan ragu untuk segera melapor kepada polisi jika Anda telah mengalami pelecehan tersebut.</li>
                                        </ul>
                                        <p><strong>Pelaporan kejadian</strong></p>
                                        <p>Meski begitu, bila telah terjadi hal-hal yang tidak diinginkan seperti pelecehan seksual tersebut. Maka beberapa tindakan ini dapat Anda lakukan.<br /><strong>1.Buat catatan mengenai kejadian dialami</strong><br /><strong>2.Bicarakan dengan orang lain maupun terdekat</strong><br /><strong>3.Segera laporkan kepada pihak berwajib (kepolisian)</strong></p>
                                        <p>Saat melaporkan kepada pihak berwajib, perlu memperhatikan beberapa hal berikut, diantaranya.</p>
                                        <ul>
                                        <li>Koordinasikan selalu dengan&nbsp;<strong>SATGAS (Satuan Tugas)</strong>&nbsp;dalam proses penyampaian laporan ke kepolisian</li>
                                        <li>Segera melapor, jangan menunda. Dengan keterlambatan pelaporan dapat menghambat proses penindakan&nbsp;<strong>(Contoh : Jika bukti hilang maka tidak dapat dilakukan pemeriksaan visum)</strong></li>
                                        <li>Laporan yang dibuat menggunakan nama pribadi.</li>
                                        <li>Dalam penyampaian laporan ke kepolisian selalu harus&nbsp;<strong>didampingi SATGAS.</strong></li>
                                        </ul>
                                        <p>Semoga artikel ini bermanfaat</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Cara Melayani Pelanggan Secara Langsung',
                        content: [
                            {
                                text: `
                                        <p>Untuk menjaga layanan yang diberikan, Anda sebagai vendor Halo Jasa harus memahami sikap dan perilaku sebagai acuan dalam melayani pelanggan. Hal ini karena sikap dan perilaku pelanggan pun berbeda-beda. Maka dari itu, berikut sikap yang wajib Anda perhatikan saat melayani pelanggan secara langsung.</p>
                                        <p><strong>1.Beri kesempatan pelanggan berbicara</strong><br />Pelanggan ingin menyampaikan keinginannya, maka berikan kesempatan terlebih dahulu pada mereka. Simak dengan seksama serta pahami kebutuhan dan keinginan mereka dengan baik agar tidak terjadi kesalahpahaman.</p>
                                        <p><strong>2.Dengarkan dengan baik dan seksama</strong><br />Simak dan dengarkan poin penting apa yang disampaikan pelanggan. Anda diharapkan menghindari gerakan yang menyinggung perasaan pelanggan. Terutama, gerakan tubuh yang dianggap kurang sopan.</p>
                                        <p><strong>3.Tidak menyela pembicaraan</strong><br />Usahakan untuk menanggapi para pelanggan jika telah selesai berbicara. Jangan memotong atau menyela pembicaraan saat pelanggan menyampaikan pembicaraan.</p>
                                        <p><strong>4.Jika perlu, ajukan pertanyaan</strong><br />Terkadang, ada pembicaraan atau informasi yang kurang dimengerti saat pelanggan menyampaikan keinginannya. Untuk itu, jangan ragu mengajukan pertanyaan terhadap pembicaraan yang telah dilakukan pelanggan. Tanyakan dengan bahasa yang baik, sopan, singkat, dan jelas.</p>
                                        <p><strong>5.Jangan mendebat pelanggan</strong><br />Sebisa mungkin, hindari mendebat pelanggan terutama jika terdapat hal-hal yang kurang disetujui. Serta tidak memberi argument yang tidak diterima pelanggan. Usahakan memberi penjelasan yang sopan.</p>
                                        <p><strong>6.Bersikap sopan, ramah, dan tenang</strong><br />Dalam melayani pelanggan, sikap sopan, santun, dan ramah perlu selalu diterapkan</p>
                                        <p><strong>7.Jangan menangani hal yang bukan wewenang Anda</strong><br />Bagi Anda vendor Halo Jasa. Hindari menangani hal-hal yang bukan wewenang Anda. Serahkan tugas tersebut kepada yang berwenang.</p>
                                        <p><strong>8.Tunjukkan sikap perhatian dan siap membantu</strong><br />Berikan perhatian sepenuhnya dan tunjukkan bahwa Anda siap untuk membantu pelanggan, karena prinsip dari pelanggan banyak yang ingin dibantu.</p>
                                        <p>Semoga bermanfaat dan terus berikan pelayanan terbaik Anda kepada pelanggan.</p>                                 
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Mengapa Menggunakan Seragam Halo Jasa Penting Dilakukan',
                        content: [
                            {
                                text: `
                                        <p><strong>Seragam merupakan tanda resmi identitas perusahaan.</strong>&nbsp;Maka dari itu,&nbsp;<strong>Halo Jasa mewajibkan</strong>&nbsp;penggunaan seragam yang telah distandarisasi oleh perusahaan dalam mengerjakan order layanan. Selain sebagai tanda resmi perusahaan, mengenakan seragam juga memiliki tujuan dan manfaat lain sehingga Anda tidak serta menyepelekan fungsi seragam itu sendiri.</p>
                                        <p><strong>1.Sebagai identitas resmi vendor Halo Jasa</strong><br />Dengan mengenakan seragam, Anda dianggap individu yang jujur dan patuh terhadap hukum serta aturan berlaku.</p>
                                        <p><strong>2.Menunjukkan sikap profesionalitas sebagai vendor Halo Jasa</strong><br />Dengan seragam pula, Anda dianggap seorang profesional yang senantiasa berlaku baik. Seragam pun dapat menjadikan pelayanan diberikan lebih nyata dengan menggambarkan layanan sesuai yang diharapkan pelanggan.</p>
                                        <p><strong>3.Sebagai bentuk solidaritas sesama vendor Halo Jasa</strong><br />Memakai seragam, maka solidaritas yang terjalin sesama vendor Halo Jasa akan merasa memiliki dan merasa satu dengan vendor lainnya.</p>
                                        <p><strong>4.Dapat berinteraksi dengan baik kepada pelanggan</strong><br />Pelanggan akan merasa nyaman saat berinteraksi dengan Anda saat mengenakan seragam. Rasa percaya diri Anda dalam mengerjakan pelayanan pun meningkat. Secara tidak langsung pelanggan akan puas terhadap pelayanan yang diberikan.</p>
                                        <p><strong>5.Memberikan keamanan dan kenyamanan dalam bekerja</strong><br />Keamanan Anda akan lebih terjamin dengan seragam yang Anda kenakan. Jika hal buruk terjadi, orang sekitar akan mudah mengenali Anda sebagai vendor Halo Jasa melalui seragam yang Anda kenakan, sehingga pihak berwajib mudah mengambil tindakan.</p>
                                        <p>Dengan informasi tersebut, kami berharap tidak ada lagi Vendor Halo Jasa yang tidak memenuhi ketentuan penggunaan seragam saat mengerjakan order layanan. Dan tak lupa, senantiasa bekerja sama mematahui ketentuan aturan Halo Jasa yang berlaku.</p>
                                        <p>Semoga bermanfaat</p>                                    
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Hadapi Keluhan Pelanggan Dengan Cara ini',
                        content: [
                            {
                                text: `
                                        <p>Sudah resiko bagi mitra Halo Jasa bahwa&nbsp;<strong>komplain/keluhan atau ekspresi ketidakpuasan terhadap layanan harus selalu dihadapi.</strong>&nbsp;Keluhan muncul bisa dari hal sepele hingga hal yang benar-benar serius. Untuk itu, Anda perlu berhati-hati saat berhadapan dengan pelanggan. Hal ini karena Anda membawa nama baik perusahaan serta pribadi yang harus dijaga dengan baik. Berikut&nbsp;<strong>tips saat menanggapi keluhan pelanggan.</strong></p>
                                        <p><strong>1.Dengarkan keluhan hingga tuntas</strong><br />Secara seksama, dengarkan hingga tuntas keluhan yang disampaikan pelanggan. Termasuk memahami dan penyebab ketidakpuasan pelanggan tersebut terjadi. Saat pelanggan mengeluh, jangan pernah memotong pembicaraan pelanggan tersebut. Karena dapat menimbulkan masalah terbaru selain juga dianggap tidak sopan.</p>
                                        <p><strong>2.Memohon maaf</strong><br />Sampaikan permohonan maaf setelah pelanggan selesai menyampaikan keluh kesahnya. Sampaikan dengan nada sopan dan tulus. Tak lupa ucapan terima kasih atas saran dan kritiknya. Tidak perlu Anda perpanjang apakah pelanggan benar atau salah.</p>
                                        <p><strong>3.Pastikan keluhan yang disampaikan dicatat dengan seksama</strong><br />Terutama mengenai apa yang dikeluhkan pelanggan, serta keinginan/ekspektasi pelanggan sehingga tidak merasa kecewa.<br />Jangan terlalu fokus untuk mengatasi dengan cepat, tapi bagaimana mengatasi dengan baik. Jangan terbawa emosi/ suasana negatif dan tetaplah tenang karena bagaimana pun, pelanggan punya hak mendapatkan pelayanan terbaik.</p>
                                        <p>Semoga artikel ini bermanfaat.</p>                                    
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Memberikan Pelayanan Terbaik Ala Halo Jasa',
                        content: [
                            {
                                text: `
                                        <p>Senantiasa bersikap profesional merupakan hal terpenting.&nbsp;<strong>Profesional di sini berarti menjadi vendor yang terapil, handal, dan bertanggung jawab dalam menjalankan pesanan pelanggan.</strong>&nbsp;Menjadi profesional bisa lebih menjamin akan lebih mudah mendapatkan order.&nbsp;<strong>Berikut kami berikan cara agar senantiasa profesional serta dapat menjadi vendor favorit pelanggan.</strong></p>
                                        <p><strong>Disiplin dalam bekerja</strong></p>
                                        <ul>
                                        <li>Datang tepat waktu sesuai kesepakatan antara Anda dan pelanggan di aplikasi.</li>
                                        <li>Berikan pelanggan layanan yang sesuai dengan aplikasi.</li>
                                        <li>Gunakan seragam dan atribut lengkap Halo Jasa.</li>
                                        <li>Tetap patuhi prosedur teknis pelayanan Halo Jasa.</li>
                                        <li>Ambil order hanya melalui aplikasi Halo Jasa.</li>
                                        <li>Saat melaksanakan pekerjaan, selain seragam wajib mengenakan Alat perlindungan diri (APD).</li>
                                        </ul>
                                        <p><strong>Komunikasi</strong></p>
                                        <ul>
                                        <li>Terapkan selalu 3S (Senyum, Sapa, dan Salam).</li>
                                        <li>Selalu gunakan tata bahasa yang sopan, termasuk dalam berkomunikasi dengan</li>
                                        <li>pelanggan secara langsung maupun melalui chat app (Whatsapp, Line, Telegram, SMS, dsb).</li>
                                        <li>Pahami situasi pelanggan untuk diajak bicara.</li>
                                        <li>Tidak bertanya bersifat pribadi apapun kepada pelanggan.</li>
                                        <li>Konfirmasikan selalu kepada pelanggan saat menerima order.</li>
                                        </ul>
                                        <p><strong>Tata karma</strong></p>
                                        <p><strong>Sebelum kedatangan</strong></p>
                                        <ul>
                                        <li>Konfirmasikan terlebih dahulu sebelum berangkat ke lokasi tujuan layanan.</li>
                                        <li>Sapa selalu pelanggan dengan mengucapkan salam.</li>
                                        <li>Pastikan layanan yang dipesan oleh pelanggan benar adanya.</li>
                                        <li>Perkenalkan diri Anda secara singkat.</li>
                                        </ul>
                                        <p><strong>Saat kedatangan</strong></p>
                                        <ul>
                                        <li>Lakukan konfirmasi kepada pelanggan bahwa Anda telah tiba di tempat layanan.</li>
                                        <li>Hindari atau kurangi obrolan yang tidak perlu kepada pelanggan.</li>
                                        <li>Bersikaplah ramah dan sopan selalu dalam melayani pelanggan.</li>
                                        <li>Jika memerlukan checklist form, maka isilah.</li>
                                        <li>Salam dan sapa pelanggan saat sampai.</li>
                                        </ul>
                                        <p><strong>Selesai kedatangan</strong></p>
                                        <ul>
                                        <li>Rapikan barang-barang Anda ke tempat semula.</li>
                                        <li>Selalu pastikan tidak ada barang Anda yang tertinggal maupun milik pelanggan yang terbawa oleh Anda.</li>
                                        <li>Ucapkan salam sebelum meninggalkan tempat.</li>
                                        <li>Jika menggunakan opsi pembayaran tunai, jangan lupa untuk menagih jika telah selesai.</li>
                                        <li>Ucapkan terima kasih sebelum benar-benar meninggalkan area pelayanan.</li>
                                        </ul>
                                        <p><strong>Mengerjakan pelayanan</strong></p>
                                        <ul>
                                        <li>Peralatan yang digunakan sesuai dengan ketentuan dari Halo Jasa.</li>
                                        <li>Selalu ikuti prosedur layanan Halo Jasa .</li>
                                        <li>Standar jam pelayanan di mulai saat Anda mulai mengerjakan pekerjaan/pesanan Bukan pada saat menuju lokasi maupun menyiapkan peralatan.</li>
                                        <li>Rapikan kembali barang-barang pelanggan yang Anda gunakan pada saat melakukan pekerjaan.</li>
                                        <li>Jika menggunakan opsi pembayaran tunai, tagih sesuai harga di aplikasi.</li>
                                        <li>Dilarang meminta uang lebih atau tip kepada pelanggan.</li>
                                        <li>Jangan lupa, arahkan pelanggan untuk memberikan rating serta komentar atas pelayanan yang Anda berikan.</li>
                                        </ul>
                                        <p>Tentu saja, dibutuhkan kerja keras dan kerja cerdas serta kemauan sebaik mungkin untuk menjadi profesional.&nbsp;<strong>Luangkan waktu&nbsp;</strong>untuk&nbsp;<strong>membaca dan mempelajari SOP baik teknis maupun non-teknis dari Halo Jasa</strong>.&nbsp;<strong>Sehingga</strong>&nbsp;Anda&nbsp;<strong>dapat menjadi</strong>&nbsp;<strong>vendor yang profesional</strong>&nbsp;dan menjadi diharapkan&nbsp;<strong>menjadi vendor favorit pelanggan</strong>.</p>
                                        <p>Semoga artikel ini bermanfaat.</p>                                    
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Produk dan Standar Peralatan Vendor Halo Jasa',
                        content: [
                            {
                                text: `
                                        <p>Kualitas layanan kepada vendor menjadi prioritas Halo Jasa. Untuk itu, perlunya standarisasi alat dan peralatan produk dari setiap vendor. Berikut daftar standard peralatan yang diharapkan dari setiap vendor.</p>
                                        <p>Sebagai catatan, setiap vendor dihimbau memiliki semua peralatan dengan kuantitas yang disesuaikan dengan layanan dipilih. Tak lupa, merk yang dipakai pun dapat dibeli bebas dan khusus untuk peralatan yang tidak disebutkan namanya, maka dibebaskan memilih merk apapun.</p>
                                        <p><strong>Daftar peralatan Halo Clean</strong></p>
                                        <table width="333">
                                        <tbody>
                                        <tr>
                                        <td width="39"><strong>Qty</strong></td>
                                        <td width="157"><strong>Halo Clean</strong></td>
                                        <td width="64"><strong>Merk</strong></td>
                                        <td width="73"><strong>Rujukan</strong></td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="157">Tas Besar</td>
                                        <td width="64">&nbsp;</td>
                                        <td width="73">Halo Jasa</td>
                                        </tr>
                                        <tr>
                                        <td width="39">2</td>
                                        <td width="157">Kaos Halo Jasa</td>
                                        <td width="64">&nbsp;</td>
                                        <td width="73">Halo Jasa</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="157">Sikat Lantai</td>
                                        <td width="64">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="157">Lap Microfiber Oranye</td>
                                        <td width="64">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="157">Lap Microfiber Hijau</td>
                                        <td width="64">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="157">Spray Mop</td>
                                        <td width="64">Bolde</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="157">Lap Microfiber Pink</td>
                                        <td width="64">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="157">Pembersih Lantai 600ml</td>
                                        <td width="64">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="157">Botol Kispray</td>
                                        <td width="64">Kispray</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="157">Wiper Pembersih Kaca</td>
                                        <td width="64">Lion</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="157">Botol Cling</td>
                                        <td width="64">Cling</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="157">Karbol</td>
                                        <td width="64">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="157">Portex</td>
                                        <td width="64">Portex</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="157">Sponge Besi</td>
                                        <td width="64">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="157">Sarung Tangan</td>
                                        <td width="64">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="157">Masker Mulut</td>
                                        <td width="64">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="157">Lap Microfiber Biru</td>
                                        <td width="64">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        </tbody>
                                        </table>
                                        <p>&nbsp;</p>
                                        <p><strong>Daftar Peralatan Halo Massage</strong></p>
                                        <table width="311">
                                        <tbody>
                                        <tr>
                                        <td width="39"><strong>Qty</strong></td>
                                        <td width="135"><strong>Halo Massage</strong></td>
                                        <td width="64"><strong>Merk</strong></td>
                                        <td width="73"><strong>Rujukan</strong></td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="135">Tas Kecil</td>
                                        <td width="64">&nbsp;</td>
                                        <td width="73">Halo Jasa</td>
                                        </tr>
                                        <tr>
                                        <td width="39">2</td>
                                        <td width="135">Kaos Halo Jasa</td>
                                        <td width="64">&nbsp;</td>
                                        <td width="73">Halo Jasa</td>
                                        </tr>
                                        <tr>
                                        <td width="39">4</td>
                                        <td width="135">Handuk Leher</td>
                                        <td width="64">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="135">Plastik Handuk</td>
                                        <td width="64">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="135">Cream Massage</td>
                                        <td width="64">Viva</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="135">Alat Kerokan</td>
                                        <td width="64">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">2</td>
                                        <td width="135">Kain Bali</td>
                                        <td width="64">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="135">Minyak Massage</td>
                                        <td width="64">Herborist</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        </tbody>
                                        </table>
                                        <p>&nbsp;</p>
                                        <p><strong>Daftar peralatan Halo Auto</strong></p>
                                        <table width="363">
                                        <tbody>
                                        <tr>
                                        <td width="39"><strong>Qty</strong></td>
                                        <td width="165"><strong>Halo Auto</strong></td>
                                        <td width="85"><strong>Merk</strong></td>
                                        <td width="73"><strong>Rujukan</strong></td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="165">Tas Kecil</td>
                                        <td width="85">&nbsp;</td>
                                        <td width="73">Halo Jasa</td>
                                        </tr>
                                        <tr>
                                        <td width="39">2</td>
                                        <td width="165">Kaos Halo Jasa</td>
                                        <td width="85">&nbsp;</td>
                                        <td width="73">Halo Jasa</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="165">Sikat Lantai</td>
                                        <td width="85">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">7</td>
                                        <td width="165">Lap Microfiber Oranye</td>
                                        <td width="85">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="165">Lap Microfiber Hijau</td>
                                        <td width="85">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="165">Kanebo</td>
                                        <td width="85">Kenmaster</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="165">Shampo Mobil</td>
                                        <td width="85">Valo</td>
                                        <td width="73">Valo</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="165">Lap Microfiber Ungu</td>
                                        <td width="85">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="165">Busa</td>
                                        <td width="85">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="165">Semir Ban</td>
                                        <td width="85">Carrera / KIT</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="165">Botol Kosong</td>
                                        <td width="85">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="165">Kuas</td>
                                        <td width="85">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="165">Waterless Wax and Wash</td>
                                        <td width="85">Valo</td>
                                        <td width="73">Valoi</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="165">Botol spray 500ml</td>
                                        <td width="85">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="165">Botol Spray 2L</td>
                                        <td width="85">Kenmaster</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="165">Ember Plastik LION</td>
                                        <td width="85">Lion</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="165">Dustpan Mini</td>
                                        <td width="85">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="165">Sabun Colek</td>
                                        <td width="85">&nbsp;</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        <tr>
                                        <td width="39">1</td>
                                        <td width="165">KIT Wax</td>
                                        <td width="85">KIT</td>
                                        <td width="73">&nbsp;</td>
                                        </tr>
                                        </tbody>
                                        </table>
                                        <p>&nbsp;</p>
                                        <p><strong>Daftar Peralatan Halo Fix</strong></p>
                                        <table width="248">
                                        <tbody>
                                        <tr>
                                        <td width="39"><strong>Qty</strong></td>
                                        <td width="72"><strong>Halo Fix</strong></td>
                                        <td width="64"><strong>Merk</strong></td>
                                        <td width="73"><strong>Rujukan</strong></td>
                                        </tr>
                                        <tr>
                                        <td width="39">2</td>
                                        <td width="72">Rompi</td>
                                        <td width="64">&nbsp;</td>
                                        <td width="73">Halo Jasa</td>
                                        </tr>
                                        </tbody>
                                        </table>
                                        <p>&nbsp;</p>                                    
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Ubah Kebiasaan Telatmu Melalui Cara ini',
                        content: [
                            {
                                text: `
                                        <p>Jam karet, itulah tipikal dari kebanyakan masyarakat Indonesia. Kebiasaan yang sudah banyak mendarah daging ini sebenarnya merupakan cerminan pribadi yang tidak baik. Tentu saja, sebagai vendor, Anda harus menghindari hal tersebut. Maka dari itu, ubah kebiasaan buruk tersebut melalui cara berikut.</p>
                                        <p><strong>1.Selalu gunakan jam tangan</strong><br />Mengenakan jam tangan setiap saat bermanfaat agar Anda dapat selalu mengingat waktu yang digunakan sebagai jam temu layanan.</p>
                                        <p><strong>2.Set alarm dengan jam yang telah ditentukan</strong><br />Namun mengenakan jam saja tidak cukup, terkadang kita harus menyetel alarm di jam jam tertentu agar janji yang dibuat tidak terlewat atau terlambat.</p>
                                        <p><strong>3.Hindari waktu yang mepet</strong><br />Waktu mepet atau deadliner juga sering menjadi kebiasaan orang Indonesia. Menjadi deadliner, maka Anda dikatakan sebagai pribadi yang tidak disiplin. Selain itu, deadliner dapat membuat pekerjaan dibuat terburu-buru, sehingga mengakibatkan hasil yang tidak maksimal.</p>
                                        <p><strong>4.Atur waktu jam janjian lebih cepat dari waktu yang ditentukan</strong><br />Agar tidak terlambat, atur jam janjian selama 15-30 menit sebelum janji temu dimulai. Hal ini dilakukan agar kita tidak cenderung menganggap remeh waktu.</p>
                                        <p><strong>5.Buat prioritas pekerjaan</strong><br />Waktu yang diberikan terbatas, namun pekerjaan begitu banyak. Untuk itu, atur prioritas mana pekerjaan yang harus dilakukan terlebih dahulu. Catat di tempat yang mudah terlihat agar Anda mudah dan termotivasi dalam melakukan pekerjaan.</p>
                                        <p><strong>6.Prediksi waktu tempuh</strong><br />Agar tidak terlambat, sebaiknya Anda mempersiapkan waktu tempuh perjalanan terlebih dahulu beberapa menit atau jam sebelum janji dilaksanakan. Terutama di kota-kota besar yang mempunyai lalu lintas padat.</p>
                                        <p><strong>7.Pendekkan waktu persiapanmu</strong><br />Mempersiapkan diri sebelum melakukan sesuatu penting dilakukan. Namun jangan terlena dengan persiapan yang begitu rumit sehingga memakan waktu lama agar Anda tidak terlambat saat melakukan order pekerjaan.</p>
                                        <p><strong>8.Sebisa mungkin, hindari ponsel</strong><br />Terkadang, banyak sebagian orang yang memegang ponsel justru membuatnya terlena dengan waktu. Maka dari itu, hanya buka ponsel Anda jika diperlukan agar tidak banyak janji terlambat dilakukan.<br />Dengan tepat waktu, maka banyak manfaat yang bisa Anda dapatkan sebagai vendor. Sehingga pekerjaan Anda dapat maksimal dan tidak terburu-buru.</p>
                                        <p>Semoga artikel ini bermanfaat.</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Standar Layanan Halo Jasa',
                        content: [
                            {
                                text: `
                                        <p>Sebelum Anda memberikan layanan kepada pelanggan, Anda perlu memperhatikan beberapa hal. Salah satunya standar layanan. Dimulai dari kerapian, kondisi produk dan peralatan, hingga cara berkomunikasi dengan pelanggan. Berikut standarisasi pelayanan dari Halo Jasa agar pelayanan Anda dapat maksimal.</p>
                                        <p>Standar layanan Halo Jasa</p>
                                        <p><strong>Penampilan vendor</strong></p>
                                        <ul>
                                        <li>Menggunakan seragam resmi Halo Jasa</li>
                                        <li>Saat order telah diambil</li>
                                        <li>Lakukan konfirmasi ulang dengan menghubungi konsumen</li>
                                        </ul>
                                        <p><strong>Pelayanan</strong></p>
                                        <ul>
                                        <li>Melaksanakan pekerjaan sesuai SOP berlaku</li>
                                        <li>Memberikan layanan sesuai pesanan</li>
                                        <li>Mengerjakan sesuai dengan waktu pemesanan yang tertera di aplikasi</li>
                                        <li>Tidak meninggalkan konsumen dalam keadaan kotor atau berantakan</li>
                                        <li>Lakukan double check antara vendor dan konsumen atas barang bawaan si vendor. Hal ini untuk mengantisipasi resiko kehilangan barang pelanggan.</li>
                                        </ul>
                                        <p><strong>Tiba di lokasi</strong></p>
                                        <ul>
                                        <li>Konfirmasikan kepada konsumen bahwa Anda sudah tiba</li>
                                        </ul>
                                        <p><strong>Peralatan atau produk</strong></p>
                                        <ul>
                                        <li>Berstandar SNI dan telah terdaftar di depkes</li>
                                        </ul>
                                        <p><strong>Setelah pelayanan</strong></p>
                                        <ul>
                                        <li>Tidak menghubungi konsumen kecuali terdapat keperluan penting seperti barang tertinggal</li>
                                        </ul>
                                        <p>Dengan mengikuti standard layanan tersebut, diharapkan pelanggan merasa puas dan mau memberikan penilaian terbaiknya. Dengan mengikuti standard vendor Halo Jasa ini juga mencegah Anda terkena pelanggaran yang menyebabkan pemutusan hubungan vendor.</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Pastikan Hal Berikut Sebelum Setuju Mengambil Order',
                        content: [
                            {
                                text: `
                                        <p>Tahukah Anda, banyak keluhan datang dari pelanggan jika vendor tidak datang ke lokasi pengerjaan layanan. Jika kasus ini terjadi, kedua belah pihak tentu akan mengalami kerugian.</p>
                                        <p>Bagi vendor, jika keluhan ini datang, vendor akan diberikan kartu kuning. Namun dari sisi pelanggan, pelanggan tentu mengalami kerugian karena kehilangan waktu akibat tidak mendapatkan layanan Halo Jasa seperti yang diharapkan.</p>
                                        <p>Sebelum Anda memutuskan untuk mengambil order, Sebaiknya vendor berfokus terhadap hal-hal berikut.</p>
                                        <p>1.Pastikan kondisi badan fit, sehat, dan segar bugar sehingga Anda mampu menjalankan order sesuai pesanan pelanggan.</p>
                                        <p>2.Pastikan juga sinyal ponsel Anda dalam kondisi baik. Jika akhirnya vendor tidak dapat menjalankan order, vendor dapat segera menginfokan kepada pelanggan sehingga pelanggan dapat segera memesan ulang layanan yang diinginkan.</p>
                                        <p>Agar Anda dan pelanggan nyaman, kami telah menyediakan tips yang dapat Anda ikuti berikut.</p>
                                        <p>1.Bagi Anda yang baru bergabung, pastikan Anda telah memahami dan menguasai fitur aplikasi Halo Jasa Vendor dengan baik. Serta memahami proses pengambilan order pada aplikasi Halo Jasa Vendor. Jika terdapat informasi yang kurang jelas, jangan ragu bertanya kepada Costumer Care Vendor atau kepada vendor Halo Jasa lainnya.</p>
                                        <p>2.Pastikan ponsel yang terpasang aplikasi Halo Jasa Vendor khusus digunakan hanya untuk bekerja. Sebaiknya, ponsel tersebut tidak diberikan atau digunakan oleh orang lain, terutama anak-anak. Hal ini untuk menghindari kejadian pengambilan order secara tidak sengaja karena hal tersebut sering terjadi dan tidak disadari para vendor Halo Jasa.</p>
                                        <p>3.Saat memenangkan bid order, lakukan konfirmasi ulang segera kepada pelanggan, baik order hari itu atau di hari lain.</p>
                                        <p>4.Cek booking aktif Anda secara berkala.</p>
                                        <p>5.Catat order masuk yang dijadwalkan di hari lain dalam buku catatan khusus order.</p>
                                        <p>6.Jika tidak bisa menyelesaikan atau memenuhi order pelanggan, segara sampaikan secara sopan dan baik-baik agar pelanggan tidak menunggu kabar atau konfirmasi Anda. Sehingga mereka bisa melakukan order ulang. Hubungi Costumer Care Vendor dan sampaikan mengapa Anda tidak bisa menyelesaikan order tersebut.</p>
                                        <p>Pastikan pelayanan terbaik Anda berikan kepada pelanggan!<br />Semoga bermanfaat</p>
                                        `
                            }
                        ]
                    },
                ]
            },
            {
                title: 'Tips Untuk Vendor',
                subdata: [
                    {
                        title: 'Hadapi Pelanggan Warga Negara Asing Dengan Cara ini',
                        content: [
                            {
                                text: `
                                        <p>Anda mendapatkan order di mana pelanggan Anda ternyata orang asing (Non-WNI (Warga Negara Indonesia)) yang ternyata tidak dapat berbahasa Indonesia. Simak langkah berikut agar Anda lancar mengerjakan orderan tersebut.</p>
                                        <p>1.&nbsp;<strong>Gunakan aplikasi Google Translate</strong>&nbsp;yang ada di ponsel Anda. Jika tidak ada, silahkan unduh aplikasi tersebut di&nbsp;<strong>Play Store (Android)</strong>&nbsp;atau&nbsp;<strong>App Store (iOS)</strong>&nbsp;Anda.<br />2. Pilih&nbsp;<strong>Detect Languages</strong>&nbsp;Anda yaitu&nbsp;<strong>Indonesia</strong>. Kemudian pada tab&nbsp;<strong>Translation</strong>, pilih&nbsp;<strong>bahasa English.</strong><br />3. Jika pelanggan ingin berkomunikasi, minta dia untuk mengetikkan perkataannya melalui aplikasi&nbsp;<strong>Google Translate</strong>&nbsp;di ponsel Anda.<br />4. Cara lain, Anda dapat mengatakan apa yang Anda ingin katakan secara langsung melalui fitur voice command di a<strong>plikasi Google Translate</strong>&nbsp;tersebut.</p>
                                        <p>Demikian beberapa langkah dan tips yang perlu dilakukan. Diharapkan, pelanggan dapat memahami seluruh komunikasi dan pelayanan yang diberikan oleh Vendor Halo Jasa.<br />Semoga artikel ini bermanfaat bagi Anda.</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Atur Keuanganmu dengan Langkah Berikut',
                        content: [
                            {
                                text: `
                                        <p>Apa yang Anda lakukan saat menerima penghasilan ? langsung&nbsp;<strong>dibelanjakan</strong>&nbsp;atau&nbsp;<strong>ditabung</strong>&nbsp;semua ?. agar tidak bingung, penghasilan harus diatur dan dikelola sedemikan mungkin. Kali ini, Halo Jasa memberikan langkah mengelola keuangan yang tepat.</p>
                                        <p><strong>1.Buat daftar pos pengeluaran</strong><br />Mulai dengan membagi beragam jenis pos pengeluaran yang kira-kira sering Anda keluarkan. Yaitu meliputi beberapa pos yang penting seperti.</p>
                                        <ul>
                                        <li><strong>Kebutuhan rumah tangga : listrik, air, BBM, PBB dsb</strong></li>
                                        <li><strong>Kebutuhan konsumsi rumah tangga</strong></li>
                                        <li><strong>Tabungan dan dana darurat</strong></li>
                                        <li><strong>Asuransi</strong></li>
                                        <li><strong>Transportasi : meliputi biaya BBM atau transportasi umum</strong></li>
                                        <li><strong>Hiburan</strong></li>
                                        </ul>
                                        <p>Setelah pos tersebut diketahui, catat dan alokasikan dana yang tersedia untuk menghitung setiap jenis pengeluaran serta perlu tidaknya mengalokasikan uang dengan jumlah tertentu di pos tersebut.</p>
                                        <p><strong>2.Buat kalender tagihan</strong><br />Agar lebih teratur, ada baiknya membuat kalender tagihan setiap bulan dengan menandai tanggal berapa saja pembayaran beragam tagihan tersebut. Hal ini membantu Anda lebih teratur dan terhindar dari biaya-biaya denda karena terlambat membayar tagihan.</p>
                                        <p><strong>3.Bayar hutang setiap bulan</strong><br />Jika Anda memiliki pos untuk pembayaran cicilan hutang setiap bulan, ini perlu diprioritaskan. Pastikan hutang ini merupakan prioritas pertama setiap bulan agar Anda tidak terdampak buruknya informasi debitur SLIK OJK Anda.</p>
                                        <p><strong>4.Buatlah goal dalam menabung</strong><br />Selain mengatur pengeluaran, kunci kesuksesan mengatur keuangan ialah kemampuan menabung. Resep utamanya dengan menyisihkan, bukan menyisakan setiap bulan. Jadi dengan langsung menyisihkan di awal untuk menabung saat menerima penghasilan. Idealnya 10-30% dari total penghasilan masuk untuk ditabung.</p>
                                        <p><strong>5.Sisihkan untuk dana darurat</strong><br />Salah satu poin penting mengatur keuangan ialah menyisihkan dana darurat. Dana darurat dipersiapkan jika sewaktu-waktu Anda menghadapi keadaan darurat seperti kehilangan pekerjaan atau sakit. Persiapkan minimal 3 bulan penghasilan untuk yang lajang atau 12 bulan penghasilan bagi yang telah berkeluarga.<br />6.Pantau pengeluaran<br />Tak ada gunanya jika pos pengeluaran telah diatur tetapi Anda tidak rajin dan disiplin dalam memantau pengeluaran. Setelah Anda pantau, Anda dapat me-review kembali di pos mana yang bisa Anda kurangi, hilangkan, atau tambahkan.</p>
                                        <p><strong>7.Ubah kebiasaan untuk terus menggunakan uang tunai atau debit</strong><br />Dalam pembayaran sehari-hari, hindari penggunaan kartu kredit. Terkecuali jika mengincar promo. Namun jangan sampai terlena dengan promo-promo tersebut, terlebih jika pos pembayaran kartu kredit telah melewati batas atau tidak terkontrol.<br />Agar lebih efisien, Anda dapat menggunakan kartu kredit yang telah dibatasi nominalnya setiap bulan dan membuat rekening terpisah untuk pembayaran maupun menabung.<br />Demikian langkah-langkah yang diterapkan. Semoga Anda dapat mengelola keuangan dengan baik.</p>
                                        <p>Salam Sukses</p>
                                        <p>Terima kasih</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Raih Bintang 5 Dengan Langkah Berikut',
                        content: [
                            {
                                text: `
                                        <p>Semua sudah tahu kan gunanya rating di dalam aplikasi Vendor Halo Jasa ?<br />Singkatnya, rating merupakan penilaian yang diberi pelanggan kepada Anda sebagai Vendor Halo Jasa atas layanan yang diberikan. Jadi bila rating Anda berada di bawah nilai rata-rata (3.0 kebawah), maka Anda bisa mendapatkan sanksi hingga pemutusan Vendor. Banyak hal yang mempengaruhi pelanggan yang akan memberi penilaian terhadap kinerjamu. Dari sikap, hingga kualitas layanan diberikan.<br />Nah agar rating Anda bagus dan membuat insentif Anda keluar, kami berikan tips berikut.</p>
                                        <ol>
                                        <li>Datang tepat waktu atau jangan sampai membuat pelanggan menunggu di lokasi pemesanan.</li>
                                        <li>Bersikap sopan santun selalu kepada pelanggan.</li>
                                        <li>Dilarang menggunakan barang, perabotan, maupun benda hingga saran lain di rumah pelanggan kecuali diperkenankan oleh pelanggan.</li>
                                        <li>Hindari memindahkan maupun merusak barang milik pelanggan.</li>
                                        <li>Hindari menggunakan atau bermain ponsel, mendengarkan music, hingga bermain game di ponsel Anda secara sengaja saat pengerjaan pelayanan berlangsung.</li>
                                        <li>Hanya menghubungi pelanggan sesuai keperluan terkait order layanan Halo Jasa.</li>
                                        <li>Sebelum meninggalkan lokasi pemesanan order layanan, pastikan tidak meninggalkan sampah dan dalam keadaan bersih.</li>
                                        <li>Dilarang meminta atau mengambil makanan dan minuman terkecuali pelanggan memang menyajikannya untuk Anda.</li>
                                        <li>Kenakan selalu seragam wajib Halo Jasa saat mengerjakan order layanan.</li>
                                        </ol>
                                        <p>Nah, diharapkan dengan tips di atas, Anda sebagai Vendor dapat meningkatkan kualitas kinerja. Sehingga kepuasan pelanggan dapat tercapai.</p>
                                        <p>Semoga artikel singkat ini bermanfaat bagi Anda.</p>   
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Jaga Penampilan Anda Dengan Tips Berikut',
                        content: [
                            {
                                text: `
                                        <p>Berpenampilan menarik dan profesional perlu dilakukan agar kesan yang baik muncul bagi pelanggan Halo Jasa. Berikut tips kami agar penampilan Anda selalu terjaga.</p>
                                        <p><strong>Menjaga kebersihan diri</strong></p>
                                        <ul>
                                        <li>Gigi tergosok bersih, tidak terdapat sisa makanan</li>
                                        <li>Mulut tidak berbau</li>
                                        <li>Rambut disisir rapi</li>
                                        <li>Khusus pria, jika menumbuhkan jenggot atau kumis maka dipotong dengan rapi serta tidak panjang dan menggangu</li>
                                        <li>Khusus wanita, gunakan anting berukuran kecil dengan rambut rapi tanpa menutupi wajah.</li>
                                        <li>Khusus wanita berjilbab, pakailah dengan rapid an dimasukkan ke dalam seragam</li>
                                        <li>Tidak menggunakan riasan (make-up), dan lipstick yang mencolok</li>
                                        <li>Tidak berbau badan dan keringat</li>
                                        <li>Wajah segar berseri</li>
                                        <li>Kuku dalam keadaan bersih dan terawat</li>
                                        </ul>
                                        <p><strong>Menjaga pakaian</strong></p>
                                        <ul>
                                        <li>Berpakaian menarik (Baju tidak terlipat, kebesaran atau kekecilan)</li>
                                        <li>Pakaian yang dikenakan bersih dan wangi</li>
                                        <li>Tidak mengenakan pakaian dan aksesoris yang mencolok mata termasuk perhiasan berlebihan</li>
                                        <li>Dilarang mengenakan celana pendek, celana sobek atau pun kekecilan</li>
                                        </ul>
                                        <p>&nbsp;</p>
                                        <ul>
                                        <li>Senantiasa senyum, terutama saat berinteraksi dengan pelanggan</li>
                                        <li>Menggunakan seragam yang telah ditentukan oleh Halo Jasa</li>
                                        </ul>
                                        <p>Demikian beberapa tips yang diberikan. Semoga dengan penampilan yang terjaga dan menarik dapat memberikan kepuasan dan senyuman pelanggan Halo Jasa.</p>
                                        <p>Semoga bermanfaat</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Langkah Berpakaian Profesional',
                        content: [
                            {
                                text: `
                                        <p>Kepribadian dapat dinilai dari banyak cara, salah satunya dalam berpenampilan. Dengan berpenampilan bersih dan menarik, maka dapat dikatakan dia memiliki personal yang menarik. Maka dari itu,&nbsp;<strong>vendor Halo Jasa dihimbau berpenampilan menarik agar kepercayaan diri meningkat</strong>, sehingga&nbsp;<strong>pelayanan diberikan pelanggan dapat maksimal</strong>. Yuk ikuti tips berikut.</p>
                                        <p><strong>1.Selalu jaga kebersihan pakaian, alas kaki, peralatan, serta seragam yang dikenakan</strong><br />Hal pertama yang diperhatikan saat berpenampilan profesional adalah bersihnya pakaian, alas kaki, dan seragam dikenakan. Pakaian harus rapi, tidak bernoda, dan luntur warnanya. Oleh Karen itu, luangkan waktu Anda untuk merawatnya.<br />.<br /><strong>2.Perhatikan keraphian rambut Anda</strong><br />Rambut rapi dan ditata memberikan nilai tambah kepada vendor. Bawalah sisir selalu dan sisirlah rambut setelah mandi dan setiap akan melakukan pekerjaan.</p>
                                        <p><strong>3.Jagalah kebersihan tangan dan kuku</strong><br />Selain pakaian, kebersihan badan terutama tangan dan kuku tak luput dari perhatian pelanggan saat bekerja dan berinteraksi. Jagalah keduanya dengan memotong kuku jika sudah panjang, cuci tangan saat akan dan setelah pekerjaan dilakukan.</p>
                                        <p><strong>4.Gunakan dan sedia wewangian selalu</strong><br />Salah satu tanda seseorang berpenampilan menarik ialah beraroma badan segar. Agar selalu segar, bawalah parfum terutama setelah beraktivitas di luar ruangan.</p>
                                        <p><strong>5.Hindari nafas yang berbau tidak sedap</strong><br />Agar nafas selalu segar, minumlah banyak air serta hindari makanan berbau menyengat. Jangan lupa konsumsi permen penyegar untuk menghilangkan bau mulut sesaat sebelum bertemu pelanggan.</p>
                                        <p><strong>6.Simpan dan bawa selalu sabun cuci muka agar wajah selalu segar</strong><br />Wajah yang segar memberikan kepercayaan diri saat berinteraksi dengan pelanggan. Oleh karena itu, cuci muka Anda setiap kali selesai melakukan aktivitas di luar ruangan dengan sabun muka.</p>
                                        <p><strong>7.Siapkan selalu tisu basah atau lap</strong><br />Pentingnya alat ini ialah untuk menyeka atau membersihkan noda ringan yang terdapat pada pakaian atau badan.</p>
                                        <p>Sekadar tambahan, vendor Halo Jasa disarankan membawa selalu peralatan&nbsp;<strong>toilet (Toiletries) yang portabel seperti sikat gigi, deodorant, wewangian, dsb</strong>&nbsp;berukuran mungil sehingga&nbsp;<strong>penampilan profesional lebih terjaga di manapun</strong>. Terutama saat di lokasi pengerjaan layanan.</p>
                                        <p>Semoga artikel ini bermanfaat</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Maksimalkan Waktu Bekerja Anda Dengan Langkah Berikut',
                        content: [
                            {
                                text: `
                                        <p>Sering Anda merasa waktu yang dibutuhkan untuk bekerja terasa kurang. Namun, hal itu tidak sepenuhnya benar. Mungkin Anda kurang maksimal memanfaatkan waktu 24 jam sehari tersebut. Berikut kami berikan tips mengatur waktu Anda sehingga maksimal dimanfaatkan.</p>
                                        <p><strong>1.Jadikan pekerjaan utama sebagai sebuah kebiasaan</strong><br />Tak bisa dipungkiri, bahwa rutinitas bisa dianggap membosankan. Namun, jika Anda mengubah pola pikir dengan menjadikan rutinitas sebagai sebuah Hobi, maka pekerjaan Anda akan sangat menyenangkan.</p>
                                        <p><strong>2.Pandai mengatur waktu</strong><br />Selalu cek waktu sebelum pekerjaan dan sesudah menyelesaikan pekerjaan agar pekerjaan dapat selesai sesuai tenggat waktu yang ditentukan.</p>
                                        <p><strong>3.Buat estimasi waktu dalam penyelesaian pekerjaan</strong><br />Dengan mampu mengestimasikan waktu penyelesaian pekerjaan, Anda akan lebih termotivasi serta mampu mengelola pekerjaan di dalam tenggat waktu tersebut.</p>
                                        <p><strong>4.Berkomitmen dalam melakukan sesuatu</strong><br />Memiliki komitmen berarti Anda sepenuh hati mencurahkan tenaga dan waktu terhadap order layanan yang datang. Dengan begitu, tak ada waktu mubazir akibat pekerjaan itu.</p>
                                        <p><strong>5.Jangan membuang waktu</strong><br />Sesegera mungkin setelah tiba di lokasi pekerjaan, langsung kerjakan secara maksimal dan profesional sesuai waktu yang diberikan. Waktu amatlah berharga bagi Anda termasuk pelanggan.</p>
                                        <p><strong>6.Selesaikan pekerjaan dari yang paling penting terlebih dahulu</strong><br />Kadang Anda akan dihadapkan dengan beragam pekerjaan yang harus dilakukan dalam satu waktu. Agar maksimal, buat prioritas pekerjaan yang harus terlebih dahulu dilakukan. Hindari menjadi multitasker.</p>
                                        <p><strong>7.Pastikan pekerjaan telah diselesaikan dengan baik</strong><br />Sebelum mengakhiri pekerjaan, pastikan pemeriksaan secara menyeluruh dari detil pekerjaan yang telah selesai dikerjakan. Agar pelanggan mendapatkan pelayanan yang sesuai dengan berkomunikasi langsung.</p>
                                        <p>Semoga artikel ini bermanfaat dalam membantu Anda memaksimalkan waktu bekerja. Jangan lupa, pelayanan terbaik kepada pelanggan harus selalu diberikan.</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Jaga Daya Tahan Tubuh Anda Dengan Cara Berikut',
                        content: [
                            {
                                text: `
                                        <p>Menjaga kesehatan tubuh merupakan hal terpenting untuk menunjang produktivitas. Maka dari itu, tubuh perlu dijaga selalu agar aktivitas Anda termasuk dalam mengerjakan order layanan dapat berjalan maksimal. Berikut kami sampaikan&nbsp;<strong>tips menjaga daya tahan tubuh Anda.</strong></p>
                                        <p><strong>1.Atur waktu istirahat</strong><br />Mendapatkan tubuh sehat perlu mengatur waktu istirahat secukup dan seoptimal mungkin. Tidak disarankan untuk kekurangan tidur. Istirahat yang cukup setelah beraktivitas dan bekerja agar kesehatan tubuh tetap terjaga.</p>
                                        <p><strong>2.Olahraga dan bergerak secara teratur</strong><br />Langkah ini tidak sering dilakukan oleh banyak orang, padahal dengan berolahraga teratur, maka kondisi badan bisa tetap bugar selain dapat mencegah beragam penyakit.</p>
                                        <p><strong>3.Konsumsi selalu makanan berserat</strong><br />Serat dapat ditemui di beragam buah dan sayuran hijau. Dengan makan makanan berserat, resiko kolestrol jahat di dalam tubuh penyebab penyakit kardiovaskular dapat dicegah.</p>
                                        <p><strong>4.Makan dengan porsi wajar dan sehat</strong><br />Segala sesuatu yang berlebihan tidaklah baik. Begitu pun dengan porsi makan berlebihan sangat tidak baik untuk berat badan. Makan dengan kandungan yang sehat dibutuhkan untuk menjaga keseimbangan dan daya tahan tubuh melalui keseimbangan gizi yaitu protein, karbohidrat, lemak, hingga vitamin.</p>
                                        <p><strong>5.Selalu berpikir positif</strong><br />Setelah raga, penting menjaga pikiran yang baik dengan pikiran positif dan optimis.</p>
                                        <p><strong>6.Jauhi rokok dan alkohol</strong><br />Rokok dan alkohol terbukti tidak memiliki manfaat terhadap daya tahan tubuh. Jauhi segera.</p>
                                        <p><strong>7.Kelola stres Anda</strong><br />Stress terbukti dapat menurunkan kekebalan tubuh sehingga dapat rentan terkena penyakit. Jadi kelolalah stres Anda semaksimal mungkin. Lakukan olahraga yang dapat mengurangi stres seperti meditasi atau yoga.</p>
                                        <p><strong>8.Bersosialisasi dengan sekitar</strong><br />Sebagai mahluk sosial, penting untuk terus bersosialisasi dengan lingkungan sekitar. Dengan terisolasi dari lingkungan dapat menyebabkan stres yang berakibat tubuh menjadi rentan.</p>
                                        <p>Demikian cara-cara menjaga daya tahan tubuh agar senantiasa fit. Dengan senantiasa fit, pekerjaan dan aktifitas dapat leluasa dilakukan. Semoga artikel ini bermanfaat.</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Jaga Produktivitasmu Dengan Cara Berikut',
                        content: [
                            {
                                text: `
                                        <p>Agar pekerjaan yang diberikan dapat maksimal dikerjakan, perlu stamina dan kondisi tubuh yang fit. Diharapkan, dengan kondisi tubuh fit, maka produktivitas vendor Halo Jasa tetap terjaga. Berikut&nbsp;<strong>tips bagaimana produktivitas dapat terus dijaga</strong>.</p>
                                        <p><strong>1.Konsumsi makanan sehat</strong><br />Untuk mendapat tubuh yang sehat, perlu mendapat asupan yang sehat sebagai sumber tenaga. Makanan sehat ini harus mempunyai keseimbangan nilai gizi di dalamnya berupa karbohidrat, protein, mineral, vitamin, dsb. Jangan lupa, makanan yang dikonsumsi harus bersih sehingga tidak menimbulkan penyakit.</p>
                                        <p><strong>2.Tetap aktif</strong><br />Untuk menjaga kondisi badan tetap prima, manusia perlu aktif bergerak. Salah satu caranya dengan berolahraga secara rutin minimal dua jam dalam seminggu. Maka dari itu, vendor Halo Jasa disarankan untuk berolahraga demi meningkatkan aktifitas tubuh.</p>
                                        <p><strong>3.Batasi penggunaan ponsel dan media social</strong><br />Tak dipungkiri, kegiatan bermedia social memang banyak manfaatnya. Namun di sisi lain, terlalu sering berselancar di media social secara tidak langsung dapat mengakibatkan gangguan mental. Maka dari itu, bijaklah bersosial media dan menggunakan ponsel. Prioritaskan penggunaan ponsel untuk hal-hal produktif, seperti menerima order Halo Jasa.</p>
                                        <p><strong>4.Istirahat yang cukup</strong><br />Setelah beraktivitas, tubuh perlu beristirahat yang cukup untuk memulihkan energi. Istirahat dengan tidur selama empat hingga delapan jam per hari (jumlah jam tidur tergantung usia) akan memulihkan energimu.</p>
                                        <p><strong>5.Kembangkan kemampuan bersosialisasi</strong><br />Terlahir sebagai mahluk sosial, manusia tidak akan bisa lepas dari bantuan dan interaksi dengan manusia lainnya. Dengan bersosialisasi, maka Anda dapat menambah kenalan/teman, memperluas wawasan, hingga mengurangi stres.</p>
                                        <p>Demikian tips agar produktivitas Anda terjaga. Produktivitas terjaga, kerja bertenaga, pelanggan bahagia.</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Tips Agar Tidak Bau Badan',
                        content: [
                            {
                                text: `
                                        <p>Pekerjaan Anda sebagai seorang vendor Halo Jasa tentu mengharuskan mobilitas yang tinggi. Tetapi, mobilitas tinggi berisko tinggi berkeringat akibat menempuh perjalanan di bawah terik matahari.&nbsp;<strong>Keringat yang menumpuk berpotensi besar menyebabkan terjadi bau tak sedap.</strong>&nbsp;Tentu ini membuat rasa tidak nyaman bagi pelanggan terutama saat Anda sedang mengerjakan order layanan. Berikut t<strong>ips agar badan Anda tetap wangi sepanjang hari.</strong></p>
                                        <p><strong>1.Mandi minimal 2 kali sehari</strong><br />Jarang mandi menyebabkan bakteri penyebab bau badan berkembang biak tak terkendali. Mandilah untuk menghilangkan bakteri tersebut.</p>
                                        <p><strong>2.Hindari makan makanan penyebab bau badan</strong><br />Beberapa makanan dapat menyebabkan bakteri bau badan berkembang biak sangat cepat. Sebisa mungkin hindari seperti daging merah, alcohol, kafein, hingga tembakau.</p>
                                        <p><strong>3.Rajin konsumsi buah-buahan</strong><br />Tak perlu diragukan, buah-buahan terbukti baik bagi tubuh. Maka dengan rajin mengkonsumsi buah-buahan, bau badan dapat diminimalisir karena melancarkan pencernaan.</p>
                                        <p><strong>4.Rutin konsumsi sayur hijau</strong><br />Klorofil alami sayuran terbukti ampuh menghilangkan bau badan sehingga bisa disebut sebagai deodoran alami.</p>
                                        <p><strong>5.Banyak minum air putih</strong><br />Keringat tidak lancar keluar akibat bakteri di dalam tubuh. Dengan banyak minum air putih setidaknya enam gelas sehari, maka bakteri penyebab bau badan dapat mudah keluar dari tubuh melalui keringat.</p>
                                        <p><strong>6.Ganti pakaian secara rutin</strong><br />Semakin jarang Anda berganti pakaian maka bakteri pada kulit akan menempel pada kulit dan berkembang biak dengan cepat. Secara tidak langsung, bau badan akan semakin lama menempel di baju tersebut.</p>
                                        <p><strong>7.Gunakan deodoran</strong><br />Pencegahan lainnya dapat menggunakan deodoran seperti spray/semprot yang tidak meninggalkan noda pada ketiak baju.</p>
                                        <p><strong>8.Cukur bulu ketiak Anda</strong><br />Bulu di ketiak merupakan tempat yang paling disukai untuk berkembang biak. Lakukan pencukuran agar tidak banyak bakteri menempel.</p>
                                        <p>Semoga artikel ini bermanfaat. Dengan badan wangi, kepercayaan diri Anda meningkat sehingga tidak langsung membuat pekerjaan Anda maksimal.</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Tips Berkomunikasi Yang Efektif',
                        content: [
                            {
                                text: `
                                        <p>Salah satu elemen penting dalam menunjang kepuasan pelanggan dalam pelayanan yang Anda berikan ialah melalui komunikasi efektif. Komunikasi dapat dilakukan melalui beragam cara seperti merangkai kata-kata, gambar, suara, ekspresi, hingga bahasa tubuh. Berikut tips yang bisa agar komunikasi Anda berjalan efektif dengan pelanggan.</p>
                                        <p><strong>1.Berikan perhatian penuh kepada lawan bicara</strong><br />Saat berbicara dengan pelanggan, jadikan mereka sebagai skala prioritas kita. Berikan perhatian penuh dan sedapat mungkin hindari perhatian kita terpecah karena hal lain.</p>
                                        <p><strong>2.Berbicara dengan baik</strong><br />Agar pelanggan memahami maksud Anda dengan baik dan tidak keliru, maka perhatikan intonasi, kejelasan bicara, serta pergunakan kata-kata yang dimengerti pelanggan.</p>
                                        <p><strong>3.Kendalikan ekspresi wajah saat berbicara</strong><br />Ekspresi wajah dapat menjadi petunjuk apakah Anda mengerti yang dimaksud oleh pelanggan, begitupun sebaliknya. Sehingga Anda dapat mengambil langkah komunikasi berikut yang efektif.</p>
                                        <p><strong>4.Bersikap sabar saat mendengarkan orang lain</strong><br />Saat berbicara dengan pelanggan, kita harus senantiasa bersikap sabar terhadap apa yang dikatakan pelanggan. Caranya dengan menghindari prediksi terhadap apa yang akan dikatakan oleh pelanggan dan tetap fokus terhadap apa yang sedang dikatakan.</p>
                                        <p><strong>5.Tetap fokus pada pokok permasalahan</strong><br />Terkadang, suatu pembicaraan atau diskusi bisa berkembang menjadi sebuah debat atau perang opini. Saat situasi ini terjadi, ada baiknya Anda maupun pelanggan yang terlibat dalam diskusi tetap memberikan rasa hormat satu sama lain serta tetap berfokus pada permasalahan. Masing-masing pihak tentu perlu mengendalikan diri agar keluar dari situasi debat tersebut.</p>
                                        <p><strong>6.Kendalikan emosi saat membicarakan sesuatu yang penting</strong><br />Adalah penting untuk memahami emosi seseorang seperti sakit, frustasi, kehilangan, dsb. Hal ini agar tidak terjadi salah paham dengan pelanggan saat Anda berkomunikasi dengannya.</p>
                                        <p><strong>7.Pahami konteks bahasa non verbal</strong><br />Selain verbal, bentuk komunikasi terhadap pelanggan dapat juga berupa bahasa non-verbal yaitu melalui nada suara, bahasa tubuh, kontak mata, hingga seberapa jauh jarak ketika berkomunikasi dengan orang lain. Maka dari itu perlu diperhatikan bahasa verbal yang kita sampaikan saat pelanggan mengekspresikan sesuatu melalui bahasa non verbal. Begitu juga sebaliknya.</p>
                                        <p><strong>8.Lakukan konfirmasi atas apa yang kita pahami</strong><br />Ketika berkomunikasi dengan orang lain, terutama kepada pelanggan sering terjadi kegagalan penyampaian maksud dari komunikasi tersebut saat pertama kali. Untuk itu setelah pelanggan menyampaikan maksudnya, jika Anda tidak yakin maka lakukan konfirmasi atas maksud yang disampaikan oleh pelanggan sebelunya dengan bahasa kita sendiri yang mudah dipahami.</p>
                                        <p>Semoga artikel ini bermanfaat.</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Tips Memahami Perilaku Tidak Sopan',
                        content: [
                            {
                                text: `
                                        <p>Bersikap sopan santun harus diterapkan kapan pun oleh siapapun, termasuk saat mengerjakan order layanan. Namun hal ini terkadang masih bias seperti apakah tindak perilaku tidaksopan atau sikap seperti apa yang masuk dalam kategori tersebut. Maka bisa dilihat melalui beberapa perilaku berikut.</p>
                                        <ul>
                                        <li>Berteriak dan melakukan gerakan kasar</li>
                                        <li>Menunjukkan rasa dan sikap tidak hormat atas hak-hak Anda</li>
                                        <li>Mengikutsertakan jenis kelamin maupun fungsi tubuh sedemikian rupa saat berbicara hingga menyinggung perasaan orang tertentu.</li>
                                        </ul>
                                        <p><strong>Ketahui penyebab munculnya perilaku tidak menyenangkan</strong></p>
                                        <p>Banyak alasan mengapa sikap tersebut muncul. Bisa jadi salah satunya perbuatan yang Anda lakukan dulu di masa lampau. Dengan memahami perilaku tidak sopan tersebut, Anda akan mendapatkan sudut pandang lebih luas dan dapat menanggapi hal tersebut dengan sikap waspada yang lebih baik. Selain itu, Anda perlu mempelajari tentang apa yang menyebabkan perilaku tidak menyenangkan sebagai berikut.</p>
                                        <p><strong>Temukan motivasi yang mendasari sikap tersebut</strong></p>
                                        <p>Banyak hal yang mendasari mengapa orang berperilaku tidak sopan kepada Anda. Bisa jadi hal itu muncul akibat perilaku Anda kepada dia di masa lalu atau memang sikapnya yang demikian. Dengan mengetahui hal tersebut, maka Anda dapat memahami lebih luas dari sudut pandangnya mengapa dia berbuat seperti itu. Namun jika Anda tidak berkenan dengannya, maka Anda bisa menjauh darinya..</p>
                                        <p><strong>Ajari diri tentang berbagai efek bersikap tidak sopan</strong></p>
                                        <p>Jika anda memerlukan alasan untuk tetap menjauh dari orang-orang yang tidak sopan atau untuk meredakan sikap kasar, perhatian dampak kesopanan tersebut terhadap Anda. Menerima perilaku tidak sopan dari orang lain tentu akan merusak banyak hal mulai dari kreativitas dan daya kerja otak hingga seberapa Anda ingin menjadi berguna bagi yang lain.</p>
                                        <p><strong>Contoh konkret perilaku tidak sopan</strong></p>
                                        <p>Berikut contoh perilaku yang secara tidak sadar maupun sadar Anda lakukan</p>
                                        <ul>
                                        <li>Bila Anda sedang melayani Pelanggan dan Anda batuk, segera tutup mulut Anda dengan tangan kemudian bersihkan tangan Anda dan lanjutkan pekerjaan Anda.</li>
                                        <li>Memotong pembicaraan ketika lawan bicara sedang berbicara.</li>
                                        <li>Membuang sampah tidak pada tempatnya atau tidak menjaga kebersihan lingkungan.</li>
                                        <li>Berbicara atau tertawa terlalu keras kepada lawan bicara.</li>
                                        <li>Membicarakan kejelekan orang lain dengan lawan bicara.</li>
                                        <li>Mengambil atau memindahkan sesuatu tanpa sepengetahuan pemiliknya.</li>
                                        <li>Melewati atau melintas di depan orang yang lebih tua tanpa membungkuk sebagai rasa hormat.</li>
                                        </ul>
                                        <p>Dengan memahami dan mengenali sikap tidak sopan, maka Anda senantiasa dapat selalu menjaga sikap profesional saat mengerjakan order layanan.<br />Semoga artikel ini bermanfaat untuk Anda.</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Cara Aman Membawa Barang Dengan Sepeda Motor',
                        content: [
                            {
                                text: `
                                        <p>Motor bisa dikatakan merupakan alat transportasi yang populer bagi banyak masyarakat Indonesia. Tak terkecuali bagi Anda Vendor Halo Jasa sebagai alat angkut dalam mengerjakan order layanan pelanggan. Sepeda Motor terbukti efisien dan efektif baik secara biaya dan waktu. Dan tentu saja, Anda juga sering membawa peralatan kerja Anda saat mengendarai. Berikut kami berikan&nbsp;<strong>tips membawa barang yang aman dalam berkendara</strong>:</p>
                                        <p><strong>1.Pastikan barang muatan terikat kuat pada sepeda motor.</strong>&nbsp;Hal ini untuk memastikan barang bawaan tidak menggangu keseimbangan Anda saat mengemudi.</p>
                                        <p><strong>2.Barang muatan tidak boleh melebihi lebar setang kemudi sepeda motor.</strong>&nbsp;Sehingga pengendara tetap dapat melihat lalu lintas di belakang pengendara melalui kaca spion. Pengendara pun juga bisa menjadikan setang kemudi sebagai tolak ukur jarak barang dengan pengendara lain di sisi samping.</p>
                                        <p><strong>3.Tinggi barang tidak boleh melebihi punggung pengendara.</strong>&nbsp;Ini bertujuan agar keseimbangan sepeda motor tidaklah terganggu dan sepeda motor dapat bergerak stabil.</p>
                                        <p><strong>4.Berat muatan tidak melebihi beban maksimal yang diperbolehkan</strong>&nbsp;(Dengan batasan tercantum dalam buku pendoman kendaraan bermotor). Jika beban terlalu berat akan menggangu keseimbangan sepeda motor baik saat menikung maupun menanjak.</p>
                                        <p><strong>5.Dilarang membawa barang di depan pengemudi</strong>, karena dapat menggangu pandangan, menyulitkan kontrol kemudi maupun saat menggunakan pengereman.</p>
                                        <p><strong>6.Selalu pastikan lampu sein dan lampu rem belakang tidak tertutup oleh barang bawaan.</strong>&nbsp;Bila lampu sein dan rem tertutup, maka kendaraan di belakang tidak bisa mendapatkan informasi terkait perubahan arah sepeda motor.</p>
                                        <p>Berdasarkan&nbsp;<strong>pasal 10 ayat 4</strong>&nbsp;di dalam undang-undang (UU) lalu lintas, bahwa muatan barang saat berkendara dalam menggunakan sepeda motor adalah sebagai berikut.<br /><strong>-Tidak boleh melebihi lebar dari ukuran lebar setang kemudi</strong><br /><strong>-Tinggi muatan barang tidak boleh melebihi 90 cm/900 mm dari atas jok pengemudi.</strong><br /><strong>-Posisi barang harus di belakang pengemudi.</strong></p>
                                        <p>Tentu saja, dalam hal ini, vendor Halo Jasa yang dibekali tas vendor telah dinyatakan aman untuk dibawa saat berkendara karena mempunyai ukuran tinggi 65 cm (khusus vendor Halo Clean).</p>
                                        <p>Mengangkut barang pun diatur dalam undang-undang no.22 tahun 2009 tentang lalu lintas dan angkutan jalan (UU 22/2009). Serta secara khusus diatur dalam peraturan pemerintah no.74 tahun 2014 tentang angkutan jalan (PP 74/2014). Jadi, tetaplah berkendara dengan aman dan patuhi selalu peraturan lalu lintas.<br />Semoga artikel ini bermanfaat.</p> 
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Lakukan Berikut Jika Pelanggan Salah Memilih Layanan',
                        content: [
                            {
                                text: `
                                        <p>Orderan telah datang, namun layanan yang telah dipesan nyatanya tidak sesuai dengan keinginan pelanggan. Seperti contoh berikut.<br />&ndash;<strong>Pelanggan memesan layanan Full Body Massage di aplikasi. Ternyata saat tiba di lokasi, pelanggan ingin Massage Reflexology.</strong><br /><strong>-Pelanggan memesan layanan Cuci Mobil pada aplikasi. Ternyata pelanggan menginginkan Cuci mobil termasuk detailing.</strong></p>
                                        <p>Serta masih banyak lagi contoh lainnya yang ada dalam aplikasi Halo Jasa.</p>
                                        <p>Agar kerugian tersebut dapat dihindari akibat kesalahan pesanan dari pelanggan, maka Halo Jasa menyarankan agar Anda mengarahkan pelanggan untuk membatalkan pesanan dari aplikasi. Lalu sarankan mereka untuk membuat pesanan kembali sesuai jenis layanan yang tepat. Tentu kasusnya berbeda jika pelanggan ternyata tidak dapat dihubungi. Maka dari itu, ikuti langkah berikut.</p>
                                        <p><strong>1.Hubungi segera layanan Admin Costumer Call Halo Jasa agar laporan dapat langsung menindaklanjuti langsung kendala Anda.</strong></p>
                                        <p><strong>2.Gunakan menu bantuan pada Aplikasi untuk memberikan laporan secara jelas kepada kami.</strong></p>
                                        <p><strong>3.Kirimkan email dan laporan kendala yang dihadapi agar tim kami dapat menindaklanjuti permasalahan yang Anda hadapi di lapangan.</strong></p>
                                        <p>Demikian beberapa cara yang dapat dilakukan Anda dalam mengatasi pelanggan yang salah memilih jenis layanan. Kami berharap agar Anda selalu dapat memberikan pelayanan terbaik dan menjamin kepuasan dari pelanggan.</p>
                                        <p>Semoga artikel ini bermanfaat.</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Memiliki Kinerja Tinggi Saat Berpuasa',
                        content: [
                            {
                                text: `
                                        <p>Memasuki bulan ramadhan, sudah pasti banyak Anda yang muslim mulai melaksanakan ibadah puasa.&nbsp;<strong>Agar puasa Anda tidak terganggu namun tetap memiliki kinerja yang optimal, kami berikan tips sebagai berikut.</strong></p>
                                        <p><strong>1.Jaga selalu kondisi badan</strong><br />Salah satu syarat agar puasa tetap lancar ialah dengan menjaga kondisi tubuh tetap fit. Dengan tubuh yang fit, kinerja Anda akan sangat optimal dalam menyelesaikan order yang datang. Berikut langkah menjaga tubuh tetap fit saat puasa.<br />-Jangan lewatkan sahur.<br />-Selalu minum vitamin agar daya tahan tubuh terjaga.<br />-Perbanyak minum air putih saat santap sahur dan berbuka puasa.<br />-Atur waktu istirahat, jangan sampai Anda kurang tidur atau istirahat.<br />-Jangan buang tenaga untuk kegiatan yang tidak penting dan efisien.</p>
                                        <p><strong>2.Menjaga lisan dan perbuatan</strong><br />Dalam kondisi lapar dan haus tentu akan mudah bagi Anda untuk terpancing emosi. Jika emosi tidak dikontrol, dikhawatirkan akan terjadi hal yang tidak-tidak saat berhadapan dengan pelanggan. Simak cara berikut agar emosi senantiasa terkontrol.<br />-Bicara dan lakukan kegiatan seperlunya.<br />-Pikir dan pertimbangkan baik-baik sebelum Anda berbicara atau melakukan sesuatu.<br />-Senantiasa bersikap sabar</p>
                                        <p>Setelah melakukan langkah di atas, diharapkan Anda tetap dapat secara optimal mengerjakan layanan kepada pelanggan sejalan dengan lancarnya Anda mengerjakan ibadah puasa. Bersikaplah senantiasa profesional dan raih rating dari pelanggan. Selamat menunaikan ibadah puasa bagi Anda yang menunaikan.<br />Semoga artikel ini bermanfaat.</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Menjaga Kesehatan Tubuh Di Musim Pancaroba',
                        content: [
                            {
                                text: `
                                        <p>Tinggal di Indonesia memiliki resiko beragam, salah satunya menghadapi musim pancaroba. Musim yang tak bisa ditebak seperti itu berakibat kesehatan tubuh menjadi rentan. Berikut kami berikan&nbsp;<strong>cara agar daya tahan tubuh Anda tetap terjaga di musim pancaroba.</strong></p>
                                        <p><strong>1.Perbanyak konsumsi air putih</strong><br />Tubuh manusia sebagian besar terdiri dari cairan. Untuk itu, penuhi kebutuhan cairan tubuh dengan minum air putih terutama di saat terik matahari agar tidak terdehidrasi.</p>
                                        <p><strong>2.Rajin berolahraga</strong><br />Agar badan tidak kaku dan selalu bugar, rutinlah berolahraga minimal 30 menit setiap hari. Tidak harus yang berat, dengan berjalan kaki atau bersepeda saja cukup untuk membakar kalori tubuh.</p>
                                        <p><strong>3.Konsumsi asupan gizi seimbang</strong><br />Tubuh yang sehat didapat dengan asupan gizi seimbang. Sumber karbohidrat, protein, vitamin, mineral, lemak perlu untuk membangun serta menjaga tubuh tetap sehat. Jangan lupa, asupan buah-buahan dan sayur-sayuran hijau juga perlu diperbanyak sebagai serat baik.</p>
                                        <p><strong>4.Cuci tangan setelah melakukan aktivitas</strong><br />Tangan sebagai media yang sering berinteraksi dengan banyak benda rentan dihinggapi beragam bakteri, kuman, hingga virus. Jadi rajinlah mencuci tangan setelah melakukan aktivitas agar tidak masuk ke dalam tubuh saat makan. Sehingga sakit dapat dihindari.</p>
                                        <p><strong>5.Istirahat yang cukup</strong><br />Tak ada yang lebih baik dari istirahat yang cukup, termasuk tidur setelah seharian beraktivitas. Tubuh perlu mengisi ulang tenaga dengan istirahat cukup.</p>
                                        <p>Semoga artikel ini bermanfaat bagi Anda.<strong>&nbsp;Jaga dan rawat tubuh Anda sehingga produktivitas tetap terjaga.</strong></p>
                                        `
                            }
                        ]
                    },
                ]
            },
            {
                title: 'Penggunaan Ponsel dan Aplikasi',
                subdata: [
                    {
                        title: 'Jaga Privasi dan Keamanan Ponsel Anda',
                        content: [
                            {
                                text: `
                                        <p>Penyadapan ponsel semakin marak di zaman seperti ini. Hal ini tentunya membahayakan keamanan, privasi, dan kenyamanan korban. Jika sampai gadget Anda disadap, beragam informasi pribadi maupun profesional yang ada di dalam ponsel Anda sangat berpotensi disalahgunakan hingga bisa di retas pihak yang tak bertanggung jawab.<br />Maka dari itu, Halo Jasa selalu menghimbau para vendor untuk menjaga keamanan dan privasi di dalam ponsel Anda. Karena ponsel merupakan alat utama yang wajib Anda gunakan setiap hari. Berikut tipsnya.</p>
                                        <p>1.Selalu perbaharui perangkat lunak serta aplikasi terbaru.</p>
                                        <p>2.Pastikan membaca deskripsi dan ulasan mengenai aplikasi yang akan Anda unduh.</p>
                                        <p>3.Jangan sembarangan mengunduh aplikasi dari sumber tidak tepercaya.</p>
                                        <p>4.Kunci selalu aplikasi dan layar gadget Anda, serta pasang verifikasi dua langkah agar keamanan makin maksimal.</p>
                                        <p>5.Selalu pastikan untuk melakukan pengaturan Android Device Manager.</p>
                                        <p>6.Hindari menyambungkan sembarang Wi-Fi maupun Bluetooth, terutama di tempat-tempat umum.</p>
                                        <p>Semoga artikel tersebut bermanfaat. Ingat selalu untuk waspada dalam menggunakan ponsel Anda.</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Lakukan ini Jika Ponsel Anda Hilang',
                        content: [
                            {
                                text: `
                                        <p>Saat ini, ponsel sebagai benda yang tak terpisahkan pastinya dijaga dengan baik-baik. Meski begitu, sudah dijaga pun kekhilafan masih bisa terjadi yang berakibat hilangnya ponsel Anda, baik itu disengaja maupun tidak. Sehingga data penting pun bisa lenyap tak berbekas baik foto, nomor kontak, serta beragam file pribadi lainnya. Untuk mengantisipasi hal tersebut, berikut langkah yang bisa Anda lakukan.</p>
                                        <p><strong>1.Aktifkan selalu fitur GPS atau location di dalam ponsel Anda.</strong><br />Hal ini memudahkan pelacakan saat terjadi kehilangan ponsel. Tracking di dalam ponsel Anda bisa dilakukan dengan memantaunya melalui gadget lain.</p>
                                        <p><strong>2.Gunakan Sandi ponsel.</strong><br />Dengan terpasangnya sandi ponsel, maka Anda memberi akses terbatas penggunaan ponsel hanya kepada Anda pribadi sebagai pemilik.</p>
                                        <p><strong>3.Ingat dan catat kode IMEI ponsel Anda.</strong><br />Setiap ponsel memiliki kode unik berupa kode IMEI yang berjumlah 15 angka. Kode ini bisa ditemukan melalui fitur About Me dan punya kegunaan sebagai pelacak jika ponsel Anda hilang.</p>
                                        <p><strong>4.Laporkan langsung kepada Halo Jasa.</strong><br />Dengan segera melaporkan kehilangan, akan semakin cepat bagi kami melakukan tindakan keamanan demi menghindari penyalahgunaan pemakai.<br />Dan yang paling penting, pencegahan terhadap data dapat dilakukan melalui backup berkala agar data Anda selalu aman saat situasi yang tidak diinginkan terjadi.</p>
                                        <p>Semoga artikel ini bermanfaat.</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Tata Cara Melakukan Withdrawal',
                        content: [
                            {
                                text: `
                                        <p>Setelah order layanan selesai dilakukan, kini saatnya Anda menikmati jerih payahmu yang telah terkumpul di dalam rekening&nbsp;<strong>OVO/DANA</strong>&nbsp;Anda. Berikut ketentuan, peraturan, serta&nbsp;<strong>tata cara melakukan withdraw</strong>.</p>
                                        <p><strong>Tata cara withdrawal.</strong></p>
                                        <p><strong>Ketentuan Withdrawal</strong><br />Penggunaan rekening&nbsp;<strong>Bank BCA</strong></p>
                                        <ul>
                                        <li>Waktu withdrawal dapat dilakukan kapan saja&nbsp;<strong>(24 jam)</strong>.</li>
                                        <li><strong>Batas melakukan withdraw</strong>&nbsp;dalam&nbsp;<strong>satu hari sebanyak satu kali</strong></li>
                                        <li><strong>Tidak ada maksimum dan minimum saldo</strong>&nbsp;yang bisa ditarik.</li>
                                        <li><strong>Minimal deposit dana deposit tersisa tidak ada</strong>, tetapi jika tidak ada saldo maka tidak dapat menerima order.</li>
                                        <li>Dana withdraw akan masuk ke akun rekening mitra dalam&nbsp;<strong>waktu 1 hari kerja.</strong><br /><strong>*tanggal merah tidak berlaku</strong></li>
                                        </ul>
                                        <p>Semoga artikel ini bermanfaat</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Cara Mendapatkan Order Pada Aplikasi Halo Jasa Vendor',
                        content: [
                            {
                                text: `
                                        <p>Apakah Anda sering kesulitan untuk mendapatkan order dari aplikasi Halo Jasa vendor ? kesulitan bisa datang dari banyak hal. Bisa dari koneksi internet yang tidak baik, pengaturan pada ponsel yang kurang tepat atau aplikasi belum ter-update ke versi terbaru.<br />Untuk itu, kami berikan beberapa langkah untuk mempermudah Anda mendapatkan order seperti berikut.<br />&ndash;<strong>Pastikan aplikasi vendor Halo Jasa Anda merupakan versi terbaru.</strong>&nbsp;Silahkan unduh aplikasi tersebut di&nbsp;<strong>https://play.google.com/store/apps/details?id=com.halojasa.vendorapp&amp;hl=en</strong></p>
                                        <p>-Jika Anda menggunakan jaringan Wi-Fi,&nbsp;<strong>pastikan Wi-Fi Anda tetap aktif walau layar dalam kondisi tidak menyala.</strong></p>
                                        <p>&ndash;<strong>Dihimbau menghindari memasang aplikasi pihak ketiga</strong>&nbsp;yang dapat menyebabkan aplikasi Halo Jasa Vendor tidak dapat berjalan dengan baik.&nbsp;<strong>Contohnya seperti AntiVirus, Speed Booster, Cleaner, dsb.</strong></p>
                                        <p>Semoga artikel ini bermanfaat untuk Anda. Jika terdapat pertanyaan, silahkan menghubungi bagian teknis Halo Jasa.</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Cara Melakukan Konfirmasi Langsung Kepada Pelanggan',
                        content: [
                            {
                                text: `
                                        <p>Apa yang seharusnya dilakukan sebagai vendor ? pertama, tentu saja Anda dihimbau untuk mengkonfirmasi kepada pelanggan setelah menerima order. Dengan melakukan konfirmasi, maka beragam hal-hal yang tidak diinginkan seperti cancel booking atau salah datang ke lokasi pemesanan bisa dihindari.</p>
                                        <p>Dalam melakukan konfirmasi, terdapat beberapa tahap. Yaitu konfirmasi panggilan langsung, tahap kedatangan, hingga tahap persiapan dan selesai.</p>
                                        <p><strong>Tahap konfirmasi (Dengan panggilan langsung)</strong><br />-Ucapkan salam pembuka dengan perkenalan diri Anda sebagai vendor Halo Jasa yang akan melayani pelanggan sesuai dengan layanan yang dipesan pada hari itu.<br />-Konfirmasi order dengan detail sesuai yang tertera di aplikasi Halo Jasa Vendor.<br />-Konfirmasikan penerima layanan (data pelanggan seperti nama, lokasi, dan jenis layanan yang di order).<br />-Ucapkan terima kasih.<br />-Konfirmasikan keberangkatan saat Anda saat siap berangkat ke lokasi pemesanan.</p>
                                        <p><strong>Tahap kedatangan</strong><br />Berikut beberapa hal yang perlu dilakukan saat Anda telah tiba di lokasi pemesan :<br />-Beritahu kepada pelanggan bahwa Anda telah sampai di lokasi pemesanan<br />-Swipe &ldquo;Telah tiba&rdquo; di aplikasi Halo Jasa Vendor Anda.<br />-Ucapkan salam pembuka dan perkenalkan diri secara singkat.<br />-Informasikan bahwa Anda merupakan vendor Halo Jasa yang bekerja secara profesional dan keberadaan Anda dipantau tim operasional Halo Jasa.<br />-Pastikan peralatan yang digunakan sesuai SOP<br />-Informasikan pula layanan yang akan diberikan kepada pelanggan sesuai SOP Halo Jasa.</p>
                                        <p><strong>Tahap Persiapan</strong><br />Saat mulai mengerjakan layanan di lokasi pemesanan, berikut beberapa hal yang harus Anda lakukan:<br />-Tunggu sampai pelanggan mempersilahkan Anda masuk ruangan.<br />-Tanyakan ruang atau area mana yang bisa digunakan untuk mengerjakan layanan<br />-Minta izin dan persiapkan peralatan yang dibutuhkan setelah ditunjuk ruangan mana yang diizinkan.<br />-Sebelum mulai pengerjaan pula, dihimbau cuci tangan dengan menanyakan serta izin dari pelanggan untuk tempat mencuci tangan.<br />-Konfirmasikan kepada pelanggan sekali lagi terkait layanan yang dipilih pelanggan.<br />-Mulai swipe &ldquo;Mulai bekerja&rdquo; pada aplikasi Halo Jasa Vendor.</p>
                                        <p><strong>Tahap penyelesaian</strong><br />Setelah selesai pengerjaan layanan, maka lakukan langkah berikut.<br />-Ucapkan terima kasih, langsung tanyakan kondisi pelanggan dan tanyakan saran atau masukan dari pelanggan.<br />-Tanyakan, apakah terdapat order tambahan yang tidak tertera di dalam aplikasi.<br />-Konfirmasikan pembayaran sesuai jumlah yang tertera di dalam aplikasi Halo Jasa.<br />-Ingatkan pelanggan untuk memberikan Anda rating pada aplikasi Halo Jasa. Tak lupa, sertakan komentar.<br />-Infokan salah satu fitur menarik dari Halo Jasa.<br />-Ucapkan salam penutup.<br />-Swipe &ldquo;Selesai pengerjaan&rdquo; saat Anda akan meninggalkan lokasi pengerjaan.</p>
                                        <p>Dengan mengikuti langkah di atas, diharapkan&nbsp;<strong>rating yang Anda terima dapat bernilai bagus.</strong>&nbsp;Semoga artikel ini bermanfaat.</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Fitur Penilaian Pelanggan Di Aplikasi Halo Jasa Vendor',
                        content: [
                            {
                                text: `
                                        <p>Tahukah Anda, di dalam fitur aplikasi Halo Jasa Vendor terdapat fitur &ldquo;Rating pelanggan&rdquo;. Fitur ini memungkinkan Anda untuk memberi penilaian kepada pelanggan, sehingga kedepan Anda dapat mengenali calon pelanggan yang akan dilayani.</p>
                                        <p>Berikut adalah panduan dalam pemberian nilai rating yang telah ditentukan oleh Halo Jasa.</p>
                                        <p><strong>Smiley murung</strong><br />Tanda ini diberikan kepada pelanggan jika mereka<br />-Melakukan tindak kekerasan, pelecehan seksual, ancaman, dan berperilaku tidak pantas.<br />-Meminta layanan ekstra, tidak sesuai dengan aplikasi, membuat Anda menunggu lama<br />-Ingin berlangganan tanpa memesan melalui aplikasi Halo Jasa.<br />-Bertindak kurang ramah.<br />-Dsb</p>
                                        <p><strong>Namun, Anda bisa memberikan smiley senyum</strong><br />-Mudah dihubungi<br />-Memberikan informasi yang jelas berupa alamat atau instruksi kerja.<br />-Ramah dan sopan.</p>
                                        <p>Perlu diketahui, pemberian fitur ini bisa dikatakan sebagai subjektif. Untuk itu, agar tidak terjadi kesalahpahaman, Anda perlu secara bijaksana dan bertanggung jawab memberikan rating sesuai dengan panduan penilaian Rating pelanggan di atas.<br />Semoga artikel ini bermanfaat untuk Anda.</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Info Pengaturan Booking',
                        content: [
                            {
                                text: `
                                        <p>Apakah Anda sudah tahu, bahwa di dalam fitur vendor Halo Jasa terdapat fitur Pengaturan Booking. Fitur ini memudahkan Anda dalam menentukan order masuk sesuai keinginan Anda.</p>
                                        <p><strong>1.Jarak Booking maksimal</strong><br />Fitur ini memudahkan Anda dalam menentukan jarak maksimal order yang masuk dalam aplikasi Anda. Seluruh layanan memiliki empat pilihan jarak maksimal order yaitu<br /><strong>-3 km</strong><br /><strong>-6 km</strong><br /><strong>-10 km</strong><br /><strong>-15 km</strong></p>
                                        <p><strong>2.Jenis kelamin (Khusus Halo Massage dan Halo Clean)</strong></p>
                                        <p>Sebagai vendor Halo Massage dan Halo Clean, Anda dapat menentukan jenis kelamin pelanggan sebagai preferensi Anda melalui fitur ini. Anda dapat memilih &ldquo;Laki-laki&rdquo;, &ldquo;Wanita&rdquo;, atau &ldquo;Tidak Ada&rdquo;.<br />Namun jika vendor memilih &ldquo;Tidak ada&rdquo;, order yang masuk pun bisa dari laki-laki ataupun perempuan. Nah jika Anda tidak berkenan melayani pelanggan dengan jenis kelamin berlawanan dengan Anda, gunakan fitur ini agar order yang masuk sesuai keinginan Anda.</p>
                                        <p>Demikian informasi mengenai pengaturan booking pada aplikasi Halo Jasa Vendor<br />Semoga artikel ini bermanfaat bagi Anda.</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Ketentuan Dalam Pembatalan Order Oleh Vendor',
                        content: [
                            {
                                text: `
                                        <p>Dalam pelaksanaan pekerjaan order, ada kalanya Anda harus membatalkan pesanan yang telah Anda ambil. Jika itu terjadi, berikut syarat dan ketentuan pembatalan order oleh vendor.<br /><strong>Cara membatalkan order yaitu melalui aplikasi Halo Jasa Vendor di tombol pembatalan disertai alasan pembatalan Anda.</strong></p>
                                        <p>Meski begitu, pembatalan order yang diperbolehkan adalah sebagai berikut.<br />1.Jika detail pengerjaan layanan diminta pelanggan tidak sesuai dengan tertera di aplikasi Halo Jasa</p>
                                        <ul>
                                        <li>Permintaan layanan di luar SOP</li>
                                        <li>Alamat pengerjaan tidak sesuai dengan keterangan detail pemesanan</li>
                                        <li>Khusus vendor Halo Massage, pemesanan oleh pelanggan dengan keterangan jenis kelamin berbeda atau tidak sesuai tertera di aplikasi.</li>
                                        <li>Waktu pengerjaan layanan yang tidak sesuai permintaan di aplikasi.</li>
                                        </ul>
                                        <p>2.Saat pelanggan melakukan pelecehan (baik verbal maupun fisik)<br />a.Jika pelanggan bertindak menjurus ke tindakan asusila.<br />b.Berkata tidak senonoh.</p>
                                        <p>3.Pelanggan tidak dapat dihubungi oleh vendor secara berkala (Untuk melakukan konfirmasi pemesanan dan kedatangan).</p>
                                        <p>4.Saat vendor mengalami musibah atau kecelakaan dalam perjalanan menuju tempat pengerjaan layanan.<br />a.Terkena kecelakaan lalu lintas.<br />b.Menjadi korban bencana alam.<br />c.Menjadi korban kriminalitas (Pencopetan, penjambretan, begal, dst).</p>
                                        <p><strong>HARAP DIPERHATIKAN!</strong><br /><strong>Jika terdapat order yang tidak dikerjakan atas alasan apapun merupakan bentuk pelanggaran SOP yang berdampak pada.</strong><br /><strong>1.Mendapatkan pinalti berupa kartu kuning.</strong><br />Untuk menghindari pembatalan order pelanggan serta senantiasa bisa memberikan pelayanan terbaik. Kami berikan beberapa saran berikut.<br /><strong>1.Setelah melalui proses Bidding, CERMAT-lah membaca detil order yang masuk.</strong>&nbsp;Jika tidak cocok dengan preferensi Anda, segera LEWATI. Saat Anda berhasil memenangkan suatu order, order tersebut telah menjadi kewajiban tiap vendor untuk diselesaikan sesuai SOP yang berlaku.<br /><strong>2.Lakukan konfirmasi terlebih dahulu sebelum menjalankan order</strong>, sampaikan secara detail mengenai layanan pesanan pelanggan.<br /><strong>3.Jika mendapat order yang seharusnya dibatalkan sesuai syarat di atas</strong>. Segera sampaikan pada pelanggan untuk melakukan pembatalan order via aplikasi Halo Jasa. Jika status order tidak jelas atau menggantung, segera hubungi Costumer/Vendor support Halo Jasa.</p>
                                        <p>Semoga artikel ini bermanfaat.</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Memahami Tombol Cancel Order Dalam Aplikasi Vendor Halo Jasa',
                        content: [
                            {
                                text: `
                                        <p>Pada fitur aplikasi vendor Halo Jasa, terdapat fitur pembatalan yang digunakan untuk membatalkan order yang telah diterima. Fitur ini dibuat untuk memenuhi kebutuhan Anda sebagai vendor Halo Jasa agar dapat melakukan pembatalan order dalam keadaan tertentu. Tetapi perlu Anda ketahui, jika Anda melakukan cancel akan berpengaruh terhadap kinerja Anda.</p>
                                        <p>Fitur ini dapat digunakan saat ini untuk seluruh layanan Halo Jasa.<br />Anda dapat melakukan Cancel Order saat tahap mendapat order sampai dengan menuju lokasi pemesanan pelanggan.</p>
                                        <p>Contoh perhitungan dampak Cancel Order terhadap performa Vendor Halo Jasa.<br />Misalkan di dalam satu hari Vendor Halo Clean mendapatkan enam (6) order dengan keterangan sebagai berikut.<br /><strong>-dua (2) booking order diselesaikan</strong><br /><strong>-dua (2) booking order ditolak</strong><br /><strong>-dua (2) booking order diambil, namun pada akhirnya ditolak.</strong></p>
                                        <p>Berdasarkan keterangan di atas, maka angka performa vendor pada hari itu ialah 33% dan tidak berhak mendapatkan bonus harian karena angka performa berada di bawah rata-rata minimal angka performa yaitu 70%.</p>
                                        <p>Untuk itu, gunakan dengan bijak fitur Cancel order tersebut sesuai kebutuhan Anda. Jika mendapatkan order mencurigakan, dengan senang hati Anda dipersilahkan untuk membatalkan order, atau bisa melapor ke Costumer Call Halo Jasa disertai bukti untuk melakukan pembatalan order.</p>
                                        <p>Semoga artikel ini bermanfaat</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Panduan Membuka Rekening Bank BCA',
                        content: [
                            {
                                text: `
                                        <p>Agar mempermudah Anda dalam melakukan pencairan dana, fitur&nbsp;<strong>Real Time Withdrawal</strong>&nbsp;bagi&nbsp;<strong>Vendor Halo Jasa</strong>. Fitur ini dapat berjalan jika Anda memiliki nomor rekening akun bank BCA. Berikut panduan untuk membuka rekening. Pembukaan rekening ini dapat melalui datang langsung ke cabang bank BCA terdekat atau melalui mobile banking.</p>
                                        <p>Dalam pembukaan rekening BCA, kami menyarankan Tahapan Xpresi karena memiliki fitur desain kartu yang menarik tanpa buku tabungan sama sekali. Biaya bulanan pun sangat ringan yaitu sebesar Rp5,000.00 / bulan. Setoran awal pun cukup Rp50,000.</p>
                                        <p><strong>Pembukaan melalui Mobile Banking.</strong></p>
                                        <ul>
                                        <li>Unduh aplikasi BCA Mobile di Play Store</li>
                                        <li>Ikuti langkah-langkah yang telah ditetapkan. Untuk informasi lengkap dapat langsung ke link&nbsp;<em>https://www.bca.co.id/bukarekening</em></li>
                                        </ul>
                                        <p>Selain itu Anda dapat mendaftarkan langsung ke Bank BCA cabang terdekat dengan membawa KTP asli serta setoran awal sebesar Rp50,000.00.<br />Ayo segera buka tabungan di BCA agar fitur Real Time Withdrawal dapat segera Anda manfaatkan untuk pencairan saldo OVO/DANA Anda.</p>
                                        <p>Semoga artikel ini bermanfaat.</p>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Cara menggunakan Whatsapp untuk menghubungi pelanggan',
                        content: [
                            {
                                text: `
                                        <p>Kenyamanan dan keamanan dalam bekerja merupakan prioritas kami kepada para Vendor. Untuk itu, saat mengkonfirmasi kepada pelanggan, kami menghimbau agar menghubunginya secara langsung ke nomor telepon mereka. Tetapi dalam suatu kondisi semisal pulsa sudah habis, maka pilihan lain bisa melalui Whatsapp. Tentu saja hal ini tidak diperkenankan oleh Halo Jasa karena dapat menggangu kenyamanan dan keamanan vendor Halo Jasa sekalian namun jika terpaksa pun boleh dilakukan.<br />Berikut tips menggunakan Whatsapp untuk menghubungi pelanggan demi kenyamanan dan keamanan Anda.</p>
                                        <p>1. Dihimbau nomor milik pelanggan tidak disimpan ke dalam kontak Anda<br />2. Kami menghimbau pula untuk tidak memasang foto pribadi Anda di akun profile Whatsapp Anda. Atau Anda bisa menyembunyikan foto profile Anda melalui cara berikut.</p>
                                        <p>Di lain hal, jika pelanggan terindikasi mulai melakukan hal-hal yang tidak sepatutnya pada Anda melalui Whatsapp, dapat memanfaatkan fitur Blokir. Jangan ragu untuk melaporkan kepada kami jika Anda memiliki bukti yaitu berupa chat atau Audio percakapan Anda melalui aplikasi Whatsapp tersebut jika pelanggan melakukan hal-hal yang tidak senonoh.<br />Semoga artikel ini bermanfaat</p>
                                        `
                            }
                        ]
                    },
                ]
            }
        ]
    },
    // {
    //     id: 3,
    //     title: 'Tutorial Halo-jasa',
    //     child: [
    //         {
    //             title: 'Halo Massage',
    //             subdata: [
    //                 {
    //                     title: 'Tutorial Teknik Pemanasan Memijat',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/lmyho6CfHk0?list=PLwTYQ6WiO8pwFguLjYiQwvP3e_J01I3ZF" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Tutorial Teknik Memijat Betis',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/-HlD6ao7D6Q?list=PLwTYQ6WiO8pwFguLjYiQwvP3e_J01I3ZF" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>                       
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Tutorial Teknik Memijat Betis (bagian 2)',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/ojcmaCr1TyY?list=PLwTYQ6WiO8pwFguLjYiQwvP3e_J01I3ZF" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Tutorial Teknik Memijat Kepala',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/YBbvjnGSkfk?list=PLwTYQ6WiO8pwFguLjYiQwvP3e_J01I3ZF" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Tutorial Teknik Memijat Leher & Pundak',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/YyI0sJwa43U?list=PLwTYQ6WiO8pwFguLjYiQwvP3e_J01I3ZF" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Tutorial Teknik Memijat Paha Luar',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/4fsJ8-CDikQ?list=PLwTYQ6WiO8pwFguLjYiQwvP3e_J01I3ZF" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Tutorial Teknik Memijat Paha Luar (bagian 2)',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/B-bF4lOa6t0?list=PLwTYQ6WiO8pwFguLjYiQwvP3e_J01I3ZF" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Tutorial Teknik memijat Punggung Kaki',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/YMl0bnMfHKk?list=PLwTYQ6WiO8pwFguLjYiQwvP3e_J01I3ZF" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Tutorial Teknik Memijat Punggung',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/lu7K5Of546Y?list=PLwTYQ6WiO8pwFguLjYiQwvP3e_J01I3ZF" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Tutorial Teknik Memijat Telapak Kaki Bagian Dalam',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/uboJE0vkNSQ?list=PLwTYQ6WiO8pwFguLjYiQwvP3e_J01I3ZF" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Tutorial Teknik Memijat Samping Kaki Luar',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/npsasiR3OCg?list=PLwTYQ6WiO8pwFguLjYiQwvP3e_J01I3ZF" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Tutorial Teknik Memijat Lengan',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/kr1kFzgqXaU?list=PLwTYQ6WiO8pwFguLjYiQwvP3e_J01I3ZF" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Tutorial Teknik Memijat Telapak Kaki',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/ZEYDC787YMs?list=PLwTYQ6WiO8pwFguLjYiQwvP3e_J01I3ZF" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Tutorial Teknik Memijat Telapak Kaki (bagian 2)',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/_tSy6_kiHtk?list=PLwTYQ6WiO8pwFguLjYiQwvP3e_J01I3ZF" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 }
    //             ]
    //         },
    //         {
    //             title: 'Halo Clean',
    //             subdata: [
    //                 {
    //                     title: 'Cara Membersihkan Balkon Secara Tuntas',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/CgOzeuU6dUw" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Cara membersihkan balkon dan teralis tangga',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/Hdd6vr8qWNs" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Cara Membersihkan Bathtub Agar Klien Selalu Nyaman',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/SsHIcTXOhaI" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Dapur Bersih Cermin Kesehatan Klien',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/GytH1tMcZXc" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Perhatikan Detail Dalam Membersihkan Gorden',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/kx9hsTUTcbc" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Cermat Dalam Membersihkan Kaca Rumah',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/IdyeRv2dLl4" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Teknik Membersihkan Toilet Closet bersih dan tuntas',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/4y0jsVTqkwc" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Cara Membersihkan Meja Kayu',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/-jIhujYwZ30" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Cara Membersihkan Meja Komputer',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/s8T8fei-96E" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Hati Hati dalam membersihkan meja kaca',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/RtE5nkvepUQ" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Perhatikan secara detail dalam membersihkan meja ruang tengah, meja marmer, dan menyapu',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/SL5Nky276VA" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Perhatikan dalam membersihkan perabot rumah, kamar, dan meja kantor',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/ItNUIGwRIuA" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Cara mudah membersihkan lemari',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/ehxh1LzpXJs" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Perhatikan Hal ini dalam membersihkan kamar mandi',
    //                     content: [
    //                         {
    //                             text: `
    //                                 <iframe width="100%" src="https://www.youtube.com/embed/uYmPPjTRwtc" 
    //                                 frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                 </iframe>
    //                                 `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Perhatikan cara membersihkan sofa yang tepat',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/2cKvYVtkcus" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Cara merapikan dan membersihkan kasur',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/t_FjGFwGADk" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Teknik membersihkan wallpaper',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/Y_QxLUary0M" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Standarisasi cara membersihkan wastafel',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/NpV3DYix_mU" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //             ]
    //         },
    //         {
    //             title: 'Halo Auto',
    //             subdata: [
    //                 {
    //                     title: 'Standarisasi Pembersihan Interior Layanan Halo Auto',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <p><iframe width="100%" src="https://www.youtube.com/embed/Hw-lejP0uyY" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe></p>
    //                                     <p>Sebagai vendor Halo Auto, Anda tentu perlu mengetahui bagaimana standarisasi yang diperlukan agar Anda dapat memberikan layanan yang maksimal. Layanan pembersihan Halo Auto meliputi dua hal yaitu bagian eksterior dan interior.</p>
    //                                     <p>Dan pada artikel ini, akan dibahas langkah-langkah pembersihan untuk bagian interior mobil dalam Halo Auto sesuai standarisasi yang ditetapkan oleh Halo Jasa</p>
    //                                     <p>Tahap Interior meliputi :</p>
    //                                     <ul>
    //                                     <li><strong>Pembersihan dashboard</strong>
    //                                     <ul>
    //                                     <li>Cek alat-alat di dalam kendaraan tanpa harus dipindahkan</li>
    //                                     <li>Lap semua bagian di bagian dashboard seperti setir, speedometer, dan permukaan dashboard</li>
    //                                     <li>Bersihkan permukaan kaca bagian dalam</li>
    //                                     </ul>
    //                                     </li>
    //                                     </ul>
    //                                     <p>&nbsp;</p>
    //                                     <ul>
    //                                     <li><strong>Pembersihan doortrim</strong>
    //                                     <ul>
    //                                     <li>Sebelumnya, keluarkan setiap karpet dari dalam mobil</li>
    //                                     <li>Lap permukaan doortrim dengan lap microfiber lembab</li>
    //                                     <li>Setelah itu, lap permukaan doortrim dengan lap microfiber kering</li>
    //                                     <li>Lap juga permukaan kaca bagian dalam dengan lap microfiber</li>
    //                                     </ul>
    //                                     </li>
    //                                     </ul>
    //                                     <p>&nbsp;</p>
    //                                     <ul>
    //                                     <li><strong>Pembersihan jok mobil</strong>
    //                                     <ul>
    //                                     <li>Gunakan microfiber lembab dan kering untuk membersihkan jok</li>
    //                                     <li>Lap hingga ke celah-celah jok mobil</li>
    //                                     <li>Angkat kursi bawah untuk dibersihkan dengan microfiber</li>
    //                                     </ul>
    //                                     </li>
    //                                     </ul>
    //                                     <p>&nbsp;</p>
    //                                     <ul>
    //                                     <li><strong>Pembersihan karpet mobil</strong>
    //                                     <ul>
    //                                     <li>Keluarkan seluruh karpet mobil terlebih dahulu</li>
    //                                     <li>Berdirikan jok mobil untuk mempermudah pengerjaan pengeluaran karpet</li>
    //                                     <li>Setelah seluruh permukaan mobil bersih, bisa mulai membersihkan karpet dengan posisi di bawah</li>
    //                                     <li>Setelah karpet bersih, Anda bisa memasukkan kembali karpet ke dalam mobil</li>
    //                                     </ul>
    //                                     </li>
    //                                     </ul>
    //                                     <p>&nbsp;</p>
    //                                     <ul>
    //                                     <li><strong>Pembersihan mesin mobil</strong>
    //                                     <ul>
    //                                     <li>Siapkan microfiber dan cairan waterless</li>
    //                                     <li>Buka kap mesin mobil dan semprot dengan cairan waterless</li>
    //                                     <li>Lap seluruh permukaan mesin dengan microfiber</li>
    //                                     </ul>
    //                                     </li>
    //                                     </ul>
    //                                     <p>Semoga artikel ini bermanfaat. Untuk lebih lengkap dan jelasnya, Anda dapat menyaksikan video vendor berikut.</p>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Standarisasi Pembersihan Eksterior Layanan Halo Auto',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <p><iframe width="100%" src="https://www.youtube.com/embed/cBZVtQ4rAkk" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe></p>
    //                                     <p>Untuk mendapatkan hasil pembersihan maksimal, maka Halo Jasa telah menerapkan standarisasi pelayanan untuk Halo Auto meliputi pembersihan eksterior dan interior. Agar para vendor lebih memahami bagaimana standarisasi pelayanan tersebut, berikut Halo Jasa sertakan langkah standarisasi pembersihan khususnya eksterior mobil sebagai berikut.</p>
    //                                     <p>Dalam standarisasi pencucian eksterior, terdapat dua langkah yaitu dengan aliran air maupun secara waterless (tanpa air). Berikut secara lengkap langkah-langkahnya.</p>
    //                                     <ul>
    //                                     <li>Cuci Ban dengan air
    //                                     <ul>
    //                                     <li>Siapkan ember, botol spray, air, sponge, sikat, &amp; shampoo valo</li>
    //                                     <li>Isi air dan 2 tutup botol shampoo valo kedalam ember</li>
    //                                     <li>Bersihkan dengan sponge</li>
    //                                     <li>Sikat dan bilas permukaan ban</li>
    //                                     <li>Oleskan semir ban pada permukaan ban</li>
    //                                     <li>Lap bagian velg dengan kain microfiber</li>
    //                                     </ul>
    //                                     </li>
    //                                     </ul>
    //                                     <p>&nbsp;</p>
    //                                     <ul>
    //                                     <li>Cuci ban waterless (Tanpa air)
    //                                     <ul>
    //                                     <li>Siapkan cairan waterless dank ain microfiber</li>
    //                                     <li>Semprotkan cairan waterless pada ban</li>
    //                                     <li>Bersihkan spakbor dalam menggunakan kain microfiber</li>
    //                                     <li>Bersihkan velg ban menggunakan kain microfiber</li>
    //                                     </ul>
    //                                     </li>
    //                                     </ul>
    //                                     <p>&nbsp;</p>
    //                                     <ul>
    //                                     <li>Cuci body mobil dengan air
    //                                     <ul>
    //                                     <li>Siapkan alat kerja Halo Auto</li>
    //                                     <li>Isi air secukupnya pada ember kecil</li>
    //                                     <li>Tuangkan dua tutup valoo shampoo</li>
    //                                     <li>Busakan dengan sponge</li>
    //                                     <li>Membersihkan panel mobil dengan sponge</li>
    //                                     <li>Bilas permukaan mobil dengan semprotan air</li>
    //                                     <li>Lap permukaan mobil dengan kain microfiber</li>
    //                                     </ul>
    //                                     </li>
    //                                     </ul>
    //                                     <p>&nbsp;</p>
    //                                     <ul>
    //                                     <li>Cuci body mobil waterless (Tanpa air)
    //                                     <ul>
    //                                     <li>Siapkan cairan waterless dan kain microfiber</li>
    //                                     <li>Semprotkan cairan waterless pada panel mobil</li>
    //                                     <li>Lap permukaan panel mobil dengan kain microfiber</li>
    //                                     <li>Saran : cuci waterless lebih baik menghindari sinar matahari</li>
    //                                     </ul>
    //                                     </li>
    //                                     </ul>
    //                                     <p>&nbsp;</p>
    //                                     <ul>
    //                                     <li>Body Wax
    //                                     <ul>
    //                                     <li>Siapkan cream body wax dan kain microfiber</li>
    //                                     <li>Aplikasikan body wax ke permukaan mobil</li>
    //                                     <li>Lap dengan kain microfiber kering</li>
    //                                     <li>Jika lap sudah mulai kotor, maka Anda dapat menggunakan sisi lainnya</li>
    //                                     </ul>
    //                                     </li>
    //                                     </ul>
    //                                     <p>&nbsp;</p>
    //                                     <ul>
    //                                     <li>Membersihkan jamur kaca
    //                                     <ul>
    //                                     <li>Waspada sebelumnya, bahwa terkena pembersih jamur kaca dapat menyebabkan iritasi</li>
    //                                     <li>Pastikan kaca dalam keadaan bersih, lalu semprotkan cairan anti jamur</li>
    //                                     <li>Lap dengan kain microfiber</li>
    //                                     <li>Bersihkan kaca bagian belakang dengan cara memutar</li>
    //                                     </ul>
    //                                     </li>
    //                                     </ul>
    //                                     <p>Diharapkan, para vendor dapat memahami&nbsp; langkah-langkah yang dijalankan saat mengerjakan layanan dari Halo Auto sesuai dengan standarisasi yang telah diterapkan.</p>
    //                                     <p>Semoga bermanfaat.</p>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Mencuci Ban Mobil Secara Baik dan Benar.',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/rmkxN3ElKfM" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Mencuci BAN Mobil Menggunakan Metode Pencucian WATERLESS Secara Baik dan Benar.',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/1hq7tJ0XQC4" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Mencuci Body Mobil Menggunakan Metode Pencucian Regular Secara Baik dan Benar.',
    //                     content: [
    //                         {
    //                             text: `
    //                             <iframe width="100%" src="https://www.youtube.com/embed/Km5o2_29neA" 
    //                             frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                             </iframe>
    //                             `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Mencuci BODY Mobil Menggunakan Metode Pencucian WATERLESS Secara Baik dan Benar.',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/Orwn1FCVVSc" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Cara Pengerjaan BODY WAX Pada Body Mobil Secara Baik dan Benar.',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/HekEwgooVyM" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Membersihkan Dashboard Secara Baik dan Benar',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/O9SfsrIUooA?list=PLwTYQ6WiO8pybDYJXgsLUlE1v1z92pW9l" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Membersihkan DOORTRIM Pada Mobil Secara Baik dan Benar.',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/undxm0WMwYg" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Membersihkan Jok Mobil Secara Baik dan Benar.',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/ZfkQ2Mx2u-M" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Membersihkan Jamur Kaca Secara Baik dan Benar.',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/YdCdS7HBGxc" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Membersihkan Karpet Mobil Secara Baik dan Benar.',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/JmNuL_cy_D4" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: 'Membersihkan Mesin Mobil Secara Baik dan Benar',
    //                     content: [
    //                         {
    //                             text: `
    //                                     <iframe width="100%" src="https://www.youtube.com/embed/kU8sL10j1ow" 
    //                                     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    //                                     </iframe>
    //                                     `
    //                         }
    //                     ]
    //                 },
    //             ]
    //         },
    //     ]
    // },
    {
        id: 4,
        title: 'Frequently Asked Questions (FAQ)',
        child: [
            {
                title: 'Teknis Aplikasi',
                subdata: [
                    {
                        title: 'Bagaimana Cara Bergabung Menjadi Vendor Halo Jasa',
                        content: [
                            {
                                text: `Untuk bergabung menjadi vendor di Halo Jasa, ikuti langkah berikut:<br />
                                        Kunjungi <a href="https://halojasa.com/">https://halojasa.com/</a>.<br />
                                        <ol>
                                            <li>Klik Gabung pada kanan atas web dan pilih Individu jika mendaftar secara personal, Bisnis jika Anda merupakan pemilik bisnis jasa.</li>
                                            <li>Isi form pendaftaran selengkap mungkin.</li>
                                            <li>Setelah pendaftaran berhasil, silahkan menunggu, Anda akan segera dihubungi oleh Admin halojasa untuk mendapatkan jadwal infosession halo jasa dan training.</li>
                                            <li>Setelah menjalani training, silahkan menunggu hasil. Jika Anda lulus training SOP maka Admin halojasa akan segera menghubungi Anda.</li>
                                            <li>Selamat, Anda telah resmi menjadi bagian dari vendor halojasa.</li>
                                        </ol>`
                            }
                        ]
                    },
                    {
                        title: 'Apa Keuntungan Bergabung Menjadi Vendor Halo Jasa',
                        content: [
                            {
                                text: `Berikut adalah keutungan yang akan Anda dapatkan dengan menjadi vendor di halo jasa:<br />
                                        <ol>
                                            <li>Pembagian hasil kerja yang menguntungkan.</li>
                                            <li>Insentif kerja berdasarkan performa vendor.</li>
                                            <li>Keseimbangan ekosistem vendor.</li>
                                            <li>Waktu kerja yang fleksibel.</li>
                                            <li>Jarak terima order yang bisa di atur.</li>
                                            <li>Training gratis untuk vendor halojasa.</li>
                                        </ol>`
                            }
                        ]
                    },
                    {
                        title: 'Berapa Pembagian Keuntungan yang Didapatkan Vendor Halo Jasa',
                        content: [
                            {
                                text: `Sebagai vendor, Anda akan mendapatkan pembagian hasil mencapai 95%, dengan rincian:<br />
                                        <ul>
                                            <li>Pembagian dasar : 80% untuk vendor dan 20% untuk halojasa</li>
                                            <li>Bonus tepat waktu : 5% untuk vendor</li>
                                            <li>Bonus performa bintang 4 : 5% untuk vendor</li>
                                            <li>Bonus performa bintang 5 : 10% untuk vendor</li>
                                        </ul>`
                            }
                        ]
                    },
                    {
                        title: 'Bentuk Promosi Seperti Apakah yang Dilakukan Halo-jasa kepada Mitra yang Bergabung?',
                        content: [
                            {
                                text: `Para calon vendor yang masih bingung dengan program promosi yang halojasa berikan, 
                                        silahkan kunjungi:<a href="https://halojasa.com/blog-vendor/peluang-usaha-tanpa-modal-hanya-di-halo-jasa/">
                                        https://halojasa.com/blog-vendor/peluang-usaha-tanpa-modal-hanya-di-halo-jasa/</a>`
                            }
                        ]
                    },
                ]
            },
            {
                title: 'Layanan',
                subdata: [
                    {
                        title: 'Bagaimana jika vendor belum sampai tujuan, namun order dibatalkan oleh pemesan?',
                        content: [
                            {
                                text: `Jika order Anda dibatalkan ketika sedang dalam perjalanan menuju tempat customer, saldo kredit Anda akan dikembalikan jika Anda sudah 
                                        melakukan SOP sesuai standar yaitu menghubungi customer.Pastikan Anda mengikuti SOP dengan menghubungi customer ketika Anda mendapatkan order, 
                                        agar resiko order dibatalkan berkurang. Pastikan juga alamat dan waktu order customer, agar Anda bisa melakukan kalkulasi waktu dan tidak 
                                        terhambat dalam perjalanan.`
                            }
                        ]
                    },
                    {
                        title: 'Bagaimana jika vendor menolak 3 kali orderan karena tidak disengaja?',
                        content: [
                            {
                                text: `Jika vendor menolak order hingga 3x karena tidak disengaja, vendor akan mendapatkan peringatan dari halo jasa mengenai tindakan cancel order tersebut.
                                        Halo jasa akan melakukan verifikasi terhadap tindakan vendor, jika terdapat masalah atau kendala, vendor bisa segera melaporkan pada halo jasa saat proses verifikasi tersebut.
                                        Vendor diharapkan untuk kooperatif dengan menjelaskan masalah agar bisa segera diselesaikan.`
                            }
                        ]
                    },
                    {
                        title: 'Bagaimana jika vendor sudah sampai lokasi tetapi pemesan tidak bisa dihubungi lagi selama lebih dari 30 menit?',
                        content: [
                            {
                                text: `Untuk membatalkan order yang sudah Anda terima, silahkan ikuti langkah berikut:<br />
                                        <ul>
                                            <li>Masuk ke aplikasi vendor halo jasa.</li>
                                            <li>Masuk ke tab “Order” di kanan bawah layar hp Anda.</li>
                                            <li>Klik rincian order yang akan dibatalkan.</li>
                                            <li>Klik titik tiga yang ada disebelah tombol “Call” dan “SMS”.</li>
                                            <li>Klik “Batalkan Order ini”.</li>
                                            <li>Pilih alasan pembatalan pada kolom yang disediakan.</li>
                                            <li>Klik “Kirim”.</li>
                                            <li>Pastikan untuk melakukan Screen Capture GPS dan Detail Order Anda. Simpan jika sewaktu-waktu dibutuhkan.</li>
                                        </ul>
                                        <div style="width: 100%; margin: auto; display: block; ">
                                        <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/Picture1.jpg" />
                                        <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/Picture2.jpg" />
                                        <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/Picture3.jpg" />
                                        <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/Picture4.jpg" />
                                        <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/Picture5.jpg" />
                                        </div>
                                        `
                            }
                        ]
                    },
                    {
                        title: 'Bagaimana jika vendor ingin mengganti nomor ponsel?',
                        content: [
                            {
                                text: `Untuk mengganti nomor HP Anda, ikuti langkah berikut ini<br />
                                        <ul>
                                            <li>Buka aplikasi halo jasa vendor.</li>
                                            <li>Klik menu "Akun".</li>
                                            <li>Klik tombol pengaturan, icon roda gerigi.</li>
                                            <li>Pada kolom nomor HP, klik "ubah".</li>
                                            <li>Masukkan nomor HP baru Anda dan klik "Berikutnya"</li>
                                            <li>Halojasa perlu melakukan verifikasi, akan ada SMS yang dikirimkan ke nomor hp yang baru Anda daftarkan. Kode merupakan OTP dalam bentuk 4 digit angka.</li>
                                            <li>Masukkan 4 digit angka yang Anda dapatkan.</li>
                                            <li>Selesai, nomor HP anda telah berhasil diganti.</li>
                                        </ul>
                                        <div style="width: 100%; margin: auto; display: block; ">
                                        <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/Picture6.jpg" />
                                        <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/Picture7.jpg" />
                                        <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/Picture8.jpg" />
                                        <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/Picture9.jpg" />
                                        <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/Picture10.jpg" />
                                        <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/Picture11.jpg" />
                                        </div>`
                            }
                        ]
                    },
                    {
                        title: 'Bagaimana jika vendor mengalami error di aplikasi sehingga app tidak jalan sehingga order tidak bisa masuk / confirm / selesai?',
                        content: [
                            {
                                text: `Jika Anda mengalami aplikasi error, berikut 2 langkah yang bisa Anda lakukan:<br />
                                        <ol>
                                            <li>Rekam dalam bentuk video atau lakukan screenshot aplikasi Anda yang error. Abaikan jika aplikasi HANG sepenuhnya.</li>
                                            <li>Segera hubungi Admin halojasa untuk bantuan lebih lanjut.</li>
                                        </ol>`
                            }
                        ]
                    },
                    {
                        title: 'Bagaimana caranya jika order sudah diterima dan diproses, pengguna jasa ingin mengganti layanan jasa / menambah layanan jasa melalui app?',
                        content: [
                            {
                                text: `Hingga saat ini, belum ada fitur untuk menambah atau mengubah layanan ketika sudah menerima dan mengerjakan order.
                                        Jika customer hendak mengganti atau menambah layanan, Anda bisa merekomendasikan customer untuk melakukan cancel order dan order ulang. Fitur vendor terdekat dari halojasa secara otomatis akan mengarahkan order baru tersebut kepada vendor.
                                        Pastikan untuk menyalakan aplikasi sehingga meminimalisir resiko terambil oleh vendor lain.`
                            }
                        ]
                    },
                ]
            },
            {
                title: 'Teknis Pembayaran',
                subdata: [
                    {
                        title: 'Mengapa saldo dompet deposit tidak bisa di tarik?',
                        content: [
                            {
                                text: `Dompet deposit adalah dompet tengah untuk pembagian hasil vendor dengan halojasa. Dompet Deposit hanya bisa di Top-Up saja oleh vendor halojasa.`
                            }
                        ]
                    },
                    {
                        title: 'Bagaimana jika saldo dompet deposit tidak bisa di top up?',
                        content: [
                            {
                                text: `Jika Dompet Deposit Anda mengalami gangguan, ada beberapa langkah yang bisa Anda lakukan:<br />
                                        <ul>
                                            <li>Rekam dalam bentuk video atau screenshot aplikasi Anda yang error. Abaikan jika aplikasi HANG sepenuhnya.</li>
                                            <li>Segera hubungi Admin halojasa untuk bantuan lebih lanjut.</li>
                                        </ul>`
                            }
                        ]
                    },
                    {
                        title: 'Bagaimana jika saldo sudah di popup tetapi tidak bertambah?',
                        content: [
                            {
                                text: `Jika saldo Dompet Deposit tidak bertambah setelah Top-Up, segera hubungi Admin halojasa beserta bukti transfer. Jika Anda melakukan Top-Up melalui dompet tunai, mohon cek riwayat transaksi dan laporkan pada Admin halojasa.<br />
                                        <div style="width: 100%; margin: auto; display: block; ">
                                        <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/1.png" />
                                        <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/2.png" />
                                        <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/3.png" />
                                        <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/4.png" />
                                        </div>
                                        Untuk cek riwayat transaksi Top-Up saldo kredit melalui saldo tunai, lakukan langkah berikut:
                                        <ul>
                                            <li>Buka aplikasi vendor halojasa.</li>
                                            <li>Klik menu "Dompet" pada bawah kiri layar Anda.</li>
                                            <li>Klik "Saldo Kredit" Anda.</li>
                                            <li>Klik "Cek Riwayat Transaksi”.</li>
                                        </ul>`
                            }
                        ]
                    },
                    {
                        title: 'Bagaimana jika saldo sudah di tarik dan terpotong, tetapi tidak masuk kedalam rekening',
                        content: [
                            {
                                text: `Jika saldo belum masuk ke rekening Anda namun sudah terpotong, segera laporkan kepada Admin halojasa. Lampirkan bukti penarikan dan mutasi rekening bank Anda kepada Admin halojasa.<br />
                                        Untuk cek riwayat transaksi Saldo Tunai Anda, ikuti langkah berikut:
                                        <ul>
                                            <li>Buka aplikasi vendor halojasa.</li>
                                            <li>Klik menu "Dompet" dibawah kiri layar Anda.</li>
                                            <li>Klik "Saldo Tunai".</li>
                                            <li>Klik "Cek Riwayat Transaksi"</li>
                                        </ul>
                                        <div style="width: 100%; margin: auto; display: block; ">
                                        <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/Picture12.jpg" />
                                        <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/Picture13.jpg" />
                                        <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/Picture14.jpg" />
                                        <img style="max-width: 100%; margin: 10px auto 10px auto; display: block;" src="https://halojasa.com/blog-vendor/wp-content/uploads/2020/06/Picture15.jpg" />
                                        </div>
                                        `
                            }
                        ]
                    },
                ]
            },
            {
                title: 'Sanksi dan Pelanggaran',
                subdata: [
                    {
                        title: 'Hukuman apa yang didapatkan oleh vendor jika mereka melanggar?',
                        content: [
                            {
                                text: `<table class="table_faq">
                                    <thead><tr><th colspan="3">Technical</th></tr><tr><th>NO</th><th>Pelanggaran</th><th>Sanksi</th></tr></thead>
                                    <tbody><tr><td>1</td><td>Menerima pesanan namun tidak melaksanakannya.</td>
                                    <td> Pelanggaran Pertama: Surat Peringatan di aplikasi <br /><br /> Pelanggaran Kedua: suspend 3 hari<br /><br /> 
                                    Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi<br /><br /> 
                                    Pelanggraan Keempat : suspend Permanen</td></tr><tr><td>2</td><td> Vendor melakukan proses pengerjaan layanan jasa yang 
                                    tidak sesuai dengan SOP, asal-asalan, tidak maksimal.</td><td> Pelanggaran Pertama: Surat Peringatan di aplikasi 
                                    <br /><br /> Pelanggaran Kedua: suspend 3 hari<br /><br /> Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi
                                    <br /><br /> Pelanggraan Keempat : suspend Permanen</td></tr><tr><td>3</td><td> Memanipulasi waktu sebelum atau sesudah 
                                    proses layanan jasa.</td><td> Pelanggaran Pertama: Surat Peringatan di aplikasi <br /><br /> Pelanggaran Kedua: suspend 
                                    3 hari<br /><br /> Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi<br /><br /> Pelanggraan Keempat : suspend Permanen</td>
                                    </tr><tr><td>4</td><td> Menekan tombol “SELESAI” tanpa mengerjakan order.</td><td> Pelanggaran Pertama: Surat Peringatan di aplikasi 
                                    <br /><br /> Pelanggaran Kedua: suspend 3 hari<br /><br /> Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi<br /><br /> 
                                    Pelanggraan Keempat : suspend Permanen</td></tr><tr><td>5</td><td> Melakukan konfirmasi Penyelesaian Order saat Pekerjaan Layanan 
                                    belum selesai dilakukan.</td><td> Pelanggaran Pertama: Surat Peringatan di aplikasi <br /><br /> 
                                    Pelanggaran Kedua: suspend 3 hari<br /><br /> Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi<br /><br /> 
                                    Pelanggraan Keempat : suspend Permanen</td></tr><tr><td>6</td><td> Menggunakan akun orang lain.</td><td> 
                                    Pelanggaran Pertama: Surat Peringatan di aplikasi <br /><br /> Pelanggaran Kedua: suspend 3 hari<br /><br /> 
                                    Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi<br /><br /> Pelanggraan Keempat : suspend Permanen</td>
                                    </tr><tr><td>7</td><td> Menolak melakukan Order Pekerjaan hingga 3 kali berturut-turut dalam 1 hari (belum berlaku sampai ada 
                                        pemberitahuan berikutnya)</td><td> Pelanggaran Pertama: Surat Peringatan di aplikasi <br /><br /> Pelanggaran Kedua: 
                                        suspend 3 hari<br /><br /> Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi<br /><br /> Pelanggraan Keempat : 
                                        suspend Permanen</td></tr><tr><td>8</td><td> Sengaja memanipulasi untuk mencurangi sistem Halo Jasa seperti membuat orderan 
                                        fiktif.</td><td> Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi <br /><br /> Pelanggaran Kedua: suspend permanent</td>
                                        </tr><tr><td>9</td><td> Mencurangi sistem Halo Jasa untuk mendapatkan bonus atau insentif Vendor dengan cara apapun.</td><td> 
                                        Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi <br /><br /> Pelanggaran Kedua: suspend permanent</td></tr>
                                        <tr><td>10</td><td> Menyebarkan detail kontak (Nama, Alamat, nomor telephon, foto) dari Pencari Jasa ke pihak lain melalui 
                                        berbagai media.</td><td> Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi <br /><br /> Pelanggaran Kedua: suspend 
                                        permanent</td></tr></tbody></table><table class="table_faq><thead><tr><th colspan="3">Non Technical</th></tr><tr><th>NO</th><th>Pelanggaran</th>
                                        <th>Sanksi</th></tr></thead><tbody><tr><td>1</td><td>Tidak melakukan 3”S” (Senyum, Salam, Sapa) Kepada Customer / Pencari Jasa 
                                        saat tiba dilokasi.</td><td> Pelanggaran Pertama: Surat Peringatan di aplikasi <br /><br /> Pelanggaran Kedua: suspend 
                                        3 hari<br /><br /> Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi<br /><br /> Pelanggraan Keempat : 
                                        suspend Permanen</td></tr><tr><td>2</td><td>Tidak berpenampilan profesional (Pakaian kusut, bau badan, tidak rapih, kotor).
                                        </td><td> Pelanggaran Pertama: Surat Peringatan di aplikasi <br /><br /> Pelanggaran Kedua: suspend 3 hari<br /><br /> 
                                        Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi<br /><br /> Pelanggraan Keempat : suspend Permanen</td></tr><tr>
                                        <td>3</td><td>Atribut tidak lengkap (seragam & peralatan kerja layanan).</td><td> Pelanggaran Pertama: Surat Peringatan di 
                                        aplikasi <br /><br /> Pelanggaran Kedua: suspend 3 hari<br /><br /> Pelanggaran Ketiga: suspend sampai ada kunjungan 
                                        klarifikasi<br /><br /> Pelanggraan Keempat : suspend Permanen</td></tr><tr><td>4</td><td>Menggunakan peralatan kerja yang 
                                        kotor atau bau tidak sedap.</td><td> Pelanggaran Pertama: Surat Peringatan di aplikasi <br /><br /> Pelanggaran Kedua: suspend 3 
                                        hari<br /><br /> Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi<br /><br /> Pelanggraan Keempat : suspend 
                                        Permanen</td></tr><tr><td>5</td><td>Customer / Pencari Jasa tidak mendapat layanan dengan peralatan sesuai SOP Halo Jasa 
                                        (Vendor tidak menggunakan tools yang ada di SOP).</td><td> Pelanggaran Pertama: Surat Peringatan di aplikasi <br /><br /> 
                                        Pelanggaran Kedua: suspend 3 hari<br /><br /> Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi<br /><br /> 
                                        Pelanggraan Keempat : suspend Permanen</td></tr><tr><td>6</td><td>Penyedia Jasa yang datang tidak sesuai dengan yang tertera 
                                        diaplikasi.</td><td> Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi<br /><br /> Pelanggaran Kedua: suspend 
                                        permanent</td></tr><tr><td>7</td><td>Dalam proses pengerjaan layanan, Vendor tidak melakukan tahapan proses pengerjaan layanan 
                                        jasa sesuai dengan ketentuan SOP Halo Jasa.</td><td> Pelanggaran Pertama: Surat Peringatan di aplikasi <br /><br /> Pelanggaran 
                                        Kedua: suspend 3 hari<br /><br /> Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi<br /><br /> Pelanggraan Keempat : 
                                        suspend Permanen</td></tr><tr><td>8</td><td>Vendor dilarang merokok saat bersama Customer dalam proses pengerjaan layanan jasa.</td>
                                        <td> Pelanggaran Pertama: Surat Peringatan di aplikasi <br /><br /> Pelanggaran Kedua: suspend 3 hari<br /><br /> Pelanggaran Ketiga: 
                                        suspend sampai ada kunjungan klarifikasi<br /><br /> Pelanggraan Keempat : suspend Permanen</td></tr><tr><td>9</td><td>Menyebabkan 
                                        kerusakan pada properti apapun yang adalah milik Customer.</td><td> Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi 
                                        <br /><br /> Pelanggaran Kedua: suspend permanent</td></tr><tr><td>10</td><td>Menghilangkan properti pribadi apapun yang adalah 
                                        milik Customer.</td><td> Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi <br /><br /> Pelanggaran Kedua: suspend 
                                        permanent</td></tr><tr><td>11</td><td>Bersikap dan atau berkata tidak sopan, menghina, mengintimidasi, dan kasar terhadap Customer.</td>
                                        <td> Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi <br /><br /> Pelanggaran Kedua: suspend permanent</td>
                                        </tr><tr><td>12</td><td>Melakukan tindakan pelecehan seksual dalam proses pengerjaan layanan jasa.</td><td> Pelanggaran Pertama: 
                                        Suspend sampai ada kunjungan klarifikasi <br /><br /> Pelanggaran Kedua: suspend permanent</td></tr><tr><td>13</td><td>
                                        Datang ke lokasi Pengerjaan Layanan dalam kondisi terpengaruhi Alkohol, Zat Adiktif atau Psikotropika.</td><td> Pelanggaran Pertama: 
                                        Suspend sampai ada kunjungan klarifikasi <br /><br /> Pelanggaran Kedua: suspend permanent</td></tr><tr><td>14</td>
                                        <td>Membatalkan pesanan tanpa alasan jelas atau tidak memberitahukan kepada Customer.</td><td> Pelanggaran Pertama: Surat 
                                        Peringatan di aplikasi <br /> Pelanggaran Kedua: suspend 3 hari<br /> Pelanggaran Ketiga: suspend sampai ada kunjungan 
                                        klarifikasi<br /><br /> Pelanggraan Keempat : suspend Permanen</td></tr><tr><td>15</td><td>Mendapat tuduhan dan atau 
                                        terbukti melakukan tindakan kejahatan terhadap Customer.</td><td> Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi <br />
                                        <br /> Pelanggaran Kedua: suspend permanent</td></tr><tr><td>16</td><td>Membawa anak atau kerabat lainnya saat proses pengerjaan 
                                        layanan.</td><td> Pelanggaran Pertama: Surat Peringatan di aplikasi <br /><br /> Pelanggaran Kedua: suspend 3 hari<br /><br /> 
                                        Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi<br /><br /> Pelanggraan Keempat : suspend Permanen</td></tr><tr>
                                        <td>17</td><td>Memberikan kembalian kurang jika transaksi COD.</td><td> Pelanggaran Pertama: Surat Peringatan di aplikasi 
                                        <br /> Pelanggaran Kedua: suspend 3 hari<br /><br /> Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi<br /><br /> 
                                        Pelanggraan Keempat : suspend Permanen</td></tr><tr><td>18</td><td>Menghubungi Customer diluar kebutuhan order.</td><td> Pelanggaran 
                                        Pertama: Surat Peringatan di aplikasi <br /><br /> Pelanggaran Kedua: suspend 3 hari<br /><br /> Pelanggaran Ketiga: suspend sampai 
                                        ada kunjungan klarifikasi<br /><br /> Pelanggraan Keempat : suspend Permanen</td></tr><tr><td>19</td><td>Meminta Biaya Tambahan di 
                                        luar biaya yang telah disepakati untuk layanan jasa yang telah dipesan melalui sistem Halo Jasa.</td><td> Pelanggaran Pertama: 
                                        Surat Peringatan di aplikasi <br /><br /> Pelanggaran Kedua: suspend 3 hari<br /><br /> Pelanggaran Ketiga: suspend sampai ada 
                                        kunjungan klarifikasi<br /><br /> Pelanggraan Keempat : suspend Permanen</td></tr><tr><td>20</td><td>Tidak bersedia memberikan 
                                        garansi 3 hari atas jasa layanan yang yang telah dipesan melalui sistem Halo Jasa. (Hanya berlaku pada Kategori Jasa cuci AC)</td>
                                        <td> Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi <br /><br /> Pelanggaran Kedua: suspend permanent</td></tr><tr>
                                        <td>21</td><td>Bebicara maupun bertindak meresahkan (secara lisan maupun tulisan) yang mengarah pada pertengkaran yang berujung kontak 
                                        fisik kepada sesama Vendor, Customer, maupun Staff Halo Jasa.</td><td> Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi 
                                        <br /><br /> Pelanggaran Kedua: suspend permanent</td></tr><tr><td>22</td><td>Dokumen resmi (KTP/SIM/SKCK) tidak lengkap dan tidak 
                                        diperbaharui.</td><td> Pelanggaran Pertama: Surat Peringatan di aplikasi <br /><br /> Pelanggaran Kedua: suspend 3 hari<br /><br /> 
                                        Pelanggaran Ketiga: suspend sampai ada kunjungan klarifikasi<br /><br /> Pelanggraan Keempat : suspend Permanen</td></tr>
                                        <tr><td>23</td><td>Berucap/berbuat hal yang bersifat penghinaan SARA, pelecehan seksual kepada Pihak Customer.</td><td>Pelanggaran 
                                        Pertama: Pemutusan Kontrak secara permanen dan penghapusan akun dari sistem Halo-jasa</td></tr><tr><td>24</td><td>Melakukan tindak 
                                        kejahatan yang berpotensi menimbulkan gugatan Pidana.</td><td>Pelanggaran Pertama: Suspend permanen</td></tr><tr><td>25</td><td>
                                        Melakukan tindakan yang meresahkan seperti mengancam Customer maupun staff Halo Jasa.</td><td>Pelanggaran Pertama: Suspend 
                                        permanen</td></tr><tr><td>26</td><td>Menawarkan dan memberikan barang atau uang kepada Staff Halo Jasa yang bertujuan untuk 
                                        menyalahi peraturan (gratifikasi).</td><td> Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi <br /><br /> Pelanggaran 
                                        Kedua: suspend permanent</td></tr><tr><td>27</td><td>Memprovokasi Vendor lain untuk melakukan kegiatan yang dapat merugikan 
                                        perusahaan (demonstrasi, razia, sweeping, dsb).</td><td> Pelanggaran Pertama: Suspend sampai ada kunjungan klarifikasi <br />
                                        <br /> Pelanggaran Kedua: suspend permanent</td></tr><tr><td>28</td><td>Menyebar Hoax, berita fitnah atau kesaksian palsu 
                                        mengenai Halo Jasa, pihak Customer atau Vendor Halo Jasa lainnya untuk alasan apapun.</td><td> Pelanggaran Pertama: Suspend 
                                        sampai ada kunjungan klarifikasi <br /><br /> Pelanggaran Kedua: suspend permanent</td></tr></tbody></table>`
                            }
                        ]
                    },
                    {
                        title: 'Bagaimana mengembalikan akun vendor yang sudah di suspend?',
                        content: [
                            {
                                text: `Jika akun tersuspend, Anda bisa mengaktifkan kembali akun sesuai ketentuan atau pelanggaran. Silahkan hubungi Admin halojasa untuk mengatur jadwal temu di kantor halojasa untuk menyelesaikan persoalan akun Anda.`
                            }
                        ]
                    },
                ]
            }
        ]
    },
]