import React, { useState } from 'react'

import AccordionStyle from './index.style'

import { Link } from 'react-router-dom'

const AccordionWebview = ({
    title = '',
    user = false,
    child = { title: '', subdata: [] }, }) => {
    const [isShow, setShow] = useState(false)
    const clickAccordion = e => {
        e.preventDefault()
        setShow(prevState => {
            return !prevState
        })
        // setShow(!isShow)
    }

    return (
        <AccordionStyle>
            <div className="list-webview">
                <div
                    onClick={clickAccordion}
                    className="title"
                >
                    <div className="arrow">
                        <span>{title}</span>
                        <i className={`icon-arrow ${isShow ? 'up' : 'down'}`}></i>
                    </div>
                </div>
                <div className="wrap-acc">
                    {child.map((value, index) => {
                        return (
                            <div className={`item-link ${isShow ? 'collapsed' : ''}`} key={index}>
                                <div className={`link-wrap`}>
                                    <Link
                                        to={{
                                            pathname: `/webview-${user ? 'vendor' : 'user'}/help-sub/`,
                                            state: {
                                                key: index,
                                                value: value.subdata,
                                                title: value.title,
                                                user: user
                                            }
                                        }}
                                        className="link"
                                    >
                                        {value.title}
                                    </Link>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </AccordionStyle>
    )
}

export default AccordionWebview