import React from 'react'
import Skeleton from '../../components/skeleton'
import { Grid, Row, Col } from 'react-flexbox-grid'
import styled from 'styled-components'

const LandingPageSkeletonWrap = styled.div`
  padding-bottom: 100px;
  padding-top: 140px;
`

const LandingPageSkeleton = () => {
  return (
    <LandingPageSkeletonWrap>
      <Grid>
        <Row>
          <Col lg={4} sm={12} xs={12}>
            <Skeleton width={'100%'} height={'200px'}></Skeleton>
          </Col>
          <Col lg={8} sm={12} xs={12}>
            <Skeleton width={'100%'}></Skeleton>
            <Skeleton width={'100%'}></Skeleton>
            <Skeleton width={'80%'}></Skeleton>
            <Skeleton width={'80%'}></Skeleton>
            <Skeleton width={'60%'}></Skeleton>
          </Col>
        </Row>
      </Grid>
    </LandingPageSkeletonWrap>
  )
}

export default LandingPageSkeleton
