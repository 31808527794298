import styled from 'styled-components'

export default styled.div`
    font-family: open sans;
    overflow-x: hidden;
    padding-bottom: 20px;
    position: relative;
    .hd-clr {
        background: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(10px);
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        z-index: 10;
        > .pp-up {
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
            text-align: center;
            box-sizing: border-box;
            padding: 20px;
            > .pp-up-cnt {
                background: white;
                box-sizing: border-box;
                padding: 25px;
                > .ttl {
                    color: #333333;
                    font-family: Open Sans;
                    font-weight: bold;
                    font-size: 16px;
                }
                > .desc {
                    color: #333333;
                    font-family: Open Sans;
                    font-weight: normal;
                    font-size: 14px;
                }
                > button {
                    background: linear-gradient(154.08deg, #FF7F5F 37.5%, #FF6049 100%);
                    border-radius: 5px;
                    box-sizing: border-box;
                    border: none;
                    font-family: Open Sans;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 12px;
                    color: #FFFFFF;
                    width: 100%;
                    padding: 12px 0px;
                    margin-top: 36px;
                }
            }
        }
    }
    .dtl-ttl-wrp {
        width: 100%;
        box-sizing: border-box;
        padding: 20px 24px;
        font-size: 16px;
        font-weight: bold;
        color: #555555;
        position: relative;
        > span {
            font-weight: bold;
        }
    }
    .frm-fddbck {
        padding: 20px 24px;
        box-sizing: border-box;
        > textarea {
            width: 100%;
            background: #EEEEEE;
            border-radius: 5px;
            border: none;
            resize: none;
            outline: none;
            box-sizing: border-box;
            padding: 16px;
            font-family: Open Sans;
        }
        > input {
            width: 100%;
            background: #EEEEEE;
            border-radius: 5px;
            border: none;
            resize: none;
            outline: none;
            box-sizing: border-box;
            padding: 16px;
            margin-bottom: 16px;
            font-family: Open Sans;
        }
    }
    .btns-wrp {
        width: 100%;
        box-sizing: border-box;
        padding: 20px 24px;
        > .snd-btn {
            border-radius: 5px;
            font-family: Open Sans;
            width: 100%;
            box-sizing: border-box;
            padding: 12px 0px;
            background: linear-gradient(159.78deg, #FF7F5F 37.5%, #FF6049 100%);
            color: #FFFFFF;
            border: none;
            &:disabled {
                background: linear-gradient(0deg, #DDDDDD, #DDDDDD);
                color: #888888;
                border: none;
            }
        }
    }
`