import React from 'react'
import Skeleton from '../../components/skeleton'
import { Grid, Row, Col } from 'react-flexbox-grid'
const JobListSkeleton = () => {
  return (
    <div style={{ paddingBottom: 100, paddingTop: 120 }}>
      <Grid>
        <Row>
          <Col lg={12} sm={12} xs={12}>
            <Skeleton width={'60%'} height="10px"></Skeleton>
          </Col>
        </Row>
        <Row>
          <Col lg={12} sm={12} xs={12}>
            <Row>
              <Col lg={2} sm={2} xs={2}>
                <Skeleton width={'100%'} height="10px"></Skeleton>
              </Col>
              <Col lg={2} sm={2} xs={2}>
                <Skeleton width={'100%'} height="10px"></Skeleton>
              </Col>
              <Col lg={2} sm={2} xs={2}>
                <Skeleton width={'100%'} height="10px"></Skeleton>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 40 }}>
          <Col lg={4} sm={6} xs={12}>
            <div
              style={{
                border: '1px solid #f1f1f1',
                padding: 32,
                borderRadius: 10,
                marginBottom: 20
              }}
            >
              <Skeleton width={'60%'} height="10px"></Skeleton>
              <div style={{ marginBottom: 40 }}></div>
              <Skeleton width={'100%'} height="10px"></Skeleton>
              <Skeleton width={'80%'} height="10px"></Skeleton>
              <Skeleton width={'80%'} height="10px"></Skeleton>
              <Skeleton width={'75%'} height="10px"></Skeleton>
              <Skeleton width={'60%'} height="10px"></Skeleton>
              <Skeleton width={'40%'} height="10px"></Skeleton>
              <div style={{ marginBottom: 40 }}></div>
              <Skeleton width={'20%'} height="10px"></Skeleton>
              <div style={{ marginBottom: 40 }}></div>
              <Skeleton width={'60%'} center height="20px"></Skeleton>
              <div style={{ marginBottom: 40 }}></div>
              <Skeleton width={'60%'} center height="20px"></Skeleton>
            </div>
          </Col>
          <Col lg={4} sm={6} xs={12}>
            {/* <Card> */}
            <div
              style={{
                border: '1px solid #f1f1f1',
                padding: 32,
                borderRadius: 10,
                marginBottom: 20
              }}
            >
              <Skeleton width={'60%'} height="10px"></Skeleton>
              <div style={{ marginBottom: 40 }}></div>
              <Skeleton width={'100%'} height="10px"></Skeleton>
              <Skeleton width={'80%'} height="10px"></Skeleton>
              <Skeleton width={'80%'} height="10px"></Skeleton>
              <Skeleton width={'75%'} height="10px"></Skeleton>
              <Skeleton width={'60%'} height="10px"></Skeleton>
              <Skeleton width={'40%'} height="10px"></Skeleton>
              <div style={{ marginBottom: 40 }}></div>
              <Skeleton width={'20%'} height="10px"></Skeleton>
              <div style={{ marginBottom: 40 }}></div>
              <Skeleton width={'60%'} center height="20px"></Skeleton>
              <div style={{ marginBottom: 40 }}></div>
              <Skeleton width={'60%'} center height="20px"></Skeleton>
            </div>
          </Col>
          <Col lg={4} sm={6} xs={12}>
            {/* <Card> */}
            <div
              style={{
                border: '1px solid #f1f1f1',
                padding: 32,
                borderRadius: 10,
                marginBottom: 20
              }}
            >
              <Skeleton width={'60%'} height="10px"></Skeleton>
              <div style={{ marginBottom: 40 }}></div>
              <Skeleton width={'100%'} height="10px"></Skeleton>
              <Skeleton width={'80%'} height="10px"></Skeleton>
              <Skeleton width={'80%'} height="10px"></Skeleton>
              <Skeleton width={'75%'} height="10px"></Skeleton>
              <Skeleton width={'60%'} height="10px"></Skeleton>
              <Skeleton width={'40%'} height="10px"></Skeleton>
              <div style={{ marginBottom: 40 }}></div>
              <Skeleton width={'20%'} height="10px"></Skeleton>
              <div style={{ marginBottom: 40 }}></div>
              <Skeleton width={'60%'} center height="20px"></Skeleton>
              <div style={{ marginBottom: 40 }}></div>
              <Skeleton width={'60%'} center height="20px"></Skeleton>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default JobListSkeleton
