import types from './redux-service-type'
import initialState from './redux-service-state'

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SUCCESS:
      return {
        ...state,
        [action.key.group]: { [action.key.value]: { ...action } }
      }
    case types.CREATED:
      return {
        ...state,
        [action.key.group]: { [action.key.value]: { ...action } }
      }

    case types.BAD_REQUEST:
      return {
        ...state,
        [action.key.group]: { [action.key.value]: { ...action } }
      }
    case types.UNAUTHORIZED:
      return {
        ...state,
        [action.key.group]: { [action.key.value]: { ...action } }
      }
    case types.NOT_FOUND:
      return {
        ...state,
        [action.key.group]: { [action.key.value]: { ...action } }
      }
    case types.CONFLICT:
      return {
        ...state,
        [action.key.group]: { [action.key.value]: { ...action } }
      }
    case types.INTERNAL_SERVICE_ERROR:
      return {
        ...state,
        [action.key.group]: { [action.key.value]: { ...action } }
      }
    case types.CLEAR:
      return {
        ...state,
        [action.key.group]: { [action.key.value]: '' }
      }
    default:
      return state
  }
}
