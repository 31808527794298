import React from 'react'

import HeaderWebviewStyle from './index.style'
import ArrowLeft from '../../../static/icons/arrow-left.png'

import { useHistory } from "react-router-dom"

const HeaderWebview = ({ title, user }) => {
    let history = useHistory()

    let color = user ? 'linear-gradient(140.09deg, #4184F4 1.26%, #83B1FF 99.1%)' : 'linear-gradient(100.43deg, #FF7400 0%, #FF9600 100%)'

    const onClose = () => {
        (window["ReactNativeWebView"] || window).postMessage("Closed")
    }

    return (
        <HeaderWebviewStyle color={color}>
            <div className="header-wrap">
                {(() => {
                    if (window.location.href.indexOf("bantuan") > -1) {
                        return 'Bantuan'
                    } else if (window.location.href.indexOf("detail") > -1) {
                        return 'Detail Pertanyaan'
                    } else if (window.location.href.indexOf("sub") > -1) {
                        return title
                    } else if (window.location.href.indexOf("feedback") > -1) {
                        return 'Detail Bantuan'
                    } else if (window.location.href.indexOf("point") > -1) {
                        return 'Bantuan'
                    }
                })()}
            </div>
            {window.location.href.indexOf("bantuan") > -1 || window.location.href.indexOf("point") > -1 ?
                <img src={ArrowLeft} className="back-button hist" onClick={onClose} /> :
                <img src={ArrowLeft} className="back-button close" onClick={() => history.goBack()} />}
        </HeaderWebviewStyle>
    )
}

export default HeaderWebview
