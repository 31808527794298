import React, { useEffect } from 'react'
import { Col } from 'react-flexbox-grid'

import HelpSubStyle from './index.style'
import HeaderWebview from '../../../../components/layouts/header-webview'

import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'


const WebviewHelpSub = props => {
    const helpSubData = props.location.state

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const user = helpSubData.user

    return (
        <HelpSubStyle>
            <Helmet>
                <title>Pusat Bantuan</title>
                <meta name="robots" content="noindex" />
                <meta charSet="utf-8" />
            </Helmet>
            <Col sm={12} xs={12} className="wrap-help">
                <HeaderWebview title={helpSubData.title} user={user}></HeaderWebview>
                <div key={helpSubData.id}>
                    <div className="title">
                        {helpSubData.value.map((value, index) => {
                            return (
                                <div
                                    className="arrow"
                                    key={index}
                                >
                                    <div className="arrow-wrap">
                                        <Link
                                            to={{
                                                pathname: `/webview-${user ? 'vendor' : 'user'}-detail/`,
                                                state: {
                                                    key: index,
                                                    value: value.content,
                                                    title: value.title,
                                                    user: user
                                                }
                                            }}
                                            className="title-sub"
                                        >
                                            {value.title}
                                        </Link>
                                        <i className="icon-arrow down"></i>
                                    </div>
                                    <div className="brdr-down"></div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Col>
        </HelpSubStyle>
    )
}

export default WebviewHelpSub