import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import localStorageFn from './utils/lib/local-storage'

const PublicRoute = ({ component: Component, restricted = false, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        localStorageFn().get('auth') && restricted ? (
          <Redirect to="/" from="/login" />
        ) : (
            <Component {...props} />
          )
      }
    >
      {rest.children}
    </Route>
  )
}

PublicRoute.defaultProps = { restricted: false }

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  restricted: PropTypes.bool
}

export { PublicRoute }
