import styled from 'styled-components'

export const HeaderHelpStyle = styled.menu`
  padding: 0;
  margin: 0;

  @media screen and (min-width: 300px) and (max-width: 900px) {
    margin: -28px 0 0;
  }

  .help-header-wrapper {
    position: relative;
    background: #818181;
    width: 100%;

    @media screen and (min-width: 300px) and (max-width: 900px) {
      white-space: nowrap;
      overflow-x: auto;
      display: none;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        color: #ffffff;
        display: inline-block;
        padding: 15px 20px;
        cursor: pointer;

        &:hover {
          background: #ff7b00;
        }

        a {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
  }
`
