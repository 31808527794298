import styled from 'styled-components'

export default styled.div`
    width: 100%;
    display: flex;
    background: ${props => props.color};
    height: 69px;
    justify-content: center;
    align-items: center;
    align-self: center;
    box-sizing: border-box;
    padding: 15px;
    position: relative;
    font-family: open sans;
    
    .back-button {
        max-width: 24px;
        position: absolute;
        left: 15px;
    }

    .header-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        width: 80%;
        color: #FFFFFF;
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
    }
`