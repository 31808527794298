import React, { useEffect } from 'react'

import { Col } from 'react-flexbox-grid'
import HelpVendorWebview from './index.style'
import AccordionWebview from '../../../../components/accordion-webview'
import HeaderWebview from '../../../../components/layouts/header-webview'
import { VendorData } from '../../../../utils/lib/webview-vendor-data'

import Helmet from 'react-helmet'

const VendorHelp = () => {

    const data = VendorData

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    let ChangeUser = window.location.href.indexOf("vendor") > -1

    return (
        <HelpVendorWebview>
            <Helmet>
                <title>Pusat Bantuan</title>
                <meta name="robots" content="noindex" />
                <meta charSet="utf-8" />
            </Helmet>
            <HeaderWebview user={ChangeUser}></HeaderWebview>
            <div className="contain-help">
                <Col sm={12} xs={12} className="wrap-help">
                    {data.map((value, index) => {
                        return (
                            <div key={index}>
                                <AccordionWebview key={index} user={ChangeUser} {...value}></AccordionWebview>
                            </div>
                        )
                    })}
                </Col>
            </div>
        </HelpVendorWebview>
    )
}

export default VendorHelp