import React, { useState } from 'react'

import FeedbackStyle from './index.style'
import FeedbackPositive from '../../static/icons/feedback_p.png'
import FeedbackNegative from '../../static/icons/feedback_n.png'

const Feedback = () => {
  const [isShow, setShow] = useState(false)
  const [sentiment, setSentiment] = useState(false)

  const positiveText = 'Terimakasih atas feedback anda'
  const negativeText =
    'Terimakasih atas feedback anda. Silahkan hubungi help@halojasa.com untuk dukungan lebih lanjut'

  return (
    <FeedbackStyle>
      <div className="feedback">
        {!isShow && (
          <div className="choose">
            <div className="feedback-title">
              <span>Apakah penjelasan ini membantumu?</span>
            </div>
            <div className="feedback-image">
              <img
                className="positive"
                src={FeedbackPositive}
                onClick={() => {
                  setSentiment(true)
                  setShow(!isShow)
                }}
              />
              <img
                className="negative"
                src={FeedbackNegative}
                onClick={() => {
                  setSentiment(false)
                  setShow(!isShow)
                }}
              />
            </div>
          </div>
        )}
        {isShow && (
          <div className="thanks">
            <span>{sentiment ? positiveText : negativeText}</span>
          </div>
        )}
      </div>
    </FeedbackStyle>
  )
}

export default Feedback
