import styled from 'styled-components'

export default styled.div`
  padding-bottom: 40px;
  max-width: 100%;
  font-family: open sans;
  font-size: 14px;
  table {
    border-collapse: collapse;
  }
  table, th, td {
    border: 1px solid black;
  }
  .table_faq {
    border-collapse: collapse;
    border: 1px solid black;
  }
    .contain-help {
        max-width: 100%;
    }
    .wrap-help {
        padding: 0px;
    }
  
`