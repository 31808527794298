/* eslint-disable */
import React from 'react'
import { Route, Switch } from 'react-router-dom'

// component
import CodeSplit from '../../components/code-split/code-split'
import { Grid, Col, Row } from 'react-flexbox-grid'
import Skeleton from '../../components/skeleton'
import HelpHeader from './help-header'
import NotFound from '../error-page/404-not-found'

const SidebarContentSkeleton = () => {
  return (
    <div>
      <Skeleton width={'100%'}></Skeleton>
      <Skeleton width={'100%'}></Skeleton>
      <Skeleton width={'100%'}></Skeleton>
      <Skeleton width={'80%'}></Skeleton>
      <Skeleton width={'80%'}></Skeleton>
      <Skeleton width={'60%'}></Skeleton>
      <Skeleton width={'80%'}></Skeleton>
      <Skeleton width={'80%'}></Skeleton>
      <Skeleton width={'60%'}></Skeleton>
    </div>
  )
}
const BreadCrumbSkeleton = () => {
  return (
    <div>
      <Skeleton width={'100%'}></Skeleton>
    </div>
  )
}

const AboutPages = props => {
  return (
    <Route
      //      path="/about/"
      render={({ match: { url }, location: { pathname } }) => {
        return (
          <Grid style={{ paddingTop: 83, paddingBottom: 20 }}>
            <HelpHeader />

            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <CodeSplit load={() => import('../../components/breadcrumb')}>
                  {Component =>
                    Component === null ? (
                      <BreadCrumbSkeleton></BreadCrumbSkeleton>
                    ) : (
                        <Component
                          options={[
                            {
                              name: 'Beranda',
                              link: '/'
                            },
                            {
                              name: 'Tentang Kami',
                              link: '/bantuan/tentang-kami/'
                            },
                            {
                              name: pathname
                                .match(/\/([^\/]+)\/?$/)[1]
                                .replace('-', ' ')
                                .replace('-', ' '),
                              link: false
                            }
                          ]}
                          {...props}
                        />
                      )
                  }
                </CodeSplit>
              </Col>
            </Row>
            <Row>
              <Switch>
                <Route
                  path='/bantuan/pusat-bantuan/'
                  component={() => (
                    <CodeSplit load={() => import('./help-center')}>
                      {Component =>
                        Component === null ? (
                          <SidebarContentSkeleton></SidebarContentSkeleton>
                        ) : (
                            <Component {...props} />
                          )
                      }
                    </CodeSplit>
                  )}
                />
                <Route
                  //                path={`${url}/privacy-policy/`}
                  path='/bantuan/privacy/'
                  component={() => (
                    <CodeSplit load={() => import('./privacy-policy')}>
                      {Component =>
                        Component === null ? (
                          <SidebarContentSkeleton></SidebarContentSkeleton>
                        ) : (
                            <Component {...props} />
                          )
                      }
                    </CodeSplit>
                  )}
                />
                <Route
                  //                path={`${url}/term-and-condition/`}
                  path='/bantuan/syarat-umum/'
                  component={() => (
                    <CodeSplit load={() => import('./term-and-condition')}>
                      {Component =>
                        Component === null ? (
                          <SidebarContentSkeleton></SidebarContentSkeleton>
                        ) : (
                            <Component {...props} />
                          )
                      }
                    </CodeSplit>
                  )}
                />
                <Route
                  //                path={`${url}/payment/`}
                  path='/bantuan/cara-pembayaran/'
                  component={() => (
                    <CodeSplit load={() => import('./payment')}>
                      {Component =>
                        Component === null ? (
                          <SidebarContentSkeleton></SidebarContentSkeleton>
                        ) : (
                            <Component {...props} />
                          )
                      }
                    </CodeSplit>
                  )}
                />
                <Route
                  //                path={`${url}/faq/`}
                  path='/bantuan/faq/'
                  component={() => (
                    <CodeSplit load={() => import('./faq')}>
                      {Component =>
                        Component === null ? (
                          <SidebarContentSkeleton></SidebarContentSkeleton>
                        ) : (
                            <Component {...props} />
                          )
                      }
                    </CodeSplit>
                  )}
                />
                <Route
                  //                path={`${url}/faq/`}
                  path='/bantuan/informasi-layanan/'
                  component={() => (
                    <CodeSplit load={() => import('./service-information')}>
                      {Component =>
                        Component === null ? (
                          <SidebarContentSkeleton></SidebarContentSkeleton>
                        ) : (
                            <Component {...props} />
                          )
                      }
                    </CodeSplit>
                  )}
                />
                <Route
                  //                path={`${url}/faq/`}
                  path='/bantuan/pengaduan-layanan/'
                  component={() => (
                    <CodeSplit load={() => import('./service-complain')}>
                      {Component =>
                        Component === null ? (
                          <SidebarContentSkeleton></SidebarContentSkeleton>
                        ) : (
                            <Component {...props} />
                          )
                      }
                    </CodeSplit>
                  )}
                />
                <Route
                  component={NotFound}
                />
              </Switch>
              {/* </Col> */}
            </Row>
          </Grid>
        )
      }}
    />
  )
}

export default AboutPages
