import styled from 'styled-components'

export default styled.div`
    font-family: open sans;
    overflow-x: hidden;
    padding-bottom: 40px;
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table, th, td {
        border: 1px solid black;
    }
    .wrap-help {
        padding: 0px; 
        > .fv {
            background: #F7F7F7;
            padding: 40px;
        }
        > .thanks {
            text-align: center;
            box-sizing: border-box;
            padding: 40px 30px 10px 20px;
        }
        > .fvb-wrap {
            text-align: center;
            box-sizing: border-box;
            padding: 10px;
            > p {
                font-family: Roboto;
            }
            > .fvb-flx {
                display: flex;
                justify-content: space-evenly;
                > .fvb-st {
                    background: none;
                    border: 1px solid #FF624B;
                    box-sizing: border-box;
                    border-radius: 5px;
                    width: 50%;
                    margin: 0px 7px;
                    padding: 10px;
                    color: #FF624B;
                    font-family: Open Sans;
                    text-decoration: none;
                    font-size: 12px;
                }
            }
        }
    }
    .dtl-ttl-wrp {
        width: 100%;
        box-sizing: border-box;
        padding: 20px 24px;
        font-size: 16px;
        font-weight: bold;
        color: #555555;
        position: relative;
        > .brdr-btm {
            border-bottom: 1px solid #eeeeee;
            margin-top: 16px;
            width: 150%;
        }
    }
    .list-number {
        padding: 0px 24px 0px 24px;
        box-sizing: border-box;
        > li {
            margin-bottom: 16px;
            box-sizing: border-box;
            margin-left: 24px;
            font-family: Roboto;
            font-size: 14px;
        }
    }
    .list-none {
        list-style: none;
        padding: 0px 24px 0px 24px;
        box-sizing: border-box;
        > li {
            margin-bottom: 16px;
            font-family: Roboto;
            font-size: 14px;
        }
    }
`