import React, { useState, useEffect } from 'react'

import HeaderWebview from '../../../../components/layouts/header-webview'
import HelpFeedbackStyle from './index.style'
import ThankYou from '../../../../static/icons/pop_up_feedback.png'

import Helmet from 'react-helmet'
import swal from 'sweetalert'

const WebviewHelpFeedback = props => {
    const helpFeedbackData = props.location.state
    const [phone, setPhone] = useState('')
    const [message, setmessage] = useState('')
    const [disable, setDisable] = useState(true)
    const [isShow, setShow] = useState(false)

    useEffect(() => {
        setDisable(true);
    }, []);

    const isEnabled = phone.length > 0 && message.length > 0

    const checkField = () => {
        const pattern_phone = /^\d+$/

        if (pattern_phone.test(phone) == false) {
            return swal('Warning', 'Phone number is invalid', 'warning')
        }

        if (message === '') {
            return swal('Warning', 'Fill the message area', 'warning')
        }

        setShow(!isShow)
    }

    const closePPUP = () => {
        window.location.reload()
        // setShow(!isShow)
    }

    return (
        <HelpFeedbackStyle>
            <Helmet>
                <title>Pusat Bantuan</title>
                <meta name="robots" content="noindex" />
                <meta charSet="utf-8" />
            </Helmet>
            {
                isShow &&
                <div className="hd-clr">
                    <div className="pp-up">
                        <div className="pp-up-cnt">
                            <img src={ThankYou} />
                            <p className="ttl">Berhasil Terkirim !</p>
                            <p className="desc">Permohonan bantuan anda berhasil terkirim dan akan segera kami proses</p>
                            <button
                                onClick={closePPUP}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            }
            <HeaderWebview user={helpFeedbackData.user}></HeaderWebview>
            <div className="dtl-ttl-wrp">
                <span>
                    Kami mohon maaf atas pengalaman yang kurang menyenangkan yang anda alami.
                </span>
            </div>
            <div className="frm-fddbck">
                <input
                    type="tel"
                    pattern="^-?[0-9]\d*\.?\d*$"
                    name="phone"
                    placeholder="Masukan nomor telepon anda.."
                    onChange={e => {
                        setPhone(e.target.value)
                    }}
                />
                <textarea
                    rows="10"
                    cols="50"
                    name="pesan"
                    placeholder="Ceritakan detail masalah yang anda alami disini.."
                    onChange={e => {
                        setmessage(e.target.value)
                    }}
                >
                </textarea>
            </div>
            <div className="btns-wrp">
                <button
                    disabled={!isEnabled}
                    className="snd-btn"
                    onClick={checkField}
                >
                    Kirim Detail Bantuan
                </button>
            </div>
        </HelpFeedbackStyle>
    )
}

export default WebviewHelpFeedback