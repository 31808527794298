export default {
  services: {
    campaign: {
      campaignList: {
        data: [],
        limit: 10,
        offset: 0,
        total: 0
      }
    },
    user: {
      userCreated: {}
    }
  },
  user: { login: true }
}
