import styled from 'styled-components'

export default styled.div`
  padding-bottom: 40px;
  max-width: 100%;
  font-family: open sans;
  font-size: 14px;
    .contain-help {
        max-width: 100%;
    }
    .wrap-help {
        padding: 0px;
    }
  
`