import React, { Fragment } from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'

const Notfound = styled.div`
  font-family: 'Open Sans', sans-serif;
  display: block;
  width: 100%;
  text-align: center;
`

const NotFoundPage = () => {

  if (window.location.href.indexOf("webview") > -1) return null

  return (
    <Fragment>
      <Helmet>
        <title>Error 404 Not Found!!</title>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex" />
        <meta
          name="p:domain_verify"
          content="4e6fcb4deba1a02d84225fad69babc0b"
        />
      </Helmet>
      <Notfound>
        <h1>404 NOT FOUND</h1>
      </Notfound>
    </Fragment>
  )
}
export default NotFoundPage
