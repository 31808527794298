import styled from 'styled-components'

export default styled.div`

    font-family: open sans;
    background: #F7F7F7;
    text-align: center;
    padding: 40px 0px;
    width: 100%;
    box-sizing: border-box;

    .feedback {
        padding: 24px 24px;
        border-top: 1px solid #DDDDDD;
        border-bottom: 1px solid #DDDDDD;

        > .choose {
            > .feedback-title {
                margin-bottom: 16px;
                > span {
                    color: #555555;
                    font-size: 14px;
                    font-family: Roboto;
                }
            }
            > .feedback-image {
                > .positive {
                    margin-right: 8px;
                }
                > .negative {
                    margin-left: 8px;
                }
            }
        }

        .thanks {
            width: 100%;
            padding: 35px 0px;
            > span {
                color: #555555;
                font-size: 14px;
                font-family: Roboto;
            }
        }
    }

`