// third-party redux
import { createEpicMiddleware, combineEpics } from 'redux-observable'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'

// reducer with react redux
import service from './redux-service'

export const rootReducer = combineReducers({
  service
})

export const rootEpic = combineEpics()

const epicMiddleware = createEpicMiddleware()

export default function configureStore() {
  const store = createStore(rootReducer, applyMiddleware(epicMiddleware, thunk))

  epicMiddleware.run(rootEpic)

  return store
}
