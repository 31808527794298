import styled from 'styled-components'

import ArrowDown from '../../../../static/icons/arrow-down.png'

export default styled.div`
    font-family: open sans;
    overflow-x: hidden;
    padding-bottom: 40px;
    .wrap-help {
        padding: 0px; 
    }
    .arrow {
        > .arrow-wrap {
            padding: 20px 24px;
            display: flex;
            width: 100%;
            -webkit-box-pack: justify;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            > .title-sub {
                font-weight: 600;
                color: #333333;
                text-decoration: none;
                font-size: 14px;
                width: 100%;
            }
    
            > i {
                width: 20px;
                height: 20px; 
                    &.down {
                        background-image: url(${ArrowDown});
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 1rem;
                        transform: rotate(-90deg);
                    }
            }
        }
        > .brdr-down {
            border-bottom: 2px solid #EEEEEE;
            margin-left: 24px;
        }
    }

    .item-link {
        visibility: hidden;
        height: 0;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear, height 0s linear;
        border-bottom: 1px solid #DDDDDD;
        box-sizing: border-box;
        &.collapsed {
            visibility: visible;
            height: 100%;
            opacity: 1;
            transition: visibility 0s, opacity 0.5s linear, height 0.7s linear;
        }
        &:last-child {
            border-bottom: none;
        }
        > .link-wrap {
            padding: 20px 15px;
            display: flex;
            width: 100%;
            background: #F7F7F7;
            > .link {
                width: 100%;
                text-decoration: none;
                color: #555555;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
  
`